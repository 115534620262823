

































































































































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {Getter} from "vuex-class";
import {Useraccount} from "../../../models/useraccount.model";

@Component({
  components: {}
})
export default class LocalReportIndividualData extends Vue {
  @Getter('getUseraccount', {namespace: 'useraccount'})
  private getUseraccount!: Useraccount;

  @Prop({required: false, default: null})
  report!: any;
}
