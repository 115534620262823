


























import {Component, Prop, Vue} from "vue-property-decorator";
import {UseraccountService} from '../../../useraccount/useraccount.service';
import LoadingOverlay from "../../../../core/components/LoadingOverlay.vue"

@Component({
  components: {LoadingOverlay},
})
export default class  DeleteAccountDialog extends Vue {

  @Prop({default: null})
  selectedAccount: any;

  private showOverlay = false;

  deleteAccount() {
    this.showOverlay = true;
    UseraccountService.deleteAccount(this.selectedAccount.id)
      .then((response: any) => {
        this.showOverlay = false;
        this.$notify({
          group: 'foo',
          title: 'Info',
          text: "Account erfolgreich gelöscht",
          type: 'success'
        });
        this.$emit('success');
      })
      .catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: error,
          type: 'error'
        });
        this.showOverlay = false;
      })
      .finally(() => {
        setTimeout(() => {
          this.showOverlay = false;
        }, 5000);
      });
  }

}
