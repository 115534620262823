






























































































































































































































































































































































































































































import {Component, Vue} from "vue-property-decorator";
import AppLoader from "../../../core/components/AppLoader.vue"
import AppCard from '../../../core/components/cards/AppCard.vue';
import AnalysisService from "../analysis.service";
import {Getter} from "vuex-class";
import {Useraccount} from "../../../models/useraccount.model";

@Component({
  components: {AppCard, AppLoader}
})
export default class ViewAnalysisCoverage extends Vue {
  @Getter('getUseraccount', {namespace: 'useraccount'})
  private getUseraccount!: Useraccount;

  private jamaatsTableData2021: any[] = [];
  private jamaatsTableData2022: any[] = [];
  private jamaatsTableData2023: any[] = [];
  private jamaatsTableData2024: any[] = [];


  private regionsTableDate2021: any[] = [];
  private regionsTableDate2022: any[] = [];
  private regionsTableDate2023: any[] = [];
  private regionsTableDate2024: any[] = [];

  private regionFilterValue: string = '';

  private allRegionItems2021: string[] = [];
  private allRegionItems2022: string[] = [];
  private allRegionItems2023: string[] = [];
  private allRegionItems2024: string[] = [];

  private lajnaMajalisTableData2021: any[] = [];
  private lajnaMajalisTableData2022: any[] = [];
  private lajnaMajalisTableData2023: any[] = [];
  private lajnaMajalisTableData2024: any[] = [];

  private lajnaRegionsTableData2021: any[] = [];
  private lajnaRegionsTableData2022: any[] = [];
  private lajnaRegionsTableData2023: any[] = [];
  private lajnaRegionsTableData2024: any[] = [];

  private lajnaRegionFilterValue: string = '';

  private allLajnaRegionItems2021: string[] = [];
  private allLajnaRegionItems2022: string[] = [];
  private allLajnaRegionItems2023: string[] = [];
  private allLajnaRegionItems2024: string[] = [];

  private regionsTableHeaders = [
    {text: 'Region/LA', value: 'name', filter: this.regionFilter},
    {text: 'Summe Gesamt', value: 'tajneedOverSix'},
    {text: 'Gesendet von Sekretär', value: 'jaizasSentSecretaryMuawina'},
    {text: 'Gesendet < 6 Jahre', value: 'jaizasSentSecretaryMuawinaAgeFiveAndBelow'},
    {text: '80% - 97%', value: 'jaizasBetweenEightyAndNinetySevenPercent'},
    {text: '100%', value: 'jaizasOverHundredPercent'},
    {text: 'Essays', value: 'essays'},
    {text: 'Prüfung berechtigt', value: 'examsEligible'},
    {text: 'Prüfung abgeschlossen', value: 'examsSent'},
    {text: '', value: ''},
  ];

  private lajnaRegionsTableHeaders = [
    {text: 'Region/LA', value: 'name', filter: this.lajnaRegionFilter},
    {text: 'Summe Gesamt', value: 'tajneedOverSix'},
    {text: 'Gesendet von Sekretär', value: 'jaizasSentSecretaryMuawina'},
    {text: 'Gesendet < 6 Jahre', value: 'jaizasSentSecretaryMuawinaAgeFiveAndBelow'},
    {text: '80% - 97%', value: 'jaizasBetweenEightyAndNinetySevenPercent'},
    {text: '100%', value: 'jaizasOverHundredPercent'},
    {text: 'Essays', value: 'essays'},
    {text: 'Prüfung berechtigt', value: 'examsEligible'},
    {text: 'Prüfung abgeschlossen', value: 'examsSent'},
    {text: '', value: ''},
  ];

  private jamaatsTableHeaders = [
    {text: 'Jamaat/Halqa', value: 'name'},
    {text: 'Region/LA', value: 'region', filter: this.regionFilter},
    {text: 'Gesamt', value: 'totalTajneed'},
    {text: 'Gesendet von Sekretär', value: 'jaizasSentSecretaryMuawina'},
    {text: 'Gesendet < 6 Jahre', value: 'jaizasSentSecretaryMuawinaAgeFiveAndBelow'},
    {text: '80% - 97%', value: 'jaizasBetweenEightyAndNinetySevenPercent'},
    {text: '100%', value: 'jaizasOverHundredPercent'},
    {text: 'Essays', value: 'essays'},
    {text: 'Prüfung berechtigt', value: 'examsEligible'},
    {text: 'Prüfung abgeschlossen', value: 'examsSent'},
    {text: '', value: ''},
  ];

  private lajnaMajalisTableHeaders = [
    {text: 'Majlis', value: 'name'},
    {text: 'Region', value: 'region', filter: this.lajnaRegionFilter},
    {text: 'Gesamt', value: 'totalTajneed'},
    {text: 'Gesendet von Sekretär', value: 'jaizasSentSecretaryMuawina'},
    {text: 'Gesendet < 6 Jahre', value: 'jaizasSentSecretaryMuawinaAgeFiveAndBelow'},
    {text: '80% - 97%', value: 'jaizasBetweenEightyAndNinetySevenPercent'},
    {text: '100%', value: 'jaizasOverHundredPercent'},
    {text: 'Essays', value: 'essays'},
    {text: 'Prüfung berechtigt', value: 'examsEligible'},
    {text: 'Prüfung abgeschlossen', value: 'examsSent'},
    {text: '', value: ''},
  ];

  private loadingData = false;

  private tab = null;

  private search = '';

  created() {
    this.loadRegions();
  }

  regionFilter(valueInRow: any) {
    if (!this.regionFilterValue) {
      return true;
    }
    else if (!valueInRow) {
      return false;
    }
    else {
      return valueInRow.toString().indexOf(this.regionFilterValue) >= 0;
    }
  }

  lajnaRegionFilter(valueInRow: any) {
    if (!this.lajnaRegionFilterValue) {
      return true;
    }
    else if (!valueInRow) {
      return false;
    }
    else {
      return valueInRow.toString().indexOf(this.lajnaRegionFilterValue) >= 0;
    }
  }

  loadRegions() {
    this.loadingData = true;
    //@ts-ignore
    AnalysisService.getJaizaCoverage().then((response: any) => {
      console.log(response);
      this.regionsTableDate2021 = response.data[0].regionCoverageJsonList.filter((o: any) => o.type == 'jamaat');
      this.jamaatsTableData2021 = response.data[0].jamaatMajlisCoverageJsonList.filter((o: any) => o.type == 'jamaat');

      this.lajnaRegionsTableData2021 = response.data[0].regionCoverageJsonList.filter((o: any) => o.type == 'lajna');
      this.lajnaMajalisTableData2021 = response.data[0].jamaatMajlisCoverageJsonList.filter((o: any) => o.type == 'lajna');

      response.data[0].regionCoverageJsonList.filter((o: any) => o.type == 'jamaat').forEach((r:any) => {
        this.allRegionItems2021.push(r.name)
      });

      response.data[0].regionCoverageJsonList.filter((o: any) => o.type == 'lajna').forEach((r:any) => {
        this.allLajnaRegionItems2021.push(r.name)
      });

      this.regionsTableDate2022 = response.data[1].regionCoverageJsonList.filter((o: any) => o.type == 'jamaat');
      this.jamaatsTableData2022 = response.data[1].jamaatMajlisCoverageJsonList.filter((o: any) => o.type == 'jamaat');

      this.lajnaRegionsTableData2022 = response.data[1].regionCoverageJsonList.filter((o: any) => o.type == 'lajna');
      this.lajnaMajalisTableData2022 = response.data[1].jamaatMajlisCoverageJsonList.filter((o: any) => o.type == 'lajna');

      response.data[1].regionCoverageJsonList.filter((o: any) => o.type == 'jamaat').forEach((r:any) => {
        this.allRegionItems2022.push(r.name)
      });

      response.data[1].regionCoverageJsonList.filter((o: any) => o.type == 'lajna').forEach((r:any) => {
        this.allLajnaRegionItems2022.push(r.name)
      });

      //2023

      this.regionsTableDate2023 = response.data[2].regionCoverageJsonList.filter((o: any) => o.type == 'jamaat');
      this.jamaatsTableData2023 = response.data[2].jamaatMajlisCoverageJsonList.filter((o: any) => o.type == 'jamaat');

      this.lajnaRegionsTableData2023 = response.data[2].regionCoverageJsonList.filter((o: any) => o.type == 'lajna');
      this.lajnaMajalisTableData2023 = response.data[2].jamaatMajlisCoverageJsonList.filter((o: any) => o.type == 'lajna');

      response.data[2].regionCoverageJsonList.filter((o: any) => o.type == 'jamaat').forEach((r:any) => {
        this.allRegionItems2023.push(r.name)
      });

      response.data[2].regionCoverageJsonList.filter((o: any) => o.type == 'lajna').forEach((r:any) => {
        this.allLajnaRegionItems2023.push(r.name)
      });

      //2024

      this.regionsTableDate2024 = response.data[3].regionCoverageJsonList.filter((o: any) => o.type == 'jamaat');
      this.jamaatsTableData2024 = response.data[3].jamaatMajlisCoverageJsonList.filter((o: any) => o.type == 'jamaat');

      this.lajnaRegionsTableData2024 = response.data[3].regionCoverageJsonList.filter((o: any) => o.type == 'lajna');
      this.lajnaMajalisTableData2024 = response.data[3].jamaatMajlisCoverageJsonList.filter((o: any) => o.type == 'lajna');

      response.data[3].regionCoverageJsonList.filter((o: any) => o.type == 'jamaat').forEach((r:any) => {
        this.allRegionItems2024.push(r.name)
      });

      response.data[3].regionCoverageJsonList.filter((o: any) => o.type == 'lajna').forEach((r:any) => {
        this.allLajnaRegionItems2024.push(r.name)
      });


    }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: error,
        type: 'error'
      });
      this.loadingData = false;
    }).finally(() => {
      setTimeout(() => {
        this.loadingData = false;
      }, 5000);
    });
  }

}
