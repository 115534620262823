import {store} from "../store/index";
import httpResource from "../http/httpResource";
import {resolve} from "q";
import {router} from './../router';
const API_URL = process.env.VUE_APP_API_URL;

export function parseApierror(error: any) {
  console.log("parseapierror", error);
  try {
    if (
      error &&
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      const apierror = error.response.data;
      return {
        status: apierror.status,
        statusCode: error.status,
        timestamp: apierror.timestamp,
        message: apierror.message
      };
    }
  } catch (parseError) {
    return {
      status: "INTERNAL_SERVER_ERROR",
      statusCode: 500,
      timestamp: new Date(),
      message: "Server is not responding.."
    };
  }
}

export function performLogout() {
  const intervalName =   store.getters['useraccount/getIntervalName'];
  if (intervalName) clearInterval(intervalName);
  store.commit("useraccount/SET_IS_AUTHENTICATED", false);
  store.commit('tajneed/SET_TAJNEED', []);
  store.commit("useraccount/LOGOUT");
  httpResource.get("api/auth/logout");
}

export async function refreshTokenInternal() {
  try {
    const response = await httpResource.post("api/auth/refresh");
    if (response.status !== 200)  {
      performLogout();
      router.push({name: 'login'});
    }
  } catch (error) {
    performLogout();
    router.push({name: 'login'});
  }
}

export async function refreshToken() {
  const response = await httpResource.post("api/auth/refresh");
  return response.status;
}

// is only called on application initation (refresh page, or first time load)
export async function getAuthenticatedUser(): Promise<boolean> {
  try {
    const response = await httpResource.get("api/profile/me");
    if (response.status === 200) {
      const currentUser = response.data;
      store.commit('useraccount/SET_USERACCOUNTDATA', currentUser);
      store.commit("useraccount/SET_IS_AUTHENTICATED", true);
      await refreshTokenInternal();
      const intervalName = setInterval(async () => {
        await refreshTokenInternal();
      }, intervalMilliSeconds);
      store.commit("useraccount/SET_INTERVAL_NAME", intervalName);
      return true;
    } else {
      performLogout();
      return false;
    }
  } catch (error) {
    return false;
    performLogout();
  }
}

export const intervalMilliSeconds = 1800000; // 30 minutes
