import httpResource from "../../../http/httpResource";

const API_URL = process.env.VUE_APP_API_URL + '/api/administration/configuration';

class ConfigurationService {

  getConfiguration() {
    return httpResource.get(API_URL + '/');
  }

  setConfiguration(configuration: any) {
    return httpResource.post(API_URL + '/', configuration);
  }
}

export default new ConfigurationService();
