








































































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import {UseraccountService} from '../../../useraccount/useraccount.service';
import AppLoader from "../../../../core/components/AppLoader.vue"
import DeleteAccountDialog from "../dialogs/DeleteAccountDialog.vue";

@Component({
  components: {DeleteAccountDialog, AppLoader}
})
export default class ViewAccounts extends Vue {

  tableData: any[] = [];
  search = "";
  headers = [
    {text: 'Username', value: 'username', filter: this.usernameFilter},
    {text: 'Erstellungsdatum', value: 'createDate'},
    {text: 'Account aktiviert', value: 'activated', filter: this.activatedFilter},
    {text: 'Jamaat ID', value: 'aimsId', filter: this.aimsIdFilter},
    {text: 'Waqf-e-Nau ID', value: 'waqfenauId', filter: this.waqfenauIdFilter},
    {text: 'Vorname', value: 'firstname', filter: this.firstnameFilter},
    {text: 'Name', value: 'lastname', filter: this.lastnameFilter},
    {text: 'Jamaat', value: 'jamaat', filter: this.jamaatFilter},
    {text: 'Majlis', value: 'majlis', filter: this.majlisFilter},
    {text: 'Geb.Datum', value: 'dateOfBirth', filter: this.dateOfBirthFilter},
    {text: 'Aktionen', value: 'dateOfBirth'}
  ];

  private on: any = null;

  private loadingTajneed = false;

  private firstnameFilterValue = "";
  private lastnameFilterValue = "";
  private aimsIdFilterValue = "";
  private waqfenauIdFilterValue = "";
  private jamaatFilterValue = "";
  private majlisFilterValue = "";
  private usernameFilterValue = "";
  private regionFilterValue = "";
  private activatedFilterValue = "";

  private menu1 = false;
  private dateOfBirthFilterValue = null;
  private dateOfBirthFormatted: any | null = "";

  private type = "";

  private isReadOnlyUser = false;

  private selectedAccountForDelete: any | null = null;
  private deleteAccountDialogVisible: boolean = false;


  @Watch('dateOfBirthFilterValue')
  onPropertyChanged1(value: string, oldValue: string) {
    this.dateOfBirthFormatted = this.formatDate(this.dateOfBirthFilterValue);
  }

  created() {
    this.loadAccounts();
  }

  loadAccounts() {
    this.loadingTajneed = true;
      //@ts-ignore
      UseraccountService.getAccountsAndTajneeds().then((response: any) => {
        this.tableData = response.data;
      }).catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: error,
          type: 'error'
        });
        this.loadingTajneed = false;
      }).finally(() => {
        setTimeout(() => {
          this.loadingTajneed = false;
        }, 5000);
      });
  }

  private openDelete(item: any) {
    this.selectedAccountForDelete = JSON.parse(JSON.stringify(item));
    this.deleteAccountDialogVisible = true;
  }

  handleCloseDeleteAccountDialog(){
    this.deleteAccountDialogVisible = false;
    this.loadAccounts();
  }


  formatDate(date: any) {
    if (!date) {
      return null;
    }
    const [year, month, day] = date.split('-');

    return `${day}.${month}.${year}`;
  }

  dateOfBirthFilter(valueInRow: any) {
    if ((!this.dateOfBirthFilterValue || this.dateOfBirthFilterValue == "")) {
      return true;
    } else {

    }
    if (this.dateOfBirthFilterValue) {
      //@ts-ignore
      var dateParts = this.dateOfBirthFilterValue.split("-");
      var dateObjectVon = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]);
      dateParts = valueInRow.substring(0, 10).split("-");
      var dateObjectValueInRow = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]);
      return dateObjectValueInRow == dateObjectVon;
    }

    return valueInRow.substring(0, 10) === this.dateOfBirthFilterValue;
  }

  private firstnameFilter(valueInRow: any) {
    if (!this.firstnameFilterValue) {
      return true;
    }
    else if(!valueInRow) {
      return false;
    }
    else {
      return valueInRow.toString().toUpperCase().indexOf(this.firstnameFilterValue.toUpperCase()) >= 0;
    }
  }

  private aimsIdFilter(valueInRow: any) {
    if (!this.aimsIdFilterValue) {
      return true;
    }
    else if(!valueInRow) {
      return false;
    }
    else {
      return valueInRow.toString().toUpperCase().indexOf(this.aimsIdFilterValue.toUpperCase()) >= 0;
    }
  }

  private activatedFilter(valueInRow: any) {
    if (!this.activatedFilterValue) {
      return true;
    }
    else {
      if (this.activatedFilterValue == 'Alle') {
        return true;
      }
      if (this.activatedFilterValue == 'Ja' && valueInRow == true) {
        return true;
      }
      if (this.activatedFilterValue == 'Nein' && valueInRow == false) {
        return true;
      }
    }
  }

private usernameFilter(valueInRow: any) {
  if (!this.usernameFilterValue) {
    return true;
  }
  else if(!valueInRow) {
    return false;
  }
  else {
    return valueInRow.toString().toUpperCase().indexOf(this.usernameFilterValue.toUpperCase()) >= 0;
  }
}

  private waqfenauIdFilter(valueInRow: any) {
    if (!this.waqfenauIdFilterValue) {
      return true;
    }
    else if(!valueInRow) {
      return false;
    }
    else {
      return valueInRow.toString().toUpperCase().indexOf(this.waqfenauIdFilterValue.toUpperCase()) >= 0;
    }
  }

  private jamaatFilter(valueInRow: any) {
    if (!this.jamaatFilterValue) {
      return true;
    }
    else if(!valueInRow) {
      return false;
    }
    else {
      return valueInRow.toString().toUpperCase().indexOf(this.jamaatFilterValue.toUpperCase()) >= 0;
    }
  }

  private majlisFilter(valueInRow: any) {
    if (!this.majlisFilterValue) {
      return true;
    }
    else if(!valueInRow) {
      return false;
    }
    else {
      return valueInRow.toString().toUpperCase().indexOf(this.majlisFilterValue.toUpperCase()) >= 0;
    }
  }

  private regionFilter(valueInRow: any) {
    if (!this.regionFilterValue) {
      return true;
    }
    else if(!valueInRow) {
      return false;
    }
    else {
      return valueInRow.toString().toUpperCase().indexOf(this.regionFilterValue.toUpperCase()) >= 0;
    }
  }

  private lastnameFilter(valueInRow: any) {
    if (!this.lastnameFilterValue) {
      return true;
    }
    else if(!valueInRow) {
      return false;
    }
    else {
      return valueInRow.toString().toUpperCase().indexOf(this.lastnameFilterValue.toUpperCase()) >= 0;
    }
  }

  private customRowClass(item: any) {
    /* if (item.status === 'NEW_TAJNEED_REQUEST') {
      return 'blue-bg';
    }
    if (item.status === 'NEW_TAJNEED_APPROVED' || item.status === 'SHIFT_APPROVED') {
      return 'green-bg';
    }
    if (item.status === 'DELETE_REQUESTED' || item.status === 'SHIFT_REQUESTED') {
      return 'yellow-bg';
    }
    if (item.status === 'NEW_TAJNEED_DECLINED' || item.status === 'DELETE_TAJNEED_DECLINED') {
      return 'red-bg';
    } else {
      return 'normal';
    } */

    if (item.activated) {
      return 'orange-bg';
    } else {
      return '';
    }
  }

}
