import httpResource from "../../http/httpResource";
import {Missionary, ParentsCounsellingCase} from "@/modules/parents-councelling/parents-councelling.types";

const API_URL = process.env.VUE_APP_API_URL + '/api/parents-counselling/';

class ParentsCouncellingService {

  getJamaatsWithoutMissionary() {
    return httpResource.get(API_URL + 'jamaats-without-missionary');
  }

  createNewMissionaryEntry(missionary: Missionary) {
    return httpResource.post(API_URL + 'missionary', missionary);
  }

  updateMissionaryEntry(missionary: Missionary) {
    return httpResource.put(API_URL + 'missionary', missionary);
  }

  deleteMissionaryEntry(missionaryId: number) {
    return httpResource.delete(API_URL + 'missionary/' + missionaryId);
  }

  getMissionariesList() {
    return httpResource.get(API_URL + 'missionaries');
  }

  createNewCaseEntry(newCase: ParentsCounsellingCase) {
    return httpResource.post(API_URL + 'counselling-case', newCase);
  }

  updateCaseEntry(updateCase: ParentsCounsellingCase) {
    return httpResource.put(API_URL + 'counselling-case', updateCase);
  }

  getCounsellingCases() {
    return httpResource.get(API_URL + 'counselling-cases');
  }

  addCaseToTajneed(caseAddToTajneed: ParentsCounsellingCase) {
    return httpResource.put(API_URL + 'counselling-case/add-to-tajneed', caseAddToTajneed);
  }

  deleteCaseEntry(caseId: number) {
    return httpResource.delete(API_URL + 'counselling-case/' + caseId);
  }
}

export default new ParentsCouncellingService();
