
































































import {Component, Prop, Vue} from "vue-property-decorator";
import StaticData from "../../../../../staticdata/staticdata"
import TajneedService from "../../../tajneed.service"
import LoadingOverlay from "../../../../../core/components/LoadingOverlay.vue"
import {Tajneed} from '../../../tajneed.types';
import Constants from "../../../../../staticdata/constants";
import BasedataService from "../../../../../services/basedata.service"
import {JamaatHalqa, LajnaMajlis} from "../../../../basedata/basedata.types";

@Component({
  components: {LoadingOverlay},
})
export default class commentTajneedEntryDialog extends Vue {

  @Prop({default: null})
  selectedTajneed: Tajneed;

  private comment: string = "";

  private showOverlay = false;

  private notempty = [
    (v: string) => !!v || 'Feld muss ausgefüllt sein'
  ];

  private commentHistory: any[] = [];

  created(){
    //@ts-ignore
    TajneedService.getTajneedCommentHistory(this.selectedTajneed.id)
      .then((response: any) => {
        this.commentHistory = response.data;
      })
      .catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: 'Netzwerkfehler',
          type: 'error'
        });
      })
      .finally(() => {
        setTimeout(() => {
        }, 5000);
      });
  }

  commentTajneed() {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.showOverlay = true;
      //@ts-ignore
      TajneedService.addCommentToTajneed(this.selectedTajneed.id, this.comment)
        .then((response: any) => {
            this.showOverlay = false;
            this.$notify({
              group: 'foo', title: 'Info', text: response.data, type: 'success'
            });
            (this.$refs.form as Vue & { reset: () => boolean }).reset();
            this.$emit('event', "");
          })
        .catch((error: any) => {
          this.$notify({
            group: 'foo', title: 'Fehler', text: error.response!.data.message, type: 'error'
          });
          this.showOverlay = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.showOverlay = false;
          }, 5000);
        });
    }
  }
}
