import {AxiosResponse} from "axios";
import httpResource from "../../http/httpResource";

const API_URL = process.env.VUE_APP_API_URL + '/api';

class DocumentsService {

  getAllDocuments(personId: string) : Promise<AxiosResponse> {
    return httpResource.get(API_URL+ '/documents/' + personId);
  }

  getDocument(personId: string, documentName: string) : Promise<AxiosResponse> {
    return httpResource.get(API_URL+ '/document/' + personId + '/' + documentName );
  }

  uploadDocuments(data: any) {
    return httpResource.post(API_URL + '/documents', data);
  }

}

export default new DocumentsService();
