import httpResource from "../../http/httpResource";

const API_URL = process.env.VUE_APP_API_URL + '/api/downloads';

class DownloadsService {

  getNisabForAge(nisabAge: string) {
    return httpResource.get(API_URL + '/nisab/' + nisabAge);
  }

  getUrduForLevel(level: string) {
    return httpResource.get(API_URL + '/urdu/' + level);
  }
}

export default new DownloadsService();
