










import {
  Component, Vue
} from 'vue-property-decorator';

import AppCard from '../../../core/components/cards/AppCard.vue';
import DashboardCol from '../../../core/components/DashboardCol.vue';
import DashboardRow from '../../../core/components/DashboardRow.vue';
import DashboardTileOwnData from './DashboardTileOwnData.vue';
import {Getter} from "vuex-class";
import {Useraccount} from "../../../models/useraccount.model";

@Component({
  components: {
    DashboardTileOwnData,
    AppCard,
    DashboardCol,
    DashboardRow,
  },
})
export default class DashboardRowOwn extends Vue {

}
