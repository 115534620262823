









import {Component, Prop, Vue} from 'vue-property-decorator';
import AppCardButton from '../../../core/components/cards/AppCardButton.vue';

@Component({
  components: {
    AppCardButton,
  },
})
export default class DownloadTileNisab extends Vue {

}

