




















































import {Component, Vue} from "vue-property-decorator";
import LoadingOverlay from "../../../core/components/LoadingOverlay.vue"
import AppLoader from "../../../core/components/AppLoader.vue"
import ReportService from '../report.service';
import TimeSeriesChart from '../components/TimeSeriesChart.vue';
import moment from 'moment';

@Component({
  components: {AppLoader, LoadingOverlay, TimeSeriesChart}
})
export default class ViewTableMissionaryReports extends Vue {

  tableData: any[] = [];

  headers = [
    {text: 'Jahr', value: 'year'},
    {text: 'Monat', value: 'month'},
    {text: 'Anzahl Waqifin Berichte', value: 'totalFinishedReports'},
    {text: 'Status', value: 'status'},
  ];

  private loadingReports: boolean = false;

  created() {
    if (!this.$route.params.jamaat) {
      this.$router.push({path: '/'});
    }
    this.loadReports();
  }

  private reloadReports() {
    this.loadReports();
  }

  loadReports() {
    this.loadingReports = true;
    //@ts-ignore
    ReportService.getMissionaryReports(this.$route.params.jamaat).then((response: any) => {
      this.tableData = response.data.organizationReportOverviewJsonList;
      this.loadingReports = false;
    }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: error,
        type: 'error'
      });
      this.loadingReports = false;
    }).finally(() => {
      setTimeout(() => {
        this.loadingReports = false;
      }, 5000);
    });
  }

  getMonthForNumber(month: number): string {
    switch (month) {
      case 0:
        return "Jan";
      case 1:
        return "Feb";
      case 2:
        return "Mär";
      case 3:
        return "Apr";
      case 4:
        return "Mai";
      case 5:
        return "Jun";
      case 6:
        return "Jul";
      case 7:
        return "Aug";
      case 8:
        return "Sep";
      case 9:
        return "Okt";
      case 10:
        return "Nov";
      case 11:
        return "Dez";
      default:
        return "";
    }
  }

  getNumberForMonth(month: string): number {
    switch (month) {
      case "Januar":
        return 0;
      case "Februar":
        return 1;
      case "März":
        return 2;
      case "April":
        return 3;
      case "Mai":
        return 4;
      case "Juni":
        return 5;
      case "Juli":
        return 6;
      case "August":
        return 7;
      case "September":
        return 8;
      case "Oktober":
        return 9;
      case "November":
        return 10;
      case "Dezember":
        return 11;
      default:
        return -1;
    }
  }

  private openLocalReport(selectedEntry: any) {
    //@ts-ignore
    this.$router.push({
      path: '/bericht/murabbi/' + selectedEntry.id
    });
  }
}
