import axios from 'axios';
import {Useraccount} from "../models/useraccount.model";
import {UseraccountData} from "@/store/useraccount.store";
import jwt_decode from "jwt-decode";
import {store} from "@/store";
import {UseraccountService} from "../modules/useraccount/useraccount.service";
import httpResource from "../http/httpResource";

const API_URL = process.env.VUE_APP_API_URL;

class AuthService {

  /* login(user: Useraccount): Promise<any> {
    return axios
      .post(API_URL + '/api/auth/signin', {
        username: user.getUsername,
        password: user.getPassword
      })
      .then(response => {
        let signinResponse: SignInResponse= {tajneedFirstName: "", tajneedLastName: "" , active: false, code: -1, confirmationAttempts: 3};
        if(response) {
          console.log(response);
          if (response.data.accessToken) {
            localStorage.setItem('user', JSON.stringify(response.data));
            store.commit('useraccount/SET_USERACCOUNTDATA', JSON.parse((localStorage.getItem("user") || '{}')));
            signinResponse.code = 200;
            signinResponse.active = true;
            return signinResponse;
          } else {
            //Account not activated
            signinResponse.code = 403;
            signinResponse.active = false;
            signinResponse.tajneedFirstName = response.data.tajneedFirstName;
            signinResponse.tajneedLastName = response.data.tajneedLastName;
            signinResponse.confirmationAttempts = response.data.confirmationAttempts;
            return signinResponse;
          }
        } else {
          // Bad credentials
          signinResponse.code = 401;
          signinResponse.active = false;
          return signinResponse;
        }
      });
  } */

  /* logout() {
    localStorage.removeItem('user');
    store.commit('tajneed/SET_TAJNEED', []);
  } */

  /* authenticate(): Promise<any> {

    return new Promise((resolve, reject) => {

      let userInLocStorage = localStorage.getItem("user") || null;

      //let userInLocStorage = JSON.parse((localStorage.getItem("user") || '{}'));

      let authToken = JSON.parse(localStorage.getItem('user') || '{}').accessToken;

      let isLocStorageUserAuthenticated = false;

      //Check if token is expired or still valid
      if (!!authToken) {
        let token = jwt_decode(authToken);
        //@ts-ignore
        if (!token.exp) {
          isLocStorageUserAuthenticated = false;
        } else {
          let expirationDate = new Date(0);
          //@ts-ignore
          expirationDate.setUTCSeconds(token.exp);
          //@ts-ignore
          isLocStorageUserAuthenticated = (new Date() < expirationDate);
        }
      }


      if (userInLocStorage && isLocStorageUserAuthenticated) {
        store.commit('useraccount/SET_USERACCOUNTDATA', JSON.parse((localStorage.getItem("user") || '{}')));

        let useraccount: Useraccount = store.getters['useraccount/getUseraccount'];

        if (useraccount.isNormalUser) {
          UseraccountService.getOwnMetaData(useraccount.getUsername)
            .then((response: any) => {
              store.commit("accountmetadata/SET_OWN_METADATA", response.data);
              resolve(true);
            }).catch((error: any) => {
              resolve(false);
            }).finally(() => {
              setTimeout(() => {
                resolve(false);
              }, 5000);
            });
        } else if (useraccount.isAdministrativeUser || useraccount.isNaibAmirUser) {
          //For administrative user, we load the tajneed at TajneedTable
          resolve(true);
        }

      } else {
        store.commit('tajneed/SET_TAJNEED', []);
        resolve(false);
      }
    });
  } */

  resetPassword(email: string) {
    return httpResource.get('/api/auth/resetpassword?email=' + email);
  }

  requestDataDelete(email: string) {
    return httpResource.get('/api/auth/request-data-delete?email=' + email);
  }

  activateAccount(data: any){
    return httpResource.post('/api/auth/activateaccount', data);
  }

}

export interface SignInResponse {
  code: number;
  active: boolean;
  tajneedFirstName: string;
  tajneedLastName: string;
  confirmationAttempts: number;
}

export default new AuthService();
