














































































import {Component, Prop, Vue, Watch,} from 'vue-property-decorator';
import {Getter} from "vuex-class";
import {Useraccount} from "../../../../models/useraccount.model";

interface File {
  id?: number;
  fileData: any;
  fileSize: number;
  fileName: string;
  fileType: string;
}

@Component({
  components: {},
})
export default class FileUploader extends Vue {

  @Getter('getUseraccount', {namespace: 'useraccount'})
  private getUseraccount!: Useraccount;

  @Prop({required: false, default: ''})
  acceptedFileTypes!: string;

  @Prop({required: false, default: -1})
  limitFilesize!: number;

  @Prop({required: false, default: -1})
  limitFiles!: number;

  @Prop({required: false, default: null})
  filedata!: any;

  @Prop({required: false, default: false})
  disabled!: boolean;

  private files: File[] = [];

  private isReadOnlyUser = false;

  get getFilesize(): number {
    return this.files.reduce((accumulator, currentValue) => accumulator + currentValue.fileSize, 0);
  }

  get hasFilesLimit(): boolean {
    return this.limitFiles > 0;
  }

  get hasFilesizeLimit(): boolean {
    return this.limitFilesize > 0;
  }

  get getFile(): File | null {
    if (this.files.length > 0) {
      if (this.files[0].fileData.indexOf('base64') >= 0) {
        this.files[0].fileData = this.files[0].fileData.split('base64,')[1];
      }
      return this.files[0];
    } else {
      return null;
    }
  }

  created() {
    if (this.getUseraccount.getRoles.indexOf("ROLE_NAIB_AMIR") >= 0) {
      this.isReadOnlyUser = true;
    }
    // When changing selected tajneed in header, then this might get null if
    // that tajneed does not have image
    if (this.filedata != null) {
      this.files.push(this.filedata);
    }
  }

  //We need this, cause in edit mode the dateOfBirth on parent is set after dateOfBirthTextField.vue is rendered
  @Watch('filedata')
  onPropertyChanged(value: any, oldValue: any) {
    this.files = [];
    if (this.filedata != null) {
      this.files.push(this.filedata);
    }
  }

  private processFiles(evt: any) {
    const files = Array.prototype.slice.call(evt.target.files);

    const filesizeNewImages: number = files.reduce(
      (accumulator, currentValue) => accumulator + currentValue.size,
      0,
    );

    files.forEach((file) => {
      if (!this.acceptedFileTypes.includes(file.type)) {
        this.$notify({group: 'foo', title: 'Fehler', text: "Dateityp wird nicht unterstützt", type: 'error'});
        return;
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        this.files = [];
        this.files.push({
          //@ts-ignore
          fileData: e!.target!.result,
          fileSize: file.size,
          fileName: file.name,
          fileType: file.type,
        });
      };

      this.resizeImage(files[0], 400, 400).then(blob => {
        reader.readAsDataURL(blob);
      }, err => {
        console.error("Photo error", err);
      });

    });
  }

  private deleteFile() {
    this.files = [];
  }

  resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        let width = image.width;
        let height = image.height;

        if (width <= maxWidth && height <= maxHeight) {
          //@ts-ignore
          resolve(file);
        }

        let newWidth;
        let newHeight;

        if (width > height) {
          newHeight = height * (maxWidth / width);
          newWidth = maxWidth;
        } else {
          newWidth = width * (maxHeight / height);
          newHeight = maxHeight;
        }

        let canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;

        let context = canvas.getContext('2d');

        //@ts-ignore
        context.drawImage(image, 0, 0, newWidth, newHeight);
        //@ts-ignore
        canvas.toBlob(resolve, file.type);
      };
      image.onerror = reject;
    });
  }
}

