export const FileReadUtils = {
  openSaveAs(attachment: any, fileName: string) {
    // @ts-ignore
    var isIE = false || !!document.documentMode;

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // IE workaround
      var byteCharacters = atob(attachment);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var blob = new Blob([byteArray], {type: 'application/pdf'});
      window.navigator.msSaveOrOpenBlob(blob, attachment.fileName);

    } else {

      var byte = this.base64ToArrayBuffer(attachment);
      var file = new Blob([byte], {type: 'application/pdf'});

      var a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      setTimeout(function() {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  },

  openSaveAsZip(attachment: any, fileName: string) {
    // @ts-ignore
    var isIE = false || !!document.documentMode;

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // IE workaround
      var byteCharacters = atob(attachment);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var blob = new Blob([byteArray], {type: 'application/zip'});
      window.navigator.msSaveOrOpenBlob(blob, attachment.fileName);

    } else {

      var byte = this.base64ToArrayBuffer(attachment);
      var file = new Blob([byte], {type: 'application/zip'});

      var a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      setTimeout(function() {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  },

  base64ToArrayBuffer(_base64Str: any) {
    var binaryString = window.atob(_base64Str);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  },

  readFileAsync(file: any) {
    return new Promise((resolve, reject) => {
      // Internet Explorer
      if (!FileReader.prototype.readAsBinaryString) {
        let binary = '';
        // var pt = this;
        const reader = new FileReader();
        reader.onload = () => {
          const bytes = new Uint8Array(reader.result as ArrayBuffer);
          const length = bytes.byteLength;
          for (let i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          // pt.result  - readonly so assign binary
          // pt.content = binary;
          // pt.onload();
          resolve(binary);
        };
        reader.readAsArrayBuffer(file);
      } else {
        // Other browsers
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsBinaryString(file);
      }
    });
  },
}
