import Vue from 'vue';
import {
  Action,
  Module, Mutation, VuexModule,
} from 'vuex-module-decorators';

import { Tajneed } from './tajneed.types';

@Module({
  namespaced: true,
})
export class TajneedStore extends VuexModule {

  private fetchingTajneed: boolean = false;

  //We still need this to store the TajneedData for National/Local
  private tajneed: Tajneed[] = [];

  private studentOrGraduate: string = "";

  get getTajneed(): Tajneed[] {
    return this.tajneed;
  }

  get getStudentOrGraduate(): string {
    return this.studentOrGraduate;
  }

  @Mutation
  private SET_STUDENTORGRADUATE(studentOrGraduate: string): void {
    this.studentOrGraduate = studentOrGraduate;
  }

  @Mutation
  private SET_FETCHING_TAJNEED(value: boolean): void {
    this.fetchingTajneed = value;
  }

  @Mutation
  private SET_TAJNEED(tajneed: Tajneed[]): void {
    this.tajneed = tajneed;
  }

  @Mutation
  private UPDATE_TAJNEED(tajneed: Tajneed): void {
    let index = this.tajneed.findIndex(function(item, i) {
      return item.id === tajneed.id;
    });

    this.tajneed[index] = tajneed;
    this.tajneed = JSON.parse(JSON.stringify(this.tajneed));
  }

  @Mutation
  private ADD_TAJNEED(newTajneed: Tajneed): void {
    this.tajneed.push(newTajneed);
  }

  /* @Action({commit: 'SET_TAJNEED', rawError: true})
  private setTajneed(): any {
    return TajneedService.getTajneedAllNational()
      .then((response: any) => response.data)
      .catch((error: any) => {
        Vue.prototype.$displayServerResponse(error.response!.data);
      });
  } */
}
