







































































































































import {Component, Vue} from "vue-property-decorator";
import AppLoader from "../../../core/components/AppLoader.vue"
import AppCard from '../../../core/components/cards/AppCard.vue';
import AnalysisService from "../analysis.service";
import {Getter} from "vuex-class";
import {Useraccount} from "../../../models/useraccount.model";

@Component({
  components: {AppCard, AppLoader}
})
export default class ViewAnalysisCoverage extends Vue {
  @Getter('getUseraccount', {namespace: 'useraccount'})
  private getUseraccount!: Useraccount;

  private jamaatsTableData: any[] = [];

  private regionsTableDate: any[] = [];

  private regionFilterValue: string = '';

  private allRegionItems: string[] = [];

  private lajnaMajalisTableData: any[] = [];

  private lajnaRegionsTableData: any[] = [];

  private lajnaRegionFilterValue: string = '';

  private allLajnaRegionItems: string[] = [];

  private regionsTableHeaders = [
    {text: 'Region/LA', value: 'name', filter: this.regionFilter},
    {text: 'Summe Gesamt', value: 'tajneed'},
    {text: 'Summe Grün', value: 'updated'},
    {text: 'Rest', value: 'rest'},
    {text: 'Region Prozent', value: 'percentage'},
    {text: '', value: ''},
    {text: 'Summe gelb', value: 'registered'},
    {text: 'Summe blau', value: 'deleteShiftRequested'},
  ];

  private lajnaRegionsTableHeaders = [
    {text: 'Region/LA', value: 'name', filter: this.lajnaRegionFilter},
    {text: 'Summe Gesamt', value: 'tajneed'},
    {text: 'Summe Grün', value: 'updated'},
    {text: 'Rest', value: 'rest'},
    {text: 'Region Prozent', value: 'percentage'},
    {text: '', value: ''},
    {text: 'Summe gelb', value: 'registered'},
    {text: 'Summe blau', value: 'deleteShiftRequested'},
  ];

  private jamaatsTableHeaders = [
    {text: 'Jamaat/Halqa', value: 'name'},
    {text: 'Region/LA', value: 'region', filter: this.regionFilter},
    {text: 'Gesamt', value: 'tajneed'},
    {text: 'Grün', value: 'updated'},
    {text: 'Rest', value: 'rest'},
    {text: 'Prozent', value: 'percentage'},
    {text: '', value: ''},
    {text: 'Gelb', value: 'registered'},
    {text: 'Blau', value: 'deleteShiftRequested'},
  ];

  private lajnaMajalisTableHeaders = [
    {text: 'Majlis', value: 'name'},
    {text: 'Region', value: 'region', filter: this.lajnaRegionFilter},
    {text: 'Gesamt', value: 'tajneed'},
    {text: 'Grün', value: 'updated'},
    {text: 'Rest', value: 'rest'},
    {text: 'Prozent', value: 'percentage'},
    {text: '', value: ''},
    {text: 'Gelb', value: 'registered'},
    {text: 'Blau', value: 'deleteShiftRequested'},
  ];

  private loadingData = false;

  private tab = null;

  private search = '';

  created() {
    this.loadRegions();
  }

  regionFilter(valueInRow: any) {
    if (!this.regionFilterValue) {
      return true;
    }
    else if (!valueInRow) {
      return false;
    }
    else {
      return valueInRow.toString().indexOf(this.regionFilterValue) >= 0;
    }
  }

  lajnaRegionFilter(valueInRow: any) {
    if (!this.lajnaRegionFilterValue) {
      return true;
    }
    else if (!valueInRow) {
      return false;
    }
    else {
      return valueInRow.toString().indexOf(this.lajnaRegionFilterValue) >= 0;
    }
  }

  loadRegions() {
    this.loadingData = true;
    //@ts-ignore
    AnalysisService.getJamaatsCoverage().then((response: any) => {
      this.regionsTableDate = response.data.regionCoverageJsonList.filter((o: any) => o.type == 'jamaat');
      this.jamaatsTableData = response.data.jamaatMajlisCoverageJsonList.filter((o: any) => o.type == 'jamaat');

      this.lajnaRegionsTableData = response.data.regionCoverageJsonList.filter((o: any) => o.type == 'lajna');
      this.lajnaMajalisTableData = response.data.jamaatMajlisCoverageJsonList.filter((o: any) => o.type == 'lajna');

      response.data.regionCoverageJsonList.filter((o: any) => o.type == 'jamaat').forEach((r:any) => {
        this.allRegionItems.push(r.name)
      });

      response.data.regionCoverageJsonList.filter((o: any) => o.type == 'lajna').forEach((r:any) => {
        this.allLajnaRegionItems.push(r.name)
      });

    }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: error,
        type: 'error'
      });
      this.loadingData = false;
    }).finally(() => {
      setTimeout(() => {
        this.loadingData = false;
      }, 5000);
    });
  }

}
