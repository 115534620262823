










































import {Component, Vue} from "vue-property-decorator";
import AppLoader from "../../../core/components/AppLoader.vue"
import AppCard from '../../../core/components/cards/AppCard.vue';
import ViewJamaats from "./ViewJamaats.vue";
import ViewLajnaMajalis from "./ViewLajnaMajalis.vue";
import {Getter} from "vuex-class";
import {Useraccount} from "../../../models/useraccount.model";

@Component({
  components: {ViewLajnaMajalis, ViewJamaats, AppCard, AppLoader}
})
export default class ViewBasedata extends Vue {
  @Getter('getUseraccount', {namespace: 'useraccount'})
  private getUseraccount!: Useraccount;

  private tab = null;

  private loadJamaats: boolean = false;
  private loadLajna: boolean = false;

  created() {
    this.loadBaseData();
  }

  private loadBaseData() {
    if (this.getUseraccount.isNationalJamaatUser) {
      if(this.tab==1) {
        if(this.loadJamaats) {
          this.loadJamaats = false;
        } else {
          this.loadJamaats = true;
        }
      }
      if(this.tab==0) {
        if(this.loadLajna) {
          this.loadLajna = false;
        } else {
          this.loadLajna = true;
        }
      }
    }
  }

  private onTabJamaatClicked() {
    this.loadBaseData();
  }

  private onTabLajnaClicked() {
    this.loadBaseData();
  }

}
