var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"padding":"20px"}},[_c('h2',{attrs:{"align":"center"}},[_vm._v("Murabbie Silsila hinzufügen")]),_c('br'),_c('v-container',[_c('v-form',{ref:"form"},[_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"rules":[
          _vm.rules.required,
          _vm.rules.digitsOnly ],"dense":"","label":"Jamaat-ID"},model:{value:(_vm.missionary.aimsId),callback:function ($$v) {_vm.$set(_vm.missionary, "aimsId", $$v)},expression:"missionary.aimsId"}}),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"rules":[
          _vm.rules.required
        ],"dense":"","label":"Name"},model:{value:(_vm.missionary.name),callback:function ($$v) {_vm.$set(_vm.missionary, "name", $$v)},expression:"missionary.name"}}),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"rules":[
          _vm.rules.required,
          _vm.rules.digitsOnly ],"dense":"","label":"Telefonnummer"},model:{value:(_vm.missionary.phoneNumber),callback:function ($$v) {_vm.$set(_vm.missionary, "phoneNumber", $$v)},expression:"missionary.phoneNumber"}}),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"rules":_vm.emailRules,"dense":"","label":"E-Mail"},model:{value:(_vm.missionary.email),callback:function ($$v) {_vm.$set(_vm.missionary, "email", $$v)},expression:"missionary.email"}}),_c('v-autocomplete',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","multiple":"","small-chips":"","hide-selected":"","items":_vm.jamaatItems,"rules":[
          _vm.rules.required ],"label":"Jamaats","clearable":""},model:{value:(_vm.missionary.jamaats),callback:function ($$v) {_vm.$set(_vm.missionary, "jamaats", $$v)},expression:"missionary.jamaats"}})],1)],1),_c('br'),_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-btn',{staticStyle:{"border-radius":"0px"},attrs:{"color":"primary"},nativeOn:{"click":function($event){return _vm.createNewMissionaryEntry($event)}}},[(_vm.selectedMissionary)?_c('span',[_vm._v("Murabbie Silsila bearbeiten")]):_c('span',[_vm._v("Murabbie Silsila hinzufügen")])])],1),_c('br'),(_vm.showOverlay)?_c('LoadingOverlay'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }