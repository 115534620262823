
class Constants {

  REASON_SHIFT_WITHIN_COUNTRY = "Umzug Inland";
  REASON_SHIFT_OUTSIDE_OF_COUNTRY = "Umzug Ausland";
  REASON_EXPIRED = "Verstorben";
  REASON_AMOORE_AMA_CASE = "Amoore Ama Case";
  REASON_WAQFENAU_REFUSAL = "Möchte kein Waqfenau mehr sein";
  REASON_JAMIA = "Jamia";

  grundItemsStrArray = [
    this.REASON_SHIFT_WITHIN_COUNTRY,
    this.REASON_SHIFT_OUTSIDE_OF_COUNTRY,
    this.REASON_EXPIRED,
    this.REASON_AMOORE_AMA_CASE,
    this.REASON_WAQFENAU_REFUSAL,
    this.REASON_JAMIA
  ];


  JAMIA_DEUTSCHLAND = "Jamia Deutschland";
  JAMIA_UK = "Jamia UK";
  JAMIA_CANADA = "Jamia Canada";
  JAMIA_GHANA = "Jamia Ghana";
  JAMIA_PAKISTAN = "Jamia Pakistan";

  jamiaItemsStrArray = [
    this.JAMIA_DEUTSCHLAND,
    this.JAMIA_UK,
    this.JAMIA_CANADA,
    this.JAMIA_GHANA,
    this.JAMIA_PAKISTAN,
  ];

}
export default new Constants();
