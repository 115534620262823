import httpResource from "../../http/httpResource";
import {Email} from "@/modules/email/email.types";

const API_URL = process.env.VUE_APP_API_URL + '/api/communication';

class EmailService {
  sendEmail(email: Email) {
    return httpResource.post(API_URL+ '/email/new', email);
  }

  sendTestEmail(email: Email) {
    return httpResource.post(API_URL+ '/email/new/test', email);
  }
}

export default new EmailService();
