import httpResource from "../../http/httpResource";
import {JamaatHalqa, LajnaMajlis, LajnaRegion, RegionLocalAmarat} from "@/modules/basedata/basedata.types";

const API_URL = process.env.VUE_APP_API_URL + '/api/basedata/';

class BasedataService {

  getJamaats() {
    return httpResource.get(API_URL + 'jamaats');
  }

  getRegionsandLocalAmarats() {
    return httpResource.get(API_URL + 'regionsandlocalamarats');
  }

  getJamaatsAndRegions() {
    return httpResource.get(API_URL + 'jamaatsandregions');
  }

  getMajalis() {
    return httpResource.get(API_URL + 'majalis');
  }

  getJamaatsLajnaMajalisandLajnaRegions() {
    return httpResource.get(API_URL + 'jamaatslajnamajalisandregions');
  }

  changeJamaatName(jamaatHalqa: JamaatHalqa) {
    return httpResource.post(API_URL + 'jamaatname', jamaatHalqa);
  }

  changeRegionName(regionLocalAmarat: RegionLocalAmarat) {
    return httpResource.post(API_URL + 'regionname', regionLocalAmarat);
  }

  changeLajnaMajlisName(majlis: LajnaMajlis) {
    return httpResource.post(API_URL + 'lajnamajlisname', majlis);
  }

  changeLajnaRegionName(region: LajnaRegion) {
    return httpResource.post(API_URL + 'lajnaregionname', region);
  }

  getLajnaRegions() {
    return httpResource.get(API_URL + 'lajnaregions');
  }

  changeRegionForJamaat(jamaatHalqa: JamaatHalqa) {
    return httpResource.post(API_URL + 'changeregionforjamaat', jamaatHalqa);
  }

  changeRegionForLajnaMajlis(lajnaMajlis: LajnaMajlis) {
    return httpResource.post(API_URL + 'changeregionforlajnamajlis', lajnaMajlis);
  }

  changeJamaatForLajnaMajlis(lajnaMajlis: LajnaMajlis) {
    return httpResource.post(API_URL + 'changejamaatforlajnamajlis', lajnaMajlis);
  }

  createNewRegionLa(newRegionName: string, localAmarat: boolean) {
    return httpResource.post(API_URL + 'createnewregionla/' + newRegionName + '/' + localAmarat);
  }

  createNewLajnaRegion(newRegionName: string) {
    return httpResource.post(API_URL + 'createnewlajnaregion/' + newRegionName);
  }

  deleteRegionLa(regionId: number) {
    return httpResource.delete(API_URL + 'regionla/delete/' + regionId);
  }

  deleteLajnaRegion(lajnaRegionId: number) {
    return httpResource.delete(API_URL + 'lajnaregion/delete/' + lajnaRegionId);
  }

  createNewJamaatHalqa(newJamaatHalqaName: string, regionLa:string) {
    return httpResource.post(API_URL + 'jamaathalqa/create/' + newJamaatHalqaName + '/' + regionLa);
  }

  createNewLajnaMajlis(newLajnaMajlisName: string, lajnaRegion:string, jamaat:string) {
    return httpResource.post(API_URL + 'lajnamajlis/create/' + newLajnaMajlisName + '/' + lajnaRegion + '/' + jamaat);
  }

  deleteJamaatHalqa(jamaatHalqaId: number) {
    return httpResource.delete(API_URL + 'jamaathalqa/delete/' + jamaatHalqaId);
  }

  deleteLajnaMajlis(lajnaMajlisId: number) {
    return httpResource.delete(API_URL + 'lajnamajlis/delete/' + lajnaMajlisId);
  }

  getJamaatAndMajalisForUser() {
    return httpResource.get('/api/basedata/jamaatsandmajalisforuser');
  }

  resetPassword(organizationId: string, organizationType: string) {
    return httpResource.post(API_URL + 'reset-password', {
      organizationId,
      organizationType
    });
  }
}

export default new BasedataService();
