































































































































































































































































































































































































































































































































































































































































import moment from 'moment';
import {Component, Vue} from "vue-property-decorator";
import LoadingOverlay from "../../../core/components/LoadingOverlay.vue"
import AppLoader from "../../../core/components/AppLoader.vue"
import {LocalReport, LocalReportJson, ReportTajneedSessionAttendedSet} from '../report.types';
import ReportService from '../report.service';
import TimeSeriesChart from '../components/TimeSeriesChart.vue';
import {Getter} from "vuex-class";
import {Useraccount} from "../../../models/useraccount.model";
import LocalReportIndividualData from "../components/LocalReportIndividualData.vue";
import {Tajneed} from "../../tajneed/tajneed.types";
import LocalReportSignatureInput from "../components/LocalReportSignatureInput.vue";
import {FileReadUtils} from "../../../utils/filereadutils";

@Component({
  components: {LocalReportIndividualData, AppLoader, LoadingOverlay, TimeSeriesChart, LocalReportSignatureInput}
})
export default class ViewLocalReport extends Vue {
  @Getter('getUseraccount', {namespace: 'useraccount'})
  private getUseraccount!: Useraccount;

  private loadingReports: boolean = false;

  private allTajneed: Tajneed[] = [];

  private allTajneedItems: any[] = [];

  private reportTajneedContactedList: any[] = [];

  private reportTajneedSessionAttendedSet: ReportTajneedSessionAttendedSet[] = [];

  private finishEnabled: boolean = true;

  private saveEnabled: boolean = false;

  private showIdividualData: boolean = false;

  private search = '';

  private signature: string = '';

  private tajneedAndReportsData: any = null;

  private report: LocalReport | null = null;

  private items =  [
    { state: 'Ja', abbr: true },
    { state: 'Nein', abbr: false }
  ];

  private rules = {
    required: (value: any) => !!value || 'Pflichtfeld',
    digitsOnly: (v: string) => /^(\s*|\d+)$/.test(v) || 'Zur Zahlen erlaubt',
  };

  created() {
    if (!this.$route.params.month) {
      this.$router.push({path: '/bericht/organisation/tabelle'});
    } else {
      let reportMonth = this.$route.params.month;
      if (reportMonth.toString().length == 1) {
        reportMonth = "0" + (reportMonth.toString());
      }

      let today = moment();
      let dateAfterWhichFinishPossible = moment("01." + reportMonth + "." + this.$route.params.year, "DD.MM.YYYY").add(1, 'M');
      if (today.isSameOrAfter(dateAfterWhichFinishPossible)) {
        // Current local Date is after the 10th of the next month
        this.saveEnabled = true;
        this.finishEnabled = true;
        this.showIdividualData = true;
      } else {
        // Current local Date is before the 10th of the next month
        this.saveEnabled = false;
        this.finishEnabled = false;
      }
      let dateAfterWhichFinishIsNotPossibleAnymore = moment("11." + reportMonth + "." + this.$route.params.year, "DD.MM.YYYY").add(1, 'M').endOf('month');
      if (today.isSameOrAfter(dateAfterWhichFinishIsNotPossibleAnymore)) {
        // Current local Date is after the 13th of the next month
        this.finishEnabled = false;
        this.saveEnabled = true;
        this.showIdividualData = true;
      }
      this.loadReports();
    }
  }

  private reloadReports() {
    this.loadReports();
  }

  loadReports() {
    this.loadingReports = true;
    //@ts-ignore
    let type = '';
    ReportService.getLocalReport(this.$route.params.month, this.$route.params.year, this.$route.params.type, this.$route.params.organizationName).then((response: any) => {
      this.report = response.data.report;
      this.tajneedAndReportsData = response.data
      this.allTajneed = response.data.allTajneed;
      for (var i = 0; i < response.data.allTajneed.length; i++) {
        this.allTajneedItems.push({
          text: response.data.allTajneed[i].firstname + ' ' + response.data.allTajneed[i].lastname,
          portalId: response.data.allTajneed[i].id
        });
      }
      this.reportTajneedContactedList = [];
      response.data.report.reportTajneedContactedList.forEach((c: any) => {
        this.reportTajneedContactedList.push({
          text: c.text,
          portalId: c.portalId,
          contactType: c.contactType
        });
      });
      this.reportTajneedSessionAttendedSet = [];
      this.addToReportTajneedSessionAttandedSet(response.data.report.reportTajneedSessionAttendedSet, 'NISAB_CLASS', 0);
      this.addToReportTajneedSessionAttandedSet(response.data.report.reportTajneedSessionAttendedSet, 'NISAB_CLASS', 1);
      this.addToReportTajneedSessionAttandedSet(response.data.report.reportTajneedSessionAttendedSet, 'NISAB_CLASS', 2);
      this.addToReportTajneedSessionAttandedSet(response.data.report.reportTajneedSessionAttendedSet, 'NISAB_CLASS', 3);
      this.addToReportTajneedSessionAttandedSet(response.data.report.reportTajneedSessionAttendedSet, 'URDU_CLASS', 0);
      this.addToReportTajneedSessionAttandedSet(response.data.report.reportTajneedSessionAttendedSet, 'URDU_CLASS', 1);
      this.addToReportTajneedSessionAttandedSet(response.data.report.reportTajneedSessionAttendedSet, 'URDU_CLASS', 2);
      this.addToReportTajneedSessionAttandedSet(response.data.report.reportTajneedSessionAttendedSet, 'URDU_CLASS', 3);
      this.signature = response.data.report.signature;
      this.loadingReports = false;
    }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: error,
        type: 'error'
      });
      this.loadingReports = false;
    }).finally(() => {
      setTimeout(() => {
        this.loadingReports = false;
      }, 5000);
    });
  }

  private addToReportTajneedSessionAttandedSet(setFromBackend: ReportTajneedSessionAttendedSet[], sessionType: string, index: number) {
    if(setFromBackend.filter((s: ReportTajneedSessionAttendedSet) => s.session == index && s.sessionType == sessionType).length > 0) {
        this.reportTajneedSessionAttendedSet.push(setFromBackend.filter((s: ReportTajneedSessionAttendedSet) => s.session == index && s.sessionType == sessionType)[0]);
      } else {
        this.reportTajneedSessionAttendedSet.push({
          attendedTajneedList: [],
          session: index,
          sessionType: sessionType
        });
      }
  }

  private compare(a: ReportTajneedSessionAttendedSet, b: ReportTajneedSessionAttendedSet) {
    if (a.session < b.session) {
      return -1;
    }
    if (a.session > b.session) {
      return 1;
    }
    return 0;
  }

  private addRowToContactedSet() {
    this.reportTajneedContactedList.push({});
  }

  private removeRowFromContactedSet(index: number) {
    //@ts-ignore
    this.reportTajneedContactedList.splice(index, 1);
  }


  private saveSignature(signature: { signature: string }, type: string) {
    this.signature = signature.signature;
  }

  private save(type: string) {
    if (type != 'save' && !this.signature) {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: "Signatur fehlt",
        type: 'error'
      });
    } else {
      let test: LocalReportJson = {
        month: this.$route.params.month,
        year: this.$route.params.year,
        parentsContactedPersonal: this.report!.parentsContactedPersonal,
        parentsContactedPhoneOnline: this.report!.parentsContactedPhoneOnline,
        reportTajneedSessionAttendedSet: this.reportTajneedSessionAttendedSet,

        reportTajneedContactedList: this.reportTajneedContactedList,
        
        ijlasFirstQuarterHeld: this.report!.ijlasFirstQuarterHeld,
        ijlasAttendanceParentsFirstQuarter: this.report!.ijlasAttendanceParentsFirstQuarter,
        ijlasAttendanceWaqifinWaqifatFirstQuarter: this.report!.ijlasAttendanceWaqifinWaqifatFirstQuarter,
      

        ijlasSecondQuarterHeld: this.report!.ijlasSecondQuarterHeld,
        ijlasAttendanceParentsSecondQuarter: this.report!.ijlasAttendanceParentsSecondQuarter,
        ijlasAttendanceWaqifinWaqifatSecondQuarter: this.report!.ijlasAttendanceWaqifinWaqifatSecondQuarter,
      

        ijlasThirdQuarterHeld: this.report!.ijlasThirdQuarterHeld,
        ijlasAttendanceParentsThirdQuarter: this.report!.ijlasAttendanceParentsThirdQuarter,
        ijlasAttendanceWaqifinWaqifatThirdQuarter: this.report!.ijlasAttendanceWaqifinWaqifatThirdQuarter,
      

        ijlasFourthQuarterHeld: this.report!.ijlasFourthQuarterHeld,
        ijlasAttendanceParentsFourthQuarter: this.report!.ijlasAttendanceParentsFourthQuarter,
        ijlasAttendanceWaqifinWaqifatFourthQuarter: this.report!.ijlasAttendanceWaqifinWaqifatFourthQuarter,

        furtherActivities: this.report!.furtherActivities,
        status: type == 'save' ? 'SAVED' : 'FINISHED',
        signature: this.signature
      };
      ReportService.saveLocalReport(test)
        .then((response: any) => {
          this.$notify({
            group: 'foo',
            title: 'Info',
            text: "Gespeichert",
            type: 'success'
          });
          this.loadReports();
        }).catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: '',
          type: 'error'
        });
      }).finally(() => {
        setTimeout(() => {
        }, 5000);
      });
    }
  }

  private printReport() {
    let type = "";
    if (this.report!.jamaatHalqa != null && this.report!.jamaatHalqa != '') {
      type = "jamaat";
    } else if (this.report!.lajnaMajlis != null && this.report!.lajnaMajlis != '') {
      type = "lajna"
    }
    ReportService.printLocalReport(this.$route.params.month, this.$route.params.year, this.$route.params.organizationName, type).then((response: any) => {
      FileReadUtils.openSaveAs(response.data.document, 'bericht.pdf');
    }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: error,
        type: 'error'
      });
      this.loadingReports = false;
    }).finally(() => {
      setTimeout(() => {
        this.loadingReports = false;
      }, 5000);
    });
  }

  getMonthForNumber(month: number): string {
    switch (month) {
      case 0:
        return "Januar";
      case 1:
        return "Februar";
      case 2:
        return "März";
      case 3:
        return "April";
      case 4:
        return "Mai";
      case 5:
        return "Juni";
      case 6:
        return "Juli";
      case 7:
        return "August";
      case 8:
        return "September";
      case 9:
        return "Oktober";
      case 10:
        return "November";
      case 11:
        return "Dezember";
      default:
        return "";
    }
  }

  getNumberForMonth(month: string): number {
    switch (month) {
      case "Januar":
        return 0;
      case "Februar":
        return 1;
      case "März":
        return 2;
      case "April":
        return 3;
      case "Mai":
        return 4;
      case "Juni":
        return 5;
      case "Juli":
        return 6;
      case "August":
        return 7;
      case "September":
        return 8;
      case "Oktober":
        return 9;
      case "November":
        return 10;
      case "Dezember":
        return 11;
      default:
        return -1;
    }
  }
}
