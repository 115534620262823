



















































































































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import TajneedService from "../../../tajneed.service"
import LoadingOverlay from "../../../../../core/components/LoadingOverlay.vue"
import {Tajneed} from "../../../tajneed.types";
import BasedataService from "../../../../basedata/basedata.service"
import {Getter} from "vuex-class";
import {Useraccount} from "../../../../../models/useraccount.model";
import plzandorte from '../../../../../staticdata/plzandorte.json';
import streets from '../../../../../staticdata/streets.json';

@Component({
  components: {LoadingOverlay},
})
export default class CreateNewTajneedDialog extends Vue {
  @Getter('getUseraccount', {namespace: 'useraccount'})
  private getUseraccount!: Useraccount;

  private newTajneed: Tajneed = {};

  private jamaatItems: string[] = [];

  private majlisItems: string[] = [];

  private cityItems: any[] = [];

  private cityPostalCode = "";

  private streetItems: string[] = [];

  private rules = {
    required: (value: any) => !!value || 'Pflichtfeld',
    dateFormat: (v: string) => /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)\d\d$/.test(v) || 'TT.MM.JJJJ Format',
    digitsOnly: (v: string) => /^(\s*|\d+)$/.test(v) || 'Jamaat-ID darf nur Zahlen beinhalten.',
    validWaqfenauId: (v: string) => {
      if (v) {
        return /^[0-9][0-9]?[0-9]?[0-9]?[0-9]?[A-Z][A-Z]?[0-9]?$/.test(v) || 'Keine gültige Waqf-e-Nau Nummer'
      }
      return true;
    },
  };

  private showOverlay = false;

  created() {
    BasedataService.getJamaatAndMajalisForUser()
      .then((response: any) => {
        this.jamaatItems = response.data.jamaats;
        this.majlisItems = response.data.majalis;
      })
      .catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: 'Netzwerkfehler',
          type: 'error'
        });
      })
      .finally(() => {
        setTimeout(() => {
        }, 5000);
      });

    this.loadOrtAndPlzSelectItems();
    this.loadStreets();
  }

  createNewTajneed() {
    if(!this.newTajneed.waqfenauId && !this.newTajneed.personFormularId) {
      this.$notify({
        group: 'foo',
        title: 'Info',
        text: 'Waqf-e-Nau ID oder Personal-Formular ID muss gefüllt sein',
        type: 'error'
      });
      return;
    }
    // @ts-ignore
    if(this.newTajneed.waqfenauId){
      this.newTajneed.waqfenauId = this.newTajneed.waqfenauId.replace(" ", "");
    }
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.showOverlay = true;
      TajneedService.createNewTajneed(this.newTajneed)
        .then(
          (response: any) => {
            this.showOverlay = false;
            this.$notify({
              group: 'foo',
              title: 'Info',
              text: 'Gespeichert',
              type: 'success'
            });
            this.$emit('on-create-new-success', response.data);
          })
        .catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text: error.response.data,
            type: 'error'
          });
          this.showOverlay = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.showOverlay = false;
          }, 5000);
        });
    }

  }

  private loadOrtAndPlzSelectItems() {
    //Load orte in autocomplete field:
    for (var i = 0; i < plzandorte.length; i++) {
      this.cityItems.push({
        text: plzandorte[i].plz + " " + plzandorte[i].ort,
        value: plzandorte[i].id
      });
    }
  }

  private loadStreets() {
    for (var i = 0; i < streets.length; i++) {
      this.streetItems.push(streets[i]);
    }
  }

  private handleOrtChange(selection: any) {
    this.newTajneed.postalCode = selection.text.substring(0, 5);
    this.newTajneed.city = selection.text.substring(6);
  }

}
