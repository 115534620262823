





















































































































import {Component, Vue} from "vue-property-decorator";
import LoadingOverlay from "../../../core/components/LoadingOverlay.vue"
import {Getter} from "vuex-class";
import {Useraccount} from "../../../models/useraccount.model";
import {UseraccountService} from "../../useraccount/useraccount.service";

@Component({
  components: {LoadingOverlay},
})
export default class CreateNewTajneedDialog extends Vue {
  @Getter('getUseraccount', {namespace: 'useraccount'})
  private getUseraccount!: Useraccount;

  private name: string = '';

  private aimsId: string = '';

  private phoneNumber: string = '';

  private dateOfBirth: string = '';

  private whatsAppNumber: string = '';

  private email: string = '';

  private emailConfirmation: string = '';

  private rules = {
    required: (value: any) => !!value || 'Pflichtfeld',
    digitsOnly: (v: string) => /^(\s*|\d+)$/.test(v) || 'Jamaat-ID darf nur Zahlen beinhalten.',
    dateFormat: (v: string) => /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)\d\d$/.test(v) || 'TT.MM.JJJJ Format',
    emailConfirmation: (v: string) => this.emailMatch(v) || 'Emails stimmen nicht überein',
  };

  private showOverlay = false;

  createNewTajneed() {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.showOverlay = true;
      UseraccountService.saveOfficeHolderData(
        this.name,
        this.aimsId,
        this.phoneNumber,
        this.dateOfBirth,
        this.whatsAppNumber,
        this.email
      ).then((response: any) => {
          this.showOverlay = false;
          this.$notify({
            group: 'foo',
            title: 'Info',
            text: 'Gespeichert',
            type: 'success'
          });
          this.$emit('success-save-office-holder-data', response.data);
        })
        .catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text: 'Fehler',
            type: 'error'
          });
          this.showOverlay = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.showOverlay = false;
          }, 5000);
        });
    }
  }

  private emailMatch(p: string) {
    if (this.emailConfirmation.length > 0) {
      //@ts-ignore
      return (this.email === this.emailConfirmation);
    }
    return true;
  }

}
