




























































































































































































































































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import LoadingOverlay from "../../../core/components/LoadingOverlay.vue"
import AppLoader from "../../../core/components/AppLoader.vue";
import {Getter} from "vuex-class";
import {OwnMetaData} from "../../tajneed/tajneed.types";
import {IndividualReport, IndividualReportRequest} from "../report.types";
import ReportService from "../report.service";
import moment from 'moment';
import StaticData from "../../../staticdata/staticdata"
import {AxiosResponse} from "axios";
import {Useraccount} from "../../../models/useraccount.model";

@Component({
  components: {
    AppLoader,
    LoadingOverlay
  },
})
export default class ViewReportIndividual extends Vue {
  @Getter('getUseraccount', {namespace: 'useraccount'})
  private getUseraccount!: Useraccount;

  @Getter('getOwnMetaData', {namespace: 'accountmetadata'})
  private getOwnMetaData!: OwnMetaData;

  private report: IndividualReport = {
    status: "CREATED",
    month: 0,
    year: 0,
    personId: "0",
  };

  private rules = {
    required: (value: any) => !!value || 'Pflichtfeld',
    digitsOnly: (v: string) => /^(\s*|\d+)$/.test(v) || 'Nur Zahlen erlaubt.',
    notMoreThenTwentyFour: (v: number) => v <= 24 || 'Nur maximal 24 erlaubt'
  };

  private errorMessageWhenTooManyPrayers = [];

  private allSports: string[] = StaticData.getAllSports();

  private showOverlay: boolean = false;
  private loadingReport: boolean = false;

  private finishDisabled: boolean = false;

  beforeCreate(){
    if (!this.$route.params.reportId) {
      this.$router.push({path: '/'});
    }
  }

  created() {
    if(this.$route.params.reportId) {
      this.loadingReport = true;
      //@ts-ignore
      ReportService.getIndividualReport(this.$route.params.reportId, this.$route.params.personId).then((response: AxiosResponse<IndividualReport>) => {
        this.report = response.data;
        this.loadingReport = false;
        // Avoid finishing before the 1st of the month after report.month.
        // The cases, that report is not finishable anymore after the 10th of the next month are handled by report status (EXPIRED, FINISHED)
        // which is set in backend via scheduled task
        if(moment().add(1, 'M').month() == this.report.month){
          this.finishDisabled = true;
        }
      }).catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: error,
          type: 'error'
        });
        this.loadingReport = false;
      }).finally(() => {
        setTimeout(() => {
          this.loadingReport = false;
        }, 5000);
      });
    }
  }

  private saveReport() {
    let request: IndividualReportRequest = {
      individualReport: this.report,
      type: "SAVE"
    }

    this.doSave(request);
  }

  private finishReport() {

    if (!(this.$refs.formReport as Vue & { validate: () => boolean }).validate()) {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: "Es fehlen noch Pflichtangaben.",
        type: 'error'
      });
    } else {
      let request: IndividualReportRequest = {
        individualReport: this.report,
        type: "FINISH"
      }
      this.doSave(request);
    }
  }

  private doSave(request: IndividualReportRequest) {
    ReportService.saveIndividualReport(request)
      .then((response: any) => {
        this.showOverlay = false;
        this.$notify({
          group: 'foo',
          title: 'Info',
          text: response.data,
          type: 'success'
        });
        this.$router.push({
          name: 'ViewTableIndividualReports',
        });
      })
      .catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: error.response!.data.message,
          type: 'error'
        });
        this.showOverlay = false;
      })
      .finally(() => {
        setTimeout(() => {
          this.showOverlay = false;
        }, 5000);
      });
  }

  get getFridaysOfMonth(): string[] {
    let date = moment(this.report.year.toString() + '-' + (this.report.month).toString(), 'YYYY-MM');
    if(this.getAmountOfWeekDaysInMonth(date, 5) == 4) {
      return ['0', '1', '2', '3', '4'];
    } else {
      return ['0', '1', '2', '3', '4', '5'];
    }
  }

  get getDaysOfMonthAsArray(): string[]{
    let days = moment(this.report.year.toString() + '-' + (this.report.month).toString(), 'YYYY-MM').daysInMonth();
    return [...Array((days + 1)).keys()].map(String);
  }

  get getDaysOfMonth(): number{
    return moment(this.report.year.toString() + '-' + (this.report.month).toString(), 'YYYY-MM').daysInMonth();
  }

  getAmountOfWeekDaysInMonth(date: any, weekday: number): number {
    date.date(1);
    var dif = (7 + (weekday - date.weekday())) % 7 + 1;
    return Math.floor((date.daysInMonth() - dif) / 7) + 1;
  }

  private getClass(question: number) {
    if (!this.$vuetify.breakpoint.xsOnly) {
      return 'margin-left';
    }
  }

  getMonthForNumber(month: number): string {
    switch (month) {
      case 0:
        return "Januar";
      case 1:
        return "Februar";
      case 2:
        return "März";
      case 3:
        return "April";
      case 4:
        return "Mai";
      case 5:
        return "Juni";
      case 6:
        return "Juli";
      case 7:
        return "August";
      case 8:
        return "September";
      case 9:
        return "Oktober";
      case 10:
        return "November";
      case 11:
        return "Dezember";
      default:
        return "";
    }
  }

  getTextForStatus(status: string): string {
    switch (status) {
      case "NEW" :
        return "NEU"
      case "CREATED" :
        return "ERSTELLT"
      case "SAVED" :
        return "ZWISCHENGESPEICHERT"
      case "FINISHED" :
        return "ABGESCHLOSSEN"
      case "EXPIRED" :
        return "ABGELAUFEN"
      default:
        return "";
    }
  }
}
