



































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Confirmation, Reconfirmation, Tajneed} from '../../tajneed.types';
import SignatureInput from "./dialogs/SignatureInput.vue";
import {Getter} from "vuex-class";

@Component({
  components: {SignatureInput},
})
export default class ConfirmationForm extends Vue {

  @Getter('getStudentOrGraduate', {namespace: 'tajneed'})
  private getStudentOrGraduate!: string;

  @Prop({default: null})
  tajneed: Tajneed;

  @Prop({required: false, default: false})
  deleteRequested!: boolean;

  private locTajneed: Tajneed = {};

  max = 50;

  notempty = [
    (v: string) => !!v || 'Feld muss ausgefüllt sein'
  ];

  created() {
    this.locTajneed = JSON.parse(JSON.stringify(this.tajneed));

    if(this.getStudentOrGraduate=='Bildung') {
      if(!this.locTajneed.confirmation) {
        this.locTajneed.confirmation = {};
      }
    }
    if(this.getStudentOrGraduate=='Absolvent') {
      if(!this.locTajneed.reconfirmation) {
        this.locTajneed.reconfirmation = {};
      }
    }
  }

  @Watch("getStudentOrGraduate")
  onGetterChange(value: string, oldValue: string){
    if(this.getStudentOrGraduate=='Bildung') {
      if(!this.locTajneed.confirmation) {
        this.locTajneed.confirmation = {};
      }
    }
    if(this.getStudentOrGraduate=='Absolvent') {
      if(!this.locTajneed.reconfirmation) {
        this.locTajneed.reconfirmation = {};
      }
    }
  }

  @Watch('tajneed')
  onPropertyChanged(value: string, oldValue: string) {
    this.locTajneed = JSON.parse(JSON.stringify(this.tajneed));
    if(this.getStudentOrGraduate=='Bildung') {
      if(!this.locTajneed.confirmation) {
        this.locTajneed.confirmation = {};
      }
    }
    if(this.getStudentOrGraduate=='Absolvent') {
      if(!this.locTajneed.reconfirmation) {
        this.locTajneed.reconfirmation = {};
      }
    }
  }

  private saveSignature(signature: { signature: string }, type: string) {
    //@ts-ignore
    if(this.getStudentOrGraduate=='Bildung') {
      //@ts-ignore
      this.locTajneed.confirmation.signature = signature.signature;
    }
    //@ts-ignore
    if(this.getStudentOrGraduate=='Absolvent') {
      //@ts-ignore
      this.locTajneed.reconfirmation.signature = signature.signature;
    }
  }

  get isValid(): boolean {
    /* console.log("isValid in ConfVue")
    //@ts-ignore
    if(this.getBildungBeschaeftigung=='Bildung') {
      console.log("222")
      //@ts-ignore
      return this.locTajneed.confirmation.signature ? true : false;
    }
    //@ts-ignore
    if(this.getBildungBeschaeftigung=='Absolvent') {
      console.log("333")
      //@ts-ignore
      return this.locTajneed.reconfirmation.signature ? true : false;
    } */

    if(this.getStudentOrGraduate=='Bildung') {
      const signatureConfirmation = this.$refs
        .signatureConfirmation as Vue & { validate: () => boolean };
      const signatureTechComponentValid = signatureConfirmation
        ? signatureConfirmation.validate() : true;

      return signatureTechComponentValid;
    }

    if(this.getStudentOrGraduate=='Absolvent') {
      const signatureReconfirmation = this.$refs
        .signatureReconfirmation as Vue & { validate: () => boolean };
      const signatureTechComponentValid = signatureReconfirmation
        ? signatureReconfirmation.validate() : true;

      return signatureTechComponentValid;
    }

    return false;

  }

  get getConfirmation(): Confirmation {
    //@ts-ignore
    return this.locTajneed.confirmation;
  }

  get getReconfirmation(): Reconfirmation {
    //@ts-ignore
    return this.locTajneed.reconfirmation;
  }

}
