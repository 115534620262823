





























import {Component, Prop, Vue} from "vue-property-decorator";
import BasedataService from '../basedata.service';
import LoadingOverlay from "../../../core/components/LoadingOverlay.vue"
import {JamaatHalqa, LajnaMajlis} from "../basedata.types";

@Component({
  components: {LoadingOverlay},
})
export default class ConfirmLajnaMajlisDeleteDialogVisible extends Vue {

  @Prop({ default: null, required: true })
  lajnaMajlis!: LajnaMajlis;

  private showOverlay = false;

  deleteLajnaMajlis() {
    this.showOverlay = true;
    BasedataService.deleteLajnaMajlis(this.lajnaMajlis.id)
      .then((response: any) => {
        this.showOverlay = false;
        this.$notify({
          group: 'foo',
          title: 'Info',
          text: 'Lajna Majlis erfolgreich gelöscht',
          type: 'success'
        });
        this.$emit('success');
      })
      .catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text:  error.response!.data.message,
          type: 'error'
        });
        this.showOverlay = false;
      })
      .finally(() => {
        setTimeout(() => {
          this.showOverlay = false;
        }, 5000);
      });
  }

}
