import {IndividualReportRequest, LocalReport, LocalReportJson, MissionaryReportJson, RegionalReportJson} from "@/modules/report/report.types";
import httpResource from "../../http/httpResource";

const API_URL = process.env.VUE_APP_API_URL + '/api';

class ReportService {

  getIndividualReports(personId: string) {
    return httpResource.get(API_URL+ '/individualreports/' + personId);
  }

  getIndividualReport(reportId: string, personId: string) {
    return httpResource.get(API_URL+ '/individualreport/' + reportId + "/" + personId);
  }

  getIndividualReportsForGroup() {
    return httpResource.get(API_URL+ '/individualreports');
  }

  saveIndividualReport(request: IndividualReportRequest) {
    return httpResource.post(API_URL + '/individualreports/save', request);
  }

  getLocalReports() {
    return httpResource.get(API_URL+ '/localreports');
  }

  getMissionaryReports(jamaat: string) {
    return httpResource.get(API_URL+ '/missionaryreports/' + jamaat);
  }

  getRegionalReports() {
    return httpResource.get(API_URL+ '/regionalreports');
  }

  getNationalReports(type: string) {
    return httpResource.get(API_URL+ '/nationalreports/' + type);
  }

  getLocalReportsForRegion() {
    return httpResource.get(API_URL+ '/localreports/region');
  }

  getRegionalReportsForGroup() {
    return httpResource.get(API_URL+ '/regionalreports/group');
  }

  getLocalReport(month: string, year: string, type: string, organizationName?: string) {
    if(organizationName && organizationName != '') {
      return httpResource.get(API_URL+ '/localreport/' + month + '/' + year + '/' + organizationName + '/' + type);
    } else {
      return httpResource.get(API_URL+ '/localreport/' + month + '/' + year + '/' + 'random' + '/' + type);
    }
  }

  getMissionaryReport(id: any) {
    return httpResource.get(API_URL+ '/missionaryreport/' + id);
  }

  getRegionalReport(month: string, year: string, type:string, organizationName?: string) {
    if(organizationName && organizationName != '') {
      return httpResource.get(API_URL+ '/regionalreport/' + month + '/' + year + '/' + organizationName + '/' + type);
    } else {
      return httpResource.get(API_URL+ '/regionalreport/' + month + '/' + year + '/' + 'random' + '/' + type);
    }
  }

  getNationalReport(month: string, year: string, type: string) {
    return httpResource.get(API_URL+ '/nationalreport/' + month + '/' + year + '/' + type);
  }

  saveLocalReport(report: LocalReportJson) {
    return httpResource.post(API_URL + '/localreport', report);
  }


  saveMissionaryReport(report: MissionaryReportJson) {
    return httpResource.post(API_URL + '/missionaryreport', report);
  }


  saveRegionalReport(report: RegionalReportJson) {
    return httpResource.post(API_URL + '/regionalreport', report);
  }

  printLocalReport(month: string, year: string, organizationName?: string, type?: string) {
    if(organizationName && organizationName != '') {
      return httpResource.get(API_URL+ '/localreport' + '/print/' + month + '/' + year + '/' + organizationName + '/' + type);
    } else {
      return httpResource.get(API_URL+ '/localreport' + '/print/' + month + '/' + year+ '/' + 'random'+ '/' + type);
    }
  }

  printRegionalReport(month: string, year: string, organizationName?: string, type?: string) {
    if(organizationName && organizationName != '') {
      return httpResource.get(API_URL+ '/regionalreport' + '/print/' + month + '/' + year + '/' + organizationName + '/' + type);
    } else {
      return httpResource.get(API_URL+ '/regionalreport' + '/print/' + month + '/' + year+ '/' + 'random'+ '/' + type);
    }
  }


  getIndividualDevelopment(personId: string) {
    return httpResource.get(API_URL+ '/individual/development/' + personId);
  }

  getReportForHuzoor() {
    return httpResource.get(API_URL+ '/huzoorreport');
  }
}

export default new ReportService();
