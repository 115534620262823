import httpResource from "../../http/httpResource";

const API_URL = process.env.VUE_APP_API_URL + '/api/archive/';

class ArchiveService {

  getArchive() {
    return httpResource.get(API_URL + 'getall')
  }

  reactivateTajneed(archiveId: any){
    return httpResource.put(API_URL + 'reactivate-tajneed', {archiveId: archiveId});

  }
}

export default new ArchiveService();
