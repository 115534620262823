







































































































import {Component, Vue} from "vue-property-decorator";
import AppLoader from "../../../core/components/AppLoader.vue"
import AppCard from '../../../core/components/cards/AppCard.vue';
import EmailService from "../email.service";
import {Email} from "@/modules/email/email.types";
import ReportService from "@/modules/report/report.service";
import {FileReadUtils} from "@/utils/filereadutils";

@Component({
  components: {AppCard, AppLoader}
})
export default class ViewWriteEmail extends Vue {
  private sendingEmail: boolean = false;
  private email: Email = {minimumAge: '0', maximumAge: '0', sex: 'Male', subject: '', body: ''};

  sendEmail(){
    this.sendingEmail = true;
    EmailService.sendEmail(this.email).then((response: any) => {
      this.sendingEmail = false;
    }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: error,
        type: 'error'
      });
      this.sendingEmail = false;
    }).finally(() => {
      setTimeout(() => {
        this.sendingEmail = false;
      }, 5000);
    });
  }

  sendTestEmail(){
    this.sendingEmail = true;
    EmailService.sendTestEmail(this.email).then((response: any) => {
      this.sendingEmail = false;
    }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: error,
        type: 'error'
      });
      this.sendingEmail = false;
    }).finally(() => {
      setTimeout(() => {
        this.sendingEmail = false;
      }, 5000);
    });
  }

}
