
















import {
  Component, Vue
} from 'vue-property-decorator';

import AppCard from '../../../core/components/cards/AppCard.vue';
import DashboardCol from '../../../core/components/DashboardCol.vue';
import DashboardRow from '../../../core/components/DashboardRow.vue';
import DashboardTileChild from './DashboardTileChild.vue';
import {Getter} from "vuex-class";
import {OwnMetaData} from "../../tajneed/tajneed.types";

@Component({
  components: {
    DashboardTileChild,
    AppCard,
    DashboardCol,
    DashboardRow,
  },
})
export default class DashboardRowChildren extends Vue {
  @Getter('getOwnMetaData', {namespace: 'accountmetadata'})
  private getOwnMetaData!: OwnMetaData;

}
