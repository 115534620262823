












































import {Component, Prop, Vue} from "vue-property-decorator";
import {router} from "../../../router";

@Component({
  components: {},
})
export default class RegistrationResultDialog extends Vue {

  @Prop({default: []})
  resultMessage: string[];

  @Prop({default: false})
  registrationSuccess: boolean;

  @Prop({default: false})
  registrationFailed: boolean;

  private accountCreated: boolean = false;

  created(){
    // This is important whether we need to navigate back to form or forward to login
    this.resultMessage.forEach( message => {
      if(message.indexOf("Registrierung für Account")>=0) {
        this.accountCreated = true;
      }
    });
  }

  private closeDialog() {
    this.$emit('event');
  }

  private redirectToLogin() {
    router.push({name: 'login'});
  }
}
