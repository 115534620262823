





























import {Component, Prop, Vue} from "vue-property-decorator";
import BasedataService from '../basedata.service';
import LoadingOverlay from "../../../core/components/LoadingOverlay.vue"
import {JamaatHalqa} from "../basedata.types";

@Component({
  components: {LoadingOverlay},
})
export default class ConfirmJamaatHalqaDeleteDialog extends Vue {

  @Prop({ default: null, required: true })
  jamaatHalqa!: JamaatHalqa;

  private showOverlay = false;

  deleteJamaatHalqa() {
    this.showOverlay = true;
    BasedataService.deleteJamaatHalqa(this.jamaatHalqa.id)
      .then((response: any) => {
        this.showOverlay = false;
        this.$notify({
          group: 'foo',
          title: 'Info',
          text: 'Jamaat/Halqa erfolgreich gelöscht',
          type: 'success'
        });
        this.$emit('success');
      })
      .catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text:  error.response!.data.message,
          type: 'error'
        });
        this.showOverlay = false;
      })
      .finally(() => {
        setTimeout(() => {
          this.showOverlay = false;
        }, 5000);
      });
  }

}
