import Vue from 'vue';
import Vuex from 'vuex';

import { UseraccountStore as useraccount } from './useraccount.store';
import { TajneedStore as tajneed } from './../modules/tajneed/tajneed.store';
import { ReportStore as report } from './../modules/report/report.store';
import { AccountMetaDataStore as accountmetadata } from './../modules/useraccount/accountmetadata.store';

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    useraccount,
    report,
    tajneed,
    accountmetadata
  },
});
