var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v(" Jamia Deutschland: ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.jamiaTableHeader,"items":_vm.jamiaDeutschlandTableData,"items-per-page":10,"search":_vm.search},scopedSlots:_vm._u([{key:"item.reactivate",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleReactivateArchiveEntry(item)}}},[_vm._v(" mdi-check ")])]}}])})],1),_c('br'),_c('v-card',[_c('v-card-title',[_vm._v(" Jamia UK: ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.jamiaTableHeader,"items":_vm.jamiaUKTableData,"items-per-page":10,"search":_vm.search},scopedSlots:_vm._u([{key:"item.reactivate",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleReactivateArchiveEntry(item)}}},[_vm._v(" mdi-check ")])]}}])})],1),_c('br'),_c('v-card',[_c('v-card-title',[_vm._v(" Jamia Canada: ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.jamiaTableHeader,"items":_vm.jamiaCanadaTableData,"items-per-page":10,"search":_vm.search},scopedSlots:_vm._u([{key:"item.reactivate",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleReactivateArchiveEntry(item)}}},[_vm._v(" mdi-check ")])]}}])})],1),_c('br'),_c('v-card',[_c('v-card-title',[_vm._v(" Jamia Pakistan: ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.jamiaTableHeader,"items":_vm.jamiaPakistanTableData,"items-per-page":10,"search":_vm.search},scopedSlots:_vm._u([{key:"item.reactivate",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleReactivateArchiveEntry(item)}}},[_vm._v(" mdi-check ")])]}}])})],1),_c('br'),_c('v-card',[_c('v-card-title',[_vm._v(" Jamia Ghana: ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.jamiaTableHeader,"items":_vm.jamiaGhanaTableData,"items-per-page":10,"search":_vm.search},scopedSlots:_vm._u([{key:"item.reactivate",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleReactivateArchiveEntry(item)}}},[_vm._v(" mdi-check ")])]}}])})],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.reactivateArchiveEntryDialogVisible),callback:function ($$v) {_vm.reactivateArchiveEntryDialogVisible=$$v},expression:"reactivateArchiveEntryDialogVisible"}},[(_vm.reactivateArchiveEntryDialogVisible)?_c('ReactivateArchiveEntryDialog',{attrs:{"selectedArchive":_vm.selectedArchiveForReactivate},on:{"event":_vm.handleCloseReactivateArchiveEntryDialog}}):_vm._e()],1),(_vm.showOverlay)?_c('LoadingOverlay'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }