import {JaizaRequest} from "@/modules/jaiza/jaiza.types";
import httpResource from "../../http/httpResource";
import {AxiosResponse} from "axios";

const API_URL = process.env.VUE_APP_API_URL + '/api';

class JaizaService {

  getJaiza(personId: string, year: string) {
    return httpResource.get(API_URL+ '/jaiza/' + personId + '/' + year);
  }

  getJaizas(personId: string) {
    return httpResource.get(API_URL+ '/jaizas/' + personId);
  }

  saveJaiza(request: JaizaRequest) {
    return httpResource.post(API_URL+ '/jaizas/save', request);
  }

  finishJaiza(request: JaizaRequest) {
    return httpResource.post(API_URL+ '/jaizas/finish', request);
  }

  getJaizasForGroup(params: any) {
    return httpResource.post(API_URL+ '/jaizas' , params);
  }

  createJudge() {
    return httpResource.get(API_URL+ '/jaizas/judge');
  }

  resetJudgePassword() {
    return httpResource.get(API_URL+ '/jaizas/judge/password/reset');
  }

  loadJudges() {
    return httpResource.get(API_URL+ '/jaizas/judges/region');
  }

  createJudgeForJamaatMajlis(jamaatMajlis: string) {
    return httpResource.get(API_URL+ '/jaizas/judge/' + jamaatMajlis);
  }

  resetJudgePasswordForJamaatMajlis(jamaatMajlis: string) {
    return httpResource.get(API_URL+ '/jaizas/judge/password/reset/' + jamaatMajlis);
  }

  getResultPdf(personId: string, year: string) : Promise<AxiosResponse> {
    return httpResource.get(API_URL+ '/jaiza/result/' + personId + '/' + year );
  }

  restoreJaiza(personId: string, year: string) : Promise<AxiosResponse> {
    return httpResource.get(API_URL+ '/jaiza/restore/' + personId + '/' + year );
  }

  getAllResultPdf() : Promise<AxiosResponse> {
    return httpResource.get(API_URL+ '/jaiza/results/all');
  }

}

export default new JaizaService();
