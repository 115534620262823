import httpResource from "../../http/httpResource";
import {JamaatHalqa, LajnaMajlis, LajnaRegion, RegionLocalAmarat} from "@/modules/basedata/basedata.types";

const API_URL = process.env.VUE_APP_API_URL + '/api/analysis/';

class AnalysisService {

  getJamaatsCoverage() {
    return httpResource.get(API_URL + 'coverage/jamaats');
  }

  getJaizaCoverage() {
    return httpResource.get(API_URL + 'coverage/jaiza');
  }
}

export default new AnalysisService();
