


























import moment from 'moment';
import {Component, Prop, Vue} from 'vue-property-decorator';

import AppCard from '../../../core/components/cards/AppCard.vue';
import DashboardCol from '../../../core/components/DashboardCol.vue';
import DashboardRow from '../../../core/components/DashboardRow.vue';
import DashboardTileTajneed from './DashboardTileTajneed.vue';
import DashboardTileWorkingGroup from './DashboardTileWorkingGroup.vue';
import DashboardTileDiary from "./DashboardTileDiary.vue";
import DashboardTileDevelopment from "./DashboardTileDevelopment.vue";
import {Getter} from "vuex-class";
import {Useraccount} from "../../../models/useraccount.model";

@Component({
  components: {
    DashboardTileDevelopment,
    DashboardTileDiary,
    DashboardTileTajneed,
    DashboardTileWorkingGroup,
    AppCard,
    DashboardCol,
    DashboardRow,
  },
})
export default class DashboardMenuFirstRow extends Vue {
  @Getter('getUseraccount', {namespace: 'useraccount'})
  private getUseraccount!: Useraccount;

  @Prop({default: "-1", required: false})
  tajneedId!: string;

  @Prop({default: "", required: false})
  isChild!: string;

  @Prop({default: "", required: false})
  isOwnTajneed!: string;

  @Prop({default: "", required: false})
  dateOfBirth!: string;

  @Prop({default: "", required: true})
  personId!: string;

  private isYoungerThenSeven: boolean = false;

  private disableDiaryTile: boolean = false;

  created() {
    this.isYoungerThenSeven = moment().diff(this.dateOfBirth, 'years') < 7 ? true : false;
    if (this.getUseraccount.getRoles.indexOf("ROLE_NORMAL_MEMBER") < 0) {
      this.disableDiaryTile = true;
    }
  }

}
