












import {Component, Prop, Vue} from 'vue-property-decorator';
import AppCardButton from '../../../core/components/cards/AppCardButton.vue';


@Component({
  components: {
    AppCardButton,
  },
})
export default class DashboardTileJaiza extends Vue {
  @Prop({ default: "", required: false })
  personId!: string;

  @Prop({ default: "", required: false })
  dateOfBirth!: string;

}

