































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import AppCard from '../../../core/components/cards/AppCard.vue';
import ExpansionPanelChangePassword from '../components/ExpansionPanelChangePassword.vue';
import {Useraccount} from "../../../models/useraccount.model";
import ExpansionPanelDeleteTajneed from "../components/ExpansionPanelDeleteTajneed.vue";
import ExpansionPanelChangeUsername from "../components/ExpansionPanelChangeUsername.vue";

@Component({
  components: {
    ExpansionPanelDeleteTajneed,
    ExpansionPanelChangePassword,
    ExpansionPanelChangeUsername,
    AppCard,
  },
})
export default class ViewSettings extends Vue {
  @Getter('getUseraccount', { namespace: 'useraccount' })
  private getUseraccount!: Useraccount;

  private panelValue: number | null = null;
}
