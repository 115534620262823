import httpResource from "../../http/httpResource";

const API_URL = process.env.VUE_APP_API_URL + '/api/tajneed/';

class TajneedService {

  getTajneed(
    firstname: string,
    lastname: string,
    aimsId: string,
    waqfenauId: string,
    pfId: string,
    jamaat: string,
    regionLa: string,
    lajnaRegion: string,
    lajnaMajlis: string,
    schoolType: string,
    studentOrGraduate: string,
    courseOfStudy: string[],
    trainingType: string[],
    mainFocus: string,
    subjectArea: string,
    ageComparator: string,
    age: string,
    zusage: string
  ) {
    return httpResource.post(API_URL + 'gettajneed', {
      firstname: firstname,
      lastname: lastname,
      aimsId: aimsId,
      waqfenauId: waqfenauId,
      pfId: pfId,
      jamaatString: jamaat,
      regionLaString: regionLa,
      lajnaRegionString: lajnaRegion,
      lajnaMajlisString: lajnaMajlis,
      schoolType: schoolType,
      studentOrGraduate: studentOrGraduate,
      courseOfStudy: courseOfStudy,
      trainingType: trainingType,
      mainFocus: mainFocus,
      subjectArea: subjectArea,
      ageComparator: ageComparator,
      age: age,
      zusage: zusage
    });
  }

  getTajneedFirstConfirmationMissing(
  ) {
    return httpResource.get(API_URL + 'firstconfirmationmissing', {
    });
  }

  getTajneedSecondConfirmationMissing(
    ) {
      return httpResource.get(API_URL + 'secondconfirmationmissing', {
      });
    }

  /* createNewTajneedEntry(tajneed: any) {
    return httpResource.post(API_URL + 'newtajneedentry', {newTajneed: tajneed});
  }*/

  updateTajneed(tajneed: any) {
    return httpResource.post(API_URL + 'updatetajneedentry', tajneed);
  }

  deleteTajneed(data: any) {
    return httpResource.post(API_URL + 'requesttajneeddelete', data);
  }

  addCommentToTajneed(tajneedId: number, data: string) {
    return httpResource.post(API_URL + 'commenttajneed/' + tajneedId, data);
  }

  getTajneedCommentHistory(tajneedId: number) {
    return httpResource.get(API_URL + 'commenthistory/' + tajneedId);
  }

  getTajneedDetailsIndividual(tajneedId: number | undefined) {
    return httpResource.post(API_URL + 'gettajneeddetailsindividual', {tajneedId: tajneedId});
  }

  getChangeHistory(tajneedId: number | undefined) {
    return httpResource.post(API_URL + 'getchangehistory', {tajneedId: tajneedId});
  }

  updateCoreTajneedData(tajneed: any) {
    return httpResource.post(API_URL + 'updatecoretajneeddata', tajneed);
  }

  createNewTajneed(tajneed: any) {
    return httpResource.post(API_URL + 'create', tajneed);
  }

  findTajneedMatch(aimsId: string, waqfenauId: string, dateOfBirth: string) {
    return httpResource.get(API_URL + 'findtajneedmatch?aimsId=' + aimsId + '&waqfenauId=' + waqfenauId + "&dateOfBirth=" + dateOfBirth);
  }

  reactivateTajneed(tajneedId: string) {
    return httpResource.put(API_URL + 'reactivate-tajneed/' + tajneedId);
  }

}

export default new TajneedService();
