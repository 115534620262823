













import {Component, Vue} from 'vue-property-decorator';
import AppCardButton from '../../../core/components/cards/AppCardButton.vue';
import {Getter} from "vuex-class";
import {OwnMetaData} from '../../tajneed/tajneed.types';


@Component({
  components: {
    AppCardButton,
  },
})
export default class DashboardTileOwnData extends Vue {

  @Getter('getOwnMetaData', {namespace: 'accountmetadata'})
  private getOwnMetaData!: OwnMetaData;

}

