











































import {Component, Prop, Vue} from "vue-property-decorator";
import {UseraccountService} from "../../../../useraccount/useraccount.service"
import LoadingOverlay from "../../../../../core/components/LoadingOverlay.vue"
import {ServerResponse} from '../../../../../core/core.types';
import {AxiosError, AxiosResponse} from "axios";

@Component({
  components: {LoadingOverlay},
})
export default class EnterEmailOfChildOlderThenSixteen extends Vue {

  @Prop({default: ''})
  personId: string;

  @Prop({default: ""})
  childName: string;

  private showOverlay = false;

  private email: string = "";

  private emailRules = [
    (v: string) => !!v || 'Feld muss ausgefüllt sein',
    (v: any) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-Mail überprüfen',
  ]

  notempty = [
    (v: string) => !!v || 'Feld muss ausgefüllt sein'
  ];

  private complianceText= "Ich willige freiwillig und ausdrücklich ein, " +
    "dass die AMJ meine personenbezogenen Daten (bspw. Name, Adresse, Kontaktdaten, Geschlecht, " +
    "Waqf-e-Nau-, Mitglieds- & ID-Nummer, ggf. Wassiyat Nr., Religionsdaten, Nationalität, " +
    "Gesundheitsdaten, Bildungsdaten, ggf. Photographie) zum Zwecke der Verwaltung " +
    "und Organisation der besonderen 'Waqf-e-Nau' Mitgliedschaft unter Beachtung der " +
    "EU - DSGVO und BDSG verarbeitet. Empfänger dieser Daten sind im Rahmen gesetzlicher " +
    "Pflichten und Mitteilungsbefugnisse Dritte, wie die Sadr Anjuman Ahmadiyya (Rabwah, Pakistan) " +
    "und AMJ International (London, UK). ";

  created() {

  }

  createAccountForChild() {
    let data = {
      //@ts-ignore
      selectedPersonId: +this.personId,
      email: this.email
    };

    this.showOverlay = true;
    UseraccountService.createAccountForChild(data)
      .then((response: AxiosResponse<ServerResponse>) => {
        this.showOverlay = false;
        this.$notify({
          //@ts-ignore
          group: 'foo', title: 'Info', text: response.data.message, type: 'success'
        });
        this.closeModal();
      })
      .catch((error: AxiosError<ServerResponse>) => {
        //@ts-ignore
        this.$notify({group: 'foo', title: 'Fehler', text: error.response!.data.message, type: 'error'});
        this.showOverlay = false;
      })
      .finally(() => {
        setTimeout(() => {
          this.showOverlay = false;
        }, 5000);
      });
  }

  private closeModal(): void {
    this.$emit('modal-closed');
  }
}
