























































































































































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import ParentsCouncellingService from '../parents-councelling.service';
import ParentsCounsellingAttachmentService from '../parents-councelling-attachment.service';
import LoadingOverlay from "../../../core/components/LoadingOverlay.vue"
import {ParentsCounsellingCase} from "../parents-councelling.types";
import BasedataService from "../../basedata/basedata.service";
import {FileReadUtils} from "../../../utils/filereadutils";
import streets from '../../../staticdata/streets.json';
import plzandorte from '../../../staticdata/plzandorte.json';

@Component({
  components: {LoadingOverlay},
})
export default class CreateNewCaseEntryDialog extends Vue {

  @Prop({default: null})
  selectedCase: ParentsCounsellingCase;

  @Prop({default: false})
  addingToTajneed: boolean;

  private showOverlay = false;
  private newJamaatHalqaName: string = '';
  private jamaatItems: string[] = [];
  private majlisItems: string[] = [];
  private regionLa: string = '';
  private valid: boolean = true;
  private selectedJamaats: string[] = [];
  private caseAttachmentInput: any[] = [];
  private caseAttachmentDocuments: any[] = [];
  private sexUnknown: boolean = false;
  private streetItems: string[] = [];
  private cityItems: any[] = [];
  private cityPostalCode = "";


  private rules = {
    required: (value: any) => !!value || 'Pflichtfeld',
    dateFormat: (v: string) => /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)\d\d$/.test(v) || 'TT.MM.JJJJ Format',
    digitsOnly: (v: string) => /^(\s*|\d+)$/.test(v) || 'Jamaat-ID darf nur Zahlen beinhalten.',
  };

  private notemptyAndNoSpecialChar = [
    (v: string) => !!v || 'Feld muss ausgefüllt sein',
    (v: string) => /^[0-9][0-9]?[0-9]?[0-9]?[0-9]?[A-Z]$/.test(v) || 'Keine gültige Waqf-e-Nau Nummer',
  ];


  private newCase: ParentsCounsellingCase = {};

  @Watch('newCase.sex')
  onPropertyChange(value: any, oldValue: any) {
    if (!value) {
      this.newCase.jamaatHalqa = 'Unbekannt';
      this.sexUnknown = true;
    } else {
      this.sexUnknown = false;
    }
  }

  created() {
    this.loadJamaats();
    this.loadStreets();
    this.loadOrtAndPlzSelectItems();
    this.updateOnCaseChange();
  }

  private updateOnCaseChange() {
    if (this.selectedCase) {
      for (var i = 0; i < this.cityItems.length; i++) {
        if (this.cityItems[i].text.indexOf(this.selectedCase.postalCode) >= 0 && this.cityItems[i].text.indexOf(this.selectedCase.city) >= 0) {
          this.cityPostalCode = this.cityItems[i];
        }
      }
    }
  }

  private loadStreets() {
    for (var i = 0; i < streets.length; i++) {
      this.streetItems.push(streets[i]);
    }
  }

  handleOrtChange(selection: any) {
    this.newCase.postalCode = selection.text.substring(0, 5);
    this.newCase.city = selection.text.substring(6);
  }

  private loadOrtAndPlzSelectItems() {
    //Load orte in autocomplete field:
    for (var i = 0; i < plzandorte.length; i++) {
      this.cityItems.push({
        text: plzandorte[i].plz + " " + plzandorte[i].ort,
        value: plzandorte[i].id
      });
    }
  }

  private loadJamaats() {
    if (this.selectedCase) {
      ParentsCounsellingAttachmentService.getMetaForAttachments(this.selectedCase.id).then((response: any) => {
        //@ts-ignore
        this.caseAttachmentDocuments = response.data;
      })
      .catch((error: any) => {
          this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: error,
          type: 'error'
        });
      })
      .finally(() => {
        setTimeout(() => {
        }, 5000);
      });
    } else {
      this.newCase.jamaatHalqa = 'Unbekannt';
      this.sexUnknown = true;
    }

    BasedataService.getJamaatAndMajalisForUser().then((response: any) => {
      this.jamaatItems = response.data.jamaats;
      this.majlisItems = response.data.majalis;
      if (this.selectedCase) {
        this.newCase = JSON.parse(JSON.stringify(this.selectedCase));
      }
    }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: error,
        type: 'error'
      });
    }).finally(() => {
      setTimeout(() => {
      }, 5000);
    });
  }

  async createNewCaseEntry() {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.showOverlay = true;
      if (this.selectedCase) {
        if (this.addingToTajneed) {
          if (!this.selectedCase.sex) {
            this.$notify({
              group: 'foo',
              title: 'Fehler',
              text:  'Bitte Geschlecht eintragen',
              type: 'error'
            });
            this.showOverlay = false;
            return;
          }
          ParentsCouncellingService.addCaseToTajneed(this.selectedCase)
          .then((response: any) => {
            this.showOverlay = false;
            this.$notify({
              group: 'foo',
              title: 'Info',
              text: 'Tajneed wurde erfolgreich erstellt',
              type: 'success'
            });
            this.$emit('success');
          })
          .catch((error: any) => {
            this.$notify({
              group: 'foo',
              title: 'Fehler',
              text:  error.response!.data,
              type: 'error'
            });
            this.showOverlay = false;
          })
          .finally(() => {
            setTimeout(() => {
              this.showOverlay = false;
            }, 5000);
          });
        } else {
          let caseAttachments = [];
          //Handle documents added in the file input field:
          if (this.caseAttachmentInput != null) {
            for (var i = 0; i < this.caseAttachmentInput.length; i++) {
              let binaryString = await FileReadUtils.readFileAsync(this.caseAttachmentInput[i]);
              //@ts-ignore
              let encodedBinaryString = window.btoa(binaryString.toString());

              let caseAttachment = {
                data: encodedBinaryString,
                fileName: this.caseAttachmentInput[i].name,
                fileType: this.caseAttachmentInput[i].type
              };

              caseAttachments.push(caseAttachment);
            }
          }
          //@ts-ignore
          this.newCase.counsellingCaseAttachments = caseAttachments;

          ParentsCouncellingService.updateCaseEntry(this.newCase)
          .then((response: any) => {
            this.showOverlay = false;
            this.$notify({
              group: 'foo',
              title: 'Info',
              text: 'Eintrag wurde erfolgreich bearbeitet',
              type: 'success'
            });
            this.$emit('success');
          })
          .catch((error: any) => {
            this.$notify({
              group: 'foo',
              title: 'Fehler',
              text:  error.response!.data.message,
              type: 'error'
            });
            this.showOverlay = false;
          })
          .finally(() => {
            setTimeout(() => {
              this.showOverlay = false;
            }, 5000);
          });
        }
      } else {
       let caseAttachments = [];
        //Handle documents added in the file input field:
        if (this.caseAttachmentInput != null) {
          for (var i = 0; i < this.caseAttachmentInput.length; i++) {
            let binaryString = await FileReadUtils.readFileAsync(this.caseAttachmentInput[i]);
            //@ts-ignore
            let encodedBinaryString = window.btoa(binaryString.toString());

            let caseAttachment = {
              data: encodedBinaryString,
              fileName: this.caseAttachmentInput[i].name,
              fileType: this.caseAttachmentInput[i].type
            };

            caseAttachments.push(caseAttachment);
          }
        }

        //@ts-ignore
       this.newCase.counsellingCaseAttachments = caseAttachments;

        ParentsCouncellingService.createNewCaseEntry(this.newCase)
        .then((response: any) => {
          this.showOverlay = false;
          this.$notify({
            group: 'foo',
            title: 'Info',
            text: 'Eintrag wurde erfolgreich angelegt',
            type: 'success'
          });
          this.$emit('success');
        })
        .catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text:  error.response!.data.message,
            type: 'error'
          });
          this.showOverlay = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.showOverlay = false;
          }, 5000);
        });
      }
    }
  }

  private downloadAttachment(id: any) {
    ParentsCounsellingAttachmentService.downloadAttachment(id).then((response: any) => {
      FileReadUtils.openSaveAs(response.data.data, "dadwawd");
    });
  }


}
