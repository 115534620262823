import Vue from 'vue';
import {
  Action,
  Module, Mutation, VuexModule,
} from 'vuex-module-decorators';

import {store} from "@/store";
import {OwnMetaData} from "@/modules/tajneed/tajneed.types";


@Module({
  namespaced: true,
})
export class AccountMetaDataStore extends VuexModule {

  private fetchingMetaData: boolean = false;

  private ownMetaData: OwnMetaData = {ownMetaData: {}, childrenMetaData: []};


  get getOwnMetaData(): OwnMetaData {
    return this.ownMetaData;
  }

  @Mutation
  private SET_OWN_METADATA(ownMetaData: OwnMetaData): void {
    this.ownMetaData = ownMetaData;
  }
}
