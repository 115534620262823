import AuthService from '../services/auth.service';
import {Useraccount} from "../models/useraccount.model";
import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";

@Module({
  namespaced: true,
})
export class UseraccountStore extends VuexModule {

  /**
   * Useraccount data.
   */
  private useraccountData: UseraccountData = {
    id: 0,
    username: '',
    jamaat: '',
    majlis: '',
    name: '',
    roles: [],
    officeHolderDataReupdated: false,
    murabbiJamaats: []
  };

  private isAuthenticated: boolean =  false;

  private intervalName: string =  "";


  get getId(): number {
    return this.useraccountData.id;
  }

  get getUsername(): string {
    return this.useraccountData.username;
  }

  get getJamaat(): string {
    return this.useraccountData.jamaat;
  }

  get getMajlis(): string {
    return this.useraccountData.majlis;
  }

  get getName(): string {
    return this.useraccountData.name;
  }

  get getOfficeHolderDataReupdated(): boolean {
    return this.useraccountData.officeHolderDataReupdated;
  }

  get getRoles(): string[] {
    return this.useraccountData.roles;
  }

  get getMurabbiJamaats(): string[] {
    return this.useraccountData.murabbiJamaats;
  }

  get getIsAuthenticated() {
    return this.isAuthenticated;
  }

  get getIntervalName() {
    return this.intervalName;
  }

  @Mutation
  private SET_USERACCOUNTDATA(useraccountData: UseraccountData) {
    this.useraccountData = useraccountData;
  }

  @Mutation
  private SET_IS_AUTHENTICATED(isAuthenticated: boolean) {
    this.isAuthenticated = isAuthenticated;
  }

  @Mutation
  private SET_INTERVAL_NAME(intervalName: string) {
    this.intervalName = intervalName;
  }

  /**
   * Useraccount instance.
   */
  get getUseraccount(): Useraccount {
    return new Useraccount(
      this.getId,
      this.getUsername,
      this.getJamaat,
      this.getMajlis,
      this.getName,
      this.getRoles,
      this.getOfficeHolderDataReupdated,
      this.getMurabbiJamaats
    );
  }

  @Mutation
  LOGOUT() {
    this.useraccountData = {
      id: 0,
      username: '',
      jamaat: '',
      majlis: '',
      name: '',
      roles: [],
      officeHolderDataReupdated: false,
      murabbiJamaats: []
    };
  }

};

export interface UseraccountData {
  id: number;
  username: string;
  jamaat: string;
  majlis: string;
  name: string;
  roles: string[];
  officeHolderDataReupdated: boolean;
  murabbiJamaats: string[]
}

