




















































import {Component, Prop, Vue} from "vue-property-decorator";
import BasedataService from '../basedata.service';
import LoadingOverlay from "../../../core/components/LoadingOverlay.vue"
import {JamaatHalqa, LajnaRegion, RegionLocalAmarat} from "../basedata.types";

@Component({
  components: {LoadingOverlay},
})
export default class CreateNewJamaatHalqaDialog extends Vue {

  private showOverlay = false;

  private newLajnaMajlisName: string = '';

  private lajnaRegionItems: string[] = [];

  private jamaatItems: string[] = [];

  private lajnaRegion: string = '';

  private jamaat: string = '';

  private valid: boolean = true;

  notempty = [
    (v: string) => !!v || 'Feld muss ausgefüllt sein'
  ];

  created() {
    this.loadRegions();
  }

  private loadRegions() {
    BasedataService.getJamaatsLajnaMajalisandLajnaRegions().then((response: any) => {
      this.lajnaRegionItems = response.data.regions.map((a: LajnaRegion) => a.name);
      this.jamaatItems = response.data.jamaats.map((a: JamaatHalqa) => a.name);
    }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: error,
        type: 'error'
      });
    }).finally(() => {
      setTimeout(() => {
      }, 5000);
    });
  }

  createNewLajnaMajlis() {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.showOverlay = true;
      BasedataService.createNewLajnaMajlis(this.newLajnaMajlisName, this.lajnaRegion, this.jamaat)
        .then((response: any) => {
          this.showOverlay = false;
          this.$notify({
            group: 'foo',
            title: 'Info',
            text: 'Lajna Majlis wurde erfolgreich angelegt',
            type: 'success'
          });
          this.$emit('success');
        })
        .catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text:  error.response!.data.message,
            type: 'error'
          });
          this.showOverlay = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.showOverlay = false;
          }, 5000);
        });
    }
  }

}
