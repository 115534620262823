





























































































































































































































































































import moment from 'moment';
import {Component, Prop, Ref, Watch} from "vue-property-decorator";
import TajneedService from "../../tajneed.service";
import ApprovalService from "../../approval.service";

import DeleteTajneedEntryDialog from '../../components/tajneed/dialogs/DeleteTajneedEntryDialog.vue';
import {
  AdditionalInformation,
  AdditionalQualification,
  Confirmation,
  Education,
  PersonImage,
  Reconfirmation,
  ResponseTajneedDataIndividualJson,
  Tajneed, TajneedChangeRecord
} from '../../tajneed.types';
import LoadingOverlay from "../../../../core/components/LoadingOverlay.vue"
import EducationForm from "../../components/tajneed/EducationForm.vue";
import BasisdatenForm from "../../components/tajneed/BasisdatenForm.vue";
import {Getter} from 'vuex-class';
import {Useraccount} from "../../../../models/useraccount.model";
import MixinAccountMetaDataFetch from "../../../useraccount/components/MixinAccountMetaDataFetch";
import AppLoader from "../../../../core/components/AppLoader.vue";
import RemoveAccountTajneedDialog from "../../components/tajneed/dialogs/RemoveAccountTajneedDialog.vue";
import {AxiosResponse} from "axios";
import AdditionalInformationForm from "../../components/tajneed/AdditionalInformationForm.vue";
import ConfirmationForm from "../../components/tajneed/ConfirmationForm.vue";
import {FileReadUtils} from "../../../../utils/filereadutils";
import ChangeHistoryDialog from "../../components/approvals/dialogs/ChangeHistoryDialog.vue";

@Component({
  components: {
    ChangeHistoryDialog,
    ConfirmationForm,
    AdditionalInformationForm,
    RemoveAccountTajneedDialog,
    AppLoader,
    //BasicSkillsForm,
    BasisdatenForm,
    EducationForm,
    DeleteTajneedEntryDialog, LoadingOverlay
  },
})
export default class TajneedForm extends MixinAccountMetaDataFetch {

  @Getter('getUseraccount', {namespace: 'useraccount'})
  private getUseraccount!: Useraccount;

  @Prop({default: null})
  source: string;

  private tajneedId: any;

  private isOwnTajneed: boolean = false

  @Ref() readonly educationForm!: EducationForm;

  @Ref() readonly basisdatenForm!: BasisdatenForm;

  @Ref() readonly additionalInformationForm!: AdditionalInformationForm;

  @Ref() readonly confirmationForm!: ConfirmationForm;


  //@Ref() readonly basicSkillsForm!: BasicSkillsForm;

  private tajneed: Tajneed = {};
  private showOverlay = false;
  private loadingTajneed = true;

  private editingTajneed = false;

  private approvingNewTajneed = false;

  private deleteTajneedDialogVisible = false;
  //@ts-ignore
  private selectedTajneedForDelete: Tajneed = null;

  private showEducationPage = false;

  private showAddInfoAndConfirmPage = false;

  //private locShowOtherPages = false;

  private isReadOnlyUser = false;

  private isIndividualAccount = false;

  private isAdministrativeUser = false;

  private e1 = 1;

  private deleteRequested = false;

  private allAdditionalQualifications: AdditionalQualification[] = [];

  private changeHistoryDialogVisible: boolean = false;

  // private isFetchingTajneed = true;

  created() {
    if (this.$route.params.tajneedId) {
      this.editingTajneed = true;
      this.tajneedId = this.$route.params.tajneedId;
    }

    if (this.getUseraccount.isNormalUser) {
      this.isIndividualAccount = true;
    }

    if (this.getUseraccount.isNaibAmirUser) {
      this.isReadOnlyUser = true;
    }

    if (this.getUseraccount.isAdministrativeUser) {
      this.isAdministrativeUser = true;
    }

    //We only give source, when coming from Approval View
    if (this.$route.params.source) {
      this.approvingNewTajneed = true;
      this.editingTajneed = false;
    }

    if (this.$route.params.isOwnTajneed) {
      this.isOwnTajneed = true;
    }

    this.initialLoading();
  }

  private initialLoading() {
    let loadTajneedDetails = true;

    if (!this.$route.params.tajneedId) {
      if (this.isIndividualAccount) {
        // We have to set this to false,
        // otherwise it strangely executes the lines after
        loadTajneedDetails = false;
        this.$router.push({name: 'ViewDashboardPersons'});
      }
      if (this.isAdministrativeUser) {
        loadTajneedDetails = false;
        // We really need both!
        //this.isFetchingTajneed = false;
        this.loadingTajneed = false;
      }
    }

    if (loadTajneedDetails) {
      //this.showOverlay = true;
      let callback = null;
      if (this.editingTajneed || this.approvingNewTajneed) {
        callback = TajneedService.getTajneedDetailsIndividual(+this.tajneedId);
      }
      //@ts-ignore
      callback.then((response: AxiosResponse<ResponseTajneedDataIndividualJson>) => {
        this.setFormTajneedObject(JSON.parse(JSON.stringify(response.data.tajneed)));
        this.allAdditionalQualifications = response.data.allAdditionalQualifications;
        //this.isFetchingTajneed = false;
        //@ts-ignore
        this.deleteRequested = this.tajneed.status.indexOf('REQUESTED') >= 0;
        this.loadingTajneed = false;
      })
        .catch((error: any) => {
          this.$notify({
            group: 'foo', title: 'Fehler', text: error, type: 'error'
          });
          //this.showOverlay = false;
        })
        .finally(() => {
          setTimeout(() => {
            //this.showOverlay = false;
          }, 5000);
        });
    }

  }

  private validate(form: number) {
    if (form == 1) {
      if (this.basisdatenForm.isValid) {
        this.e1 = 2;
      }
    }
    if (form == 2) {
      if (this.educationForm.isValid) {
        this.e1 = 3;
      }
    }

    if (form == 3) {
      if (this.additionalInformationForm.isValid) {
        this.e1 = 4;
      }
    }
  }

  goBackTo(form: number) {
    this.e1 = form;
  }

  handleDelete() {
    // We need to assign complete tajneed object, because in dialog we need to know geschlecht
    this.selectedTajneedForDelete = this.tajneed;
    this.deleteTajneedDialogVisible = true;
  }

  createOrUpdateTajneed() {
    let valid = false;
    if (!this.showEducationPage && (this.basisdatenForm.isValid)) {
      valid = true;
    } else if (this.showEducationPage && (this.educationForm.isValid) && !this.showAddInfoAndConfirmPage) {
      valid = true;
    } else if (this.showAddInfoAndConfirmPage && (this.confirmationForm.isValid)) {
      valid = true;
    }

    if (valid) {
      this.showOverlay = true;
      this.tajneed = this.getBasisdaten;

      this.tajneed.dateOfBirth = this.getDateOfBirth;

      let responseCallback = null;

      this.tajneed.personImage = this.getFileData;

      if (this.showEducationPage) {
        this.tajneed.education = this.getEducation;
      }

      if (this.showAddInfoAndConfirmPage) {
        this.tajneed.additionalInformation = this.getAdditionalInformation;
        //@ts-ignore
        if (this.tajneed.education.studentOrGraduate == 'Bildung') {
          this.tajneed.confirmation = this.getConfirmation;
        }
        //@ts-ignore
        if (this.tajneed.education.studentOrGraduate == 'Absolvent') {
          this.tajneed.reconfirmation = this.getReconfirmation;
        }
      }

      /* //Coming from edit?
      if (this.editingTajneed) {
        responseCallback = TajneedService.updateTajneed(this.tajneed)
      } else {
        //create new tajneed
        //responseCallback = TajneedService.createNewTajneedEntry(this.tajneed)
      } */

      responseCallback = TajneedService.updateTajneed(this.tajneed);

      responseCallback.then((response: any) => {
        this.showOverlay = false;
        this.$notify({group: 'foo', title: 'Info', text: response.data.responseMessage, type: 'success'});

        if (response.data.confirmationPdf) {
          // FileReadUtils.openSaveAs(response.data.confirmationPdf);
        }

        // Remeber which tajneed was shown before saving so that one is shown again after
        let currentTajneedId = this.tajneed.id;
        this.initialLoading();
        if (this.getUseraccount.isNormalUser) {
          this.fetchAccountMetaData(this.getUseraccount.getUsername);
        }
      })
        .catch((error: any) => {
          console.log(error);
          this.$notify({group: 'foo', title: 'Fehler', text: error.response.data.message, type: 'error'});
          this.showOverlay = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.showOverlay = false;
          }, 5000);
        });

    } else {
      //Form is not valid
      let errortext = 'Es fehlen noch einige Pflichtangaben.';

      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: errortext,
        type: 'error'
      });
    }
  }

  /*private onlyUnique(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  }*/

  approveNewTajneed() {
    this.showOverlay = true;
    ApprovalService.approveNewTajneed(this.tajneedId)
      .then((response: any) => {
        this.showOverlay = false;
        this.$notify(
          {
            group: 'foo', title: 'Info', text: response.data, type: 'success'
          });
        this.initialLoading();
      })
      .catch((error: any) => {
        this.$notify({
          group: 'foo', title: 'Fehler', text: error, type: 'error'
        });
        this.showOverlay = false;
      })
      .finally(() => {
        setTimeout(() => {
          this.showOverlay = false;
        }, 5000);
      });
  }

  get getFileData(): PersonImage {
    //@ts-ignore
    return this.basisdatenForm.getFileData;
  }

  get getBasisdaten(): Tajneed {
    //@ts-ignore
    return this.basisdatenForm.getBasisdaten;
  }

  get getEducation(): Education {
    //@ts-ignore
    return this.educationForm.getEducation;
  }

  get getAdditionalInformation(): AdditionalInformation {
    //@ts-ignore
    return this.additionalInformationForm.getAdditionalInformation;
  }

  get getConfirmation(): Confirmation {
    //@ts-ignore
    return this.confirmationForm.getConfirmation;
  }

  get getReconfirmation(): Reconfirmation {
    // Yes here we have to do this.confirmationForm
    //@ts-ignore
    return this.confirmationForm.getReconfirmation;
  }

  get getDateOfBirth(): string {
    //@ts-ignore
    return this.basisdatenForm.getDateOfBirth;
  }

  @Watch('showEducationPage')
  onShowEducationPageChange(value: boolean, oldValue: boolean) {
    //this.setOtherPagesObject();
  }

  @Watch('showAddInfoAndConfirmPage')
  onShowAddInfoAndConfirmPage(value: boolean, oldValue: boolean) {
    //this.setOtherPagesObject();
  }

  private setFormTajneedObject(tajneed: Tajneed) {
    this.e1 = 1;
    this.tajneed = tajneed;
    //this.setOtherPagesObject();
  }

  private showChangeHistoryDialog() {
    this.changeHistoryDialogVisible = true;
  }

  /* private setOtherPagesObject() {
    if (this.showEducationPage) {
      if (!this.tajneed.education) {
        this.tajneed.education = {};
      }
    }

    if (this.showAddInfoAndConfirmPage) {

      if (!this.tajneed.additionalInformation) {
        this.tajneed.additionalInformation = {};
      }

      if(this.tajneed.education.studentOrGraduate=='Bildung'){
        if (!this.tajneed.confirmation) {
          this.tajneed.confirmation = {};
        }
      }

      if(this.tajneed.education.studentOrGraduate=='Absolvent'){
        if (!this.tajneed.confirmation) {
          this.tajneed.confirmation = {};
        }
      }


    }
  } */
}
