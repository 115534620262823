
















































































































































import {Component, Vue} from "vue-property-decorator";
import ArchiveService from "../../archive.service";
import LoadingOverlay from "../../../../core/components/LoadingOverlay.vue"
import {Archive} from "../../../../models/archive";
import Constants from "../../../../staticdata/constants";
import ReactivateArchiveEntryDialog from "../../components/archive/dialogs/ReactivateArchiveEntryDialog.vue";


@Component({
  components: {ReactivateArchiveEntryDialog, LoadingOverlay}
})
export default class ArchiveJamia extends Vue {

  private showOverlay = false;

  search = "";

  private jamiaDeutschlandTableData = [];
  private jamiaUKTableData = [];
  private jamiaCanadaTableData = [];
  private jamiaPakistanTableData = [];
  private jamiaGhanaTableData = [];

  private reactivateArchiveEntryDialogVisible = false;
  private selectedArchiveForReactivate: any = null;

  private jamiaTableHeader = [
    {text: 'Jamaat ID', value: 'tajneedEntry.aimsId'},
    {text: 'Waqf-e-Nau ID', value: 'tajneedEntry.waqfenauId'},
    {text: 'Antragsteller', value: 'deleteTajneedRequest.requester.username'},
    // {text: 'Reaktivieren', value: 'reactivate', sortable: false, filterable: false, align: 'center'},
  ];


  beforeMount() {
    this.loadArchive();
  }

  loadArchive() {
    this.showOverlay = true;
    ArchiveService.getArchive()
      .then(
        (response: any) => {
          this.showOverlay = false;

          this.jamiaDeutschlandTableData = response.data.filter((archive: Archive) =>
            //@ts-ignore
            archive.deleteTajneedRequest.reason === Constants.REASON_JAMIA
            //@ts-ignore
            && archive.deleteTajneedRequest.jamia === Constants.JAMIA_DEUTSCHLAND
          );

          this.jamiaUKTableData = response.data.filter((archive: Archive) =>
            //@ts-ignore
            archive.deleteTajneedRequest.reason === Constants.REASON_JAMIA
            //@ts-ignore
            && archive.deleteTajneedRequest.jamia === Constants.JAMIA_UK
          );

          this.jamiaCanadaTableData = response.data.filter((archive: Archive) =>
            //@ts-ignore
            archive.deleteTajneedRequest.reason === Constants.REASON_JAMIA
            //@ts-ignore
            && archive.deleteTajneedRequest.jamia === Constants.JAMIA_CANADA
          );

          this.jamiaGhanaTableData = response.data.filter((archive: Archive) =>
            //@ts-ignore
            archive.deleteTajneedRequest.reason === Constants.REASON_JAMIA
            //@ts-ignore
            && archive.deleteTajneedRequest.jamia === Constants.JAMIA_GHANA
          );

        })
      .catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: error,
          type: 'error'
        });
        this.showOverlay = false;
      })
      .finally(() => {
        setTimeout(() => {
          this.showOverlay = false;
        }, 5000);
      });
  }

  handleReactivateArchiveEntry(selectedEntry: any){
    this.reactivateArchiveEntryDialogVisible = true;
    this.selectedArchiveForReactivate = selectedEntry
  }

  handleCloseReactivateArchiveEntryDialog(){
    this.reactivateArchiveEntryDialogVisible = false;
    this.loadArchive();
  }

}
