import { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';

import { ServerResponse } from '../../../core/core.types';
import {OwnMetaData} from "@/modules/tajneed/tajneed.types";
import {UseraccountService} from "../useraccount.service";

@Component
export default class MixinAccountMetaDataFetch extends Vue {

  @Mutation('SET_OWN_METADATA', { namespace: 'accountmetadata' })
  SET_OWN_METADATA!: (ownMetaData: OwnMetaData) => void;

  protected fetchAccountMetaData(username: string): Promise<void> {
      return UseraccountService.getOwnMetaData(username)
        .then((response: AxiosResponse<any>) => {
          this.SET_OWN_METADATA(response.data);
        })
        .catch((error: AxiosError<ServerResponse>) => {
         console.log("error");
        }).finally(() => {
        });

    return new Promise((resolve => resolve()));
  }
}
