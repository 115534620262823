

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import moment from 'moment';
import {Component, Vue} from 'vue-property-decorator';
import AppLoader from "../../../core/components/AppLoader.vue"

import AppCard from '../../../core/components/cards/AppCard.vue';
import {DiaryEntry} from "../diary.types";
import DiaryService from "../diary.service";

@Component({
  components: {
    AppCard,
    AppLoader
  },
})
export default class ViewDiary extends Vue {
  private formChildAddRequest = "formChildAddRequest";

  private diaryEntry: DiaryEntry = {};

  private currentDate: moment.Moment = moment();

  private currentDateString: string = '';

  private dateForwardingPossible: boolean = false;

  private dateBackwardingPossible: boolean = false;

  private loadingDiary: boolean = false;

  private saveDisabled: boolean = false;

  private isFriday: boolean = false;

  // Montag, Dienstag, Mittwoch, ...
  private currentDay: string = '';

  created() {
    if (!this.$route.params.personId) {
      this.$router.push({
        name: 'ViewDashboardPersons',
      });
    } else {
      this.currentDate = moment();
      this.currentDateString = this.currentDate.format("DD.MM.YYYY");
      this.checkDateForwardingPossible();
      this.loadDiaryByDate();
    }
  }

  private loadDiaryByDate() {
    this.loadingDiary = true;
    DiaryService.getDiaryEntry(this.currentDateString, this.$route.params.personId)
      .then((response: any) => {
        this.diaryEntry = response.data;
        this.loadingDiary = false;
      }).catch((error: any) => {
      this.diaryEntry = {
        diaryDate: this.currentDateString,

        tilawatWithoutTranslation: false,
        tilwatWithTranslation: false,

        fajrAloneRead: false,
        fajrCongreationRead: false,
        fajrNamazCenterRead: false,

        zuhrAloneRead: false,
        zuhrCongreationRead: false,
        zuhrNamazCenterRead: false,

        asrAloneRead: false,
        asrCongreationRead: false,
        asrNamazCenterRead: false,

        maghribAloneRead: false,
        maghribCongreationRead: false,
        maghribNamazCenterRead: false,

        ishaAloneRead: false,
        ishaCongreationRead: false,
        ishaNamazCenterRead: false,

        tahajjudRead: false,

        khilafatNawafilRead: false,
        khilafatPrayersRecited: false,

        fasted: false,

        fridayPrayerAttended: false,
        fridaySermonListened: false,

        jamaatiBookRead: false,

        mtaWatched: false,

        newsRead: false,

        sportsDone: false,

        jamaatServed: false,

      };
      this.diaryEntry.diaryDate = this.currentDateString;
      this.loadingDiary = false;
    }).finally(() => {
      setTimeout(() => {
        this.loadingDiary = false;
      }, 5000);
    });
  }

  private dateBack() {
    this.currentDate.subtract(1, 'd');
    this.currentDateString = this.currentDate.format("DD.MM.YYYY");
    this.loadDiaryByDate();
    this.checkDateForwardingPossible();
  }

  private dateForward() {
    this.currentDate.add(1, 'd');
    this.currentDateString = this.currentDate.format("DD.MM.YYYY");
    this.loadDiaryByDate();
    this.checkDateForwardingPossible();
  }

  private checkDateForwardingPossible() {
    // We have to copy this in order to not to edit original object
    let currentDateStr = this.currentDate.format("DD.MM.YYYY");
    let currentDate = moment(currentDateStr, 'DD.MM.YYYY');
    let today = moment(moment().format("DD.MM.YYYY"), "DD.MM.YYYY");
    if (moment(currentDate.add(1, 'd').format("DD.MM.YYYY"), "DD.MM.YYYY").isAfter(moment(moment().format("DD.MM.YYYY"), "DD.MM.YYYY"))) {
      this.dateForwardingPossible = false;
    } else {
      this.dateForwardingPossible = true;
    }
    currentDate = moment(currentDateStr, 'DD.MM.YYYY');
    if (moment(currentDate.subtract(1, 'd').format("DD.MM.YYYY"), "DD.MM.YYYY").isBefore(moment("01.06.2021", "DD.MM.YYYY"))) {
      this.dateBackwardingPossible = false;
    } else {
      this.dateBackwardingPossible = true;
    }

    currentDate = moment(currentDateStr, 'DD.MM.YYYY');
    if ((moment().month() > currentDate.month() + 1) || (moment().month() == currentDate.month() + 1 && moment().date() > 31)) {
      // if ((moment().month() > currentDate.month() + 1) || (moment().month() == currentDate.month() + 1 && moment().date() > 10)) {
      this.saveDisabled = true;
    } else {
      this.saveDisabled = false;
    }

    // Check is Friday
    currentDate = moment(currentDateStr, 'DD.MM.YYYY');
    if ((currentDate.day() == 5)) {
      this.isFriday = true;
    } else {
      this.isFriday = false;
    }

    // Get day string
    currentDate = moment(currentDateStr, 'DD.MM.YYYY');
    let day = '';
    console.log(currentDate.day());
    switch (currentDate.day()) {
      case 1: day = "Montag"; break;
      case 2: day = "Dienstag"; break;
      case 3: day = "Mittwoch"; break;
      case 4: day = "Donnerstag"; break;
      case 5: day = "Freitag"; break;
      case 6: day = "Samstag"; break;
      case 0: day = "Sonntag"; break;
    }
    console.log(day);
    this.currentDay = day;
  }

  private saveEntry() {
    DiaryService.saveDiaryEntry(this.$route.params.personId, this.diaryEntry)
      .then((response: any) => {
        this.$notify({
          group: 'foo',
          title: 'Info',
          text: "Gespeichert",
          type: 'success'
        });
      }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: error.response.data.message,
        type: 'error'
      });
    }).finally(() => {
      setTimeout(() => {
      }, 5000);
    });
  }

  private setTilawatDoneWithoutTranslation() {
    if (this.diaryEntry.tilawatWithoutTranslation) {
      this.diaryEntry.tilawatWithoutTranslation = false;
    } else {
      this.diaryEntry.tilawatWithoutTranslation = true;
      this.diaryEntry.tilwatWithTranslation = false;
    }
  }

  private setTilawatDoneWithTranslation() {
    if (this.diaryEntry.tilwatWithTranslation) {
      this.diaryEntry.tilwatWithTranslation = false;
    } else {
      this.diaryEntry.tilwatWithTranslation = true;
      this.diaryEntry.tilawatWithoutTranslation = false;
    }
  }


  private setFajrAloneRead() {
    if (this.diaryEntry.fajrAloneRead) {
      this.diaryEntry.fajrAloneRead = false;
    } else {
      this.diaryEntry.fajrAloneRead = true;
      this.diaryEntry.fajrCongreationRead = false;
      this.diaryEntry.fajrNamazCenterRead = false;
    }
  }

  private setFajrCongregationRead() {
    if (this.diaryEntry.fajrCongreationRead) {
      this.diaryEntry.fajrCongreationRead = false;
    } else {
      this.diaryEntry.fajrAloneRead = false;
      this.diaryEntry.fajrCongreationRead = true;
      this.diaryEntry.fajrNamazCenterRead = false;
    }
  }

  private setFajrNcMosqueRead() {
    if (this.diaryEntry.fajrNamazCenterRead) {
      this.diaryEntry.fajrNamazCenterRead = false;
    } else {
      this.diaryEntry.fajrAloneRead = false;
      this.diaryEntry.fajrCongreationRead = false;
      this.diaryEntry.fajrNamazCenterRead = true;
    }
  }

  private setZuhrAloneRead() {
    if (this.diaryEntry.zuhrAloneRead) {
      this.diaryEntry.zuhrAloneRead = false;
    } else {
      this.diaryEntry.zuhrAloneRead = true;
      this.diaryEntry.zuhrCongreationRead = false;
      this.diaryEntry.zuhrNamazCenterRead = false;
    }
  }

  private setZuhrCongregationRead() {
    if (this.diaryEntry.zuhrCongreationRead) {
      this.diaryEntry.zuhrCongreationRead = false;
    } else {
      this.diaryEntry.zuhrAloneRead = false;
      this.diaryEntry.zuhrCongreationRead = true;
      this.diaryEntry.zuhrNamazCenterRead = false;
    }
  }

  private setZuhrNcMosqueRead() {
    if (this.diaryEntry.zuhrNamazCenterRead) {
      this.diaryEntry.zuhrNamazCenterRead = false;
    } else {
      this.diaryEntry.zuhrAloneRead = false;
      this.diaryEntry.zuhrCongreationRead = false;
      this.diaryEntry.zuhrNamazCenterRead = true;
    }
  }

  private setAsrAloneRead() {
    if (this.diaryEntry.asrAloneRead) {
      this.diaryEntry.asrAloneRead = false;
    } else {
      this.diaryEntry.asrAloneRead = true;
      this.diaryEntry.asrCongreationRead = false;
      this.diaryEntry.asrNamazCenterRead = false;
    }
  }

  private setAsrCongregationRead() {
    if (this.diaryEntry.asrCongreationRead) {
      this.diaryEntry.asrCongreationRead = false;
    } else {
      this.diaryEntry.asrAloneRead = false;
      this.diaryEntry.asrCongreationRead = true;
      this.diaryEntry.asrNamazCenterRead = false;
    }
  }

  private setAsrNcMosqueRead() {
    if (this.diaryEntry.asrNamazCenterRead) {
      this.diaryEntry.asrNamazCenterRead = false;
    } else {
      this.diaryEntry.asrAloneRead = false;
      this.diaryEntry.asrCongreationRead = false;
      this.diaryEntry.asrNamazCenterRead = true;
    }
  }

  private setMaghribAloneRead() {
    if (this.diaryEntry.maghribAloneRead) {
      this.diaryEntry.maghribAloneRead = false;
    } else {
      this.diaryEntry.maghribAloneRead = true;
      this.diaryEntry.maghribCongreationRead = false;
      this.diaryEntry.maghribNamazCenterRead = false;
    }
  }

  private setMaghribCongregationRead() {
    if (this.diaryEntry.maghribCongreationRead) {
      this.diaryEntry.maghribCongreationRead = false;
    } else {
      this.diaryEntry.maghribAloneRead = false;
      this.diaryEntry.maghribCongreationRead = true;
      this.diaryEntry.maghribNamazCenterRead = false;
    }
  }

  private setMaghribNcMosqueRead() {
    if (this.diaryEntry.maghribNamazCenterRead) {
      this.diaryEntry.maghribNamazCenterRead = false;
    } else {
      this.diaryEntry.maghribAloneRead = false;
      this.diaryEntry.maghribCongreationRead = false;
      this.diaryEntry.maghribNamazCenterRead = true;
    }
  }


  private setIshaAloneRead() {
    if (this.diaryEntry.ishaAloneRead) {
      this.diaryEntry.ishaAloneRead = false;
    } else {
      this.diaryEntry.ishaAloneRead = true;
      this.diaryEntry.ishaCongreationRead = false;
      this.diaryEntry.ishaNamazCenterRead = false;
    }
  }

  private setIshaCongregationRead() {
    if (this.diaryEntry.ishaCongreationRead) {
      this.diaryEntry.ishaCongreationRead = false;
    } else {
      this.diaryEntry.ishaAloneRead = false;
      this.diaryEntry.ishaCongreationRead = true;
      this.diaryEntry.ishaNamazCenterRead = false;
    }
  }

  private setIshaNcMosqueRead() {
    if (this.diaryEntry.ishaNamazCenterRead) {
      this.diaryEntry.ishaNamazCenterRead = false;
    } else {
      this.diaryEntry.ishaAloneRead = false;
      this.diaryEntry.ishaCongreationRead = false;
      this.diaryEntry.ishaNamazCenterRead = true;
    }
  }

  private setKhilafatNawafilRead() {
    this.diaryEntry.khilafatNawafilRead = true;
  }

  private setKhilafatNawafilNotRead() {
    this.diaryEntry.khilafatNawafilRead = false;
  }

  private setKhilafatPrayersRecited() {
    this.diaryEntry.khilafatPrayersRecited = true;
  }

  private setKhilafatPrayersNotRecited() {
    this.diaryEntry.khilafatPrayersRecited = false;
  }

  private setFasted() {
    this.diaryEntry.fasted = true;
  }

  private setNotFasted() {
    this.diaryEntry.fasted = false;
  }

  private setFridayPrayerAttended() {
    this.diaryEntry.fridayPrayerAttended = true;
  }

  private setFridayPrayerNotAttended() {
    this.diaryEntry.fridayPrayerAttended = false;
  }

  private setFridaySermonListened() {
    this.diaryEntry.fridaySermonListened = true;
  }

  private setFridaySermonNotListened() {
    this.diaryEntry.fridaySermonListened = false;
  }

  private setJamaatiBookRead() {
    this.diaryEntry.jamaatiBookRead = true;
  }

  private setJamaatiBookNotRead() {
    this.diaryEntry.jamaatiBookRead = false;
  }

}
