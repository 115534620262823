





































import { Component, Mixins, Vue } from 'vue-property-decorator';
import FormChangePassword from './FormChangePassword.vue';

@Component({
  components: { FormChangePassword },
})
export default class ExpansionPanelChangePassword extends Vue{

}

