
















































































































import {Component, Prop, Vue} from "vue-property-decorator";
import TajneedService from "../../../tajneed.service"
import LoadingOverlay from "../../../../../core/components/LoadingOverlay.vue"
import {Tajneed} from "../../../tajneed.types";
import BasedataService from "../../../../../services/basedata.service"

@Component({
  components: {LoadingOverlay},
})
export default class EditCoreTajneedDataDialog extends Vue {

  @Prop({default: null})
  selectedTajneed: Tajneed;

  private jamaatItems: string[] = [];

  private majlisItems: string[] = [];

  private rules = {
    required: (value: any) => !!value || 'Pflichtfeld',
    dateFormat: (v: string) => /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)\d\d$/.test(v) || 'TT.MM.JJJJ Format',
    digitsOnly: (v: string) => /^(\s*|\d+)$/.test(v) || 'Jamaat-ID darf nur Zahlen beinhalten.',
    validWaqfenauId: (v: string) => /^[0-9][0-9]?[0-9]?[0-9]?[0-9]?[A-Z][A-Z]?[0-9]?$/.test(v) || 'Keine gültige Waqf-e-Nau Nummer',
  };

  private showOverlay = false;

  created() {
    BasedataService.getJamaatAndMajalis()
      .then((response: any) => {
        this.jamaatItems = response.data.jamaats;
        this.majlisItems = response.data.majalis;
      })
      .catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: 'Netzwerkfehler',
          type: 'error'
        });
      })
      .finally(() => {
        setTimeout(() => {
        }, 5000);
      });
  }

  changeTajneedCoreData() {
    // @ts-ignore
    this.selectedTajneed.waqfenauId = this.selectedTajneed.waqfenauId.replace(" ", "");
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.showOverlay = true;
      TajneedService.updateCoreTajneedData(this.selectedTajneed)
        .then(
          (response: any) => {
            this.showOverlay = false;
            this.$notify({
              group: 'foo',
              title: 'Info',
              text: 'Änderungen wurden erfolgreich gespeichert',
              type: 'success'
            });
            this.$emit('on-core-update-success', response.data);
          })
        .catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text: error.response.data,
            type: 'error'
          });
          this.showOverlay = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.showOverlay = false;
          }, 5000);
        });
    }

  }

}
