
















































import {Component, Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import JaizaService from '../jaiza.service';
import AppCard from '../../../core/components/cards/AppCard.vue';
import {Useraccount} from "../../../models/useraccount.model";
import {JudgeAccountCredentials, JudgesRegionJson} from "../jaiza.types";
import TableButton from '../../../core/components/TableButton.vue';
import {AxiosResponse} from "axios";

@Component({
  components: {
    AppCard,
    TableButton
  },
})
export default class ViewJudgesRegion extends Vue {
  @Getter('getUseraccount', {namespace: 'useraccount'})
  private getUseraccount!: Useraccount;

  private result: string = '';

  private judgesTableHeaders = [
    {text: 'Jamaat/Majlis', value: 'jamaatMajlis'},
    {text: 'Account Judge', value: 'judgeAccount'},
    {text: 'Passwort', value: 'password'},
    {text: '', align: 'end', sortable: false, value: 'actions'},
  ];

  private judgesTableItems: JudgesRegionJson[] = [];

  created() {
    this.loadJudges();
  }

  private loadJudges(){
    JaizaService.loadJudges()
      .then((response: any) => {
        this.judgesTableItems = response.data;
      }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: '',
        type: 'error'
      });
    }).finally(() => {
      setTimeout(() => {
      }, 5000);
    });
  }

  private createJudge(jamaatMajlis: string){
    JaizaService.createJudgeForJamaatMajlis(jamaatMajlis)
      .then((response: AxiosResponse<JudgeAccountCredentials>) => {
        this.judgesTableItems.filter(j => j.jamaatMajlis == jamaatMajlis)[0].judgeAccount = response.data.username;
        this.judgesTableItems.filter(j => j.jamaatMajlis == jamaatMajlis)[0].password = response.data.password;
      }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: '',
        type: 'error'
      });
    }).finally(() => {
      setTimeout(() => {
      }, 5000);
    });
  }

  private resetJudgePassword(jamaatMajlis: string){
    JaizaService.resetJudgePasswordForJamaatMajlis(jamaatMajlis)
      .then((response: AxiosResponse<JudgeAccountCredentials>) => {
        this.judgesTableItems.filter(j => j.jamaatMajlis == jamaatMajlis)[0].password = response.data.password;
      }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: '',
        type: 'error'
      });
    }).finally(() => {
      setTimeout(() => {
      }, 5000);
    });
  }

}
