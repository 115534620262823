















































































































import { Component, Vue } from 'vue-property-decorator';
import AppCard from '../../../core/components/cards/AppCard.vue';
import DownloadNisabCardButton from '../components/DownloadNisabCardButton.vue';
import DashboardCol from '../../../core/components/DashboardCol.vue';
import DashboardRow from '../../../core/components/DashboardRow.vue';

@Component({
  components: {
    AppCard,
    DownloadNisabCardButton,
    DashboardCol,
    DashboardRow
  }
})
export default class ViewDownloadsNisab extends Vue {

  private loadingDashboardData: boolean = false;

  created() {
  }

}
