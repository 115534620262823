



































import {Component, Prop, Vue} from 'vue-property-decorator';
import moment from 'moment';

import AppCardButton from '../../../core/components/cards/AppCardButton.vue';
import EnterEmailOfChildOlderThenSixteen from "../../tajneed/components/tajneed/dialogs/EnterEmailOfChildOlderThenSixteen.vue";
import {UseraccountService} from "../../useraccount/useraccount.service";
import {router} from "../../../router";
import {OwnMetaData} from "../../tajneed/tajneed.types";
import {Mutation} from "vuex-class";
import {Useraccount} from "@/models/useraccount.model";
import {store} from "@/store";

@Component({
  components: {
    EnterEmailOfChildOlderThenSixteen,
    AppCardButton,
  },
})
export default class DashboardTileChild extends Vue {

  @Mutation('SET_OWN_METADATA', {namespace: 'accountmetadata'})
  SET_OWN_METADATA!: (ownMetaData: OwnMetaData) => void;

  @Prop({required: true})
  tileText: string;

  @Prop({default: null, required: false})
  tajneedId: any;

  @Prop({default: null, required: false})
  personId: any;

  @Prop({default: null, required: false})
  dateOfBirth: any;

  private isOlderThenSixteen = false;

  private childOverSixteenDialogVisible = false;

  created() {
    this.isOlderThenSixteen = moment().diff(this.dateOfBirth, 'years') >= 16 ? true : false
  }

  private showChildOverSixteenDialog() {
    this.childOverSixteenDialogVisible = true;
  }

  private closeEnterEmailofChildOlderDialog() {
    let user: Useraccount = store.getters['useraccount/getUseraccount'];
    UseraccountService.getOwnMetaData(user.getUsername)
      .then((response: any) => {
        this.SET_OWN_METADATA(response.data);
        router.push({name: 'ViewDashboardPersons'});
      });
    this.childOverSixteenDialogVisible = false;
  }


}

