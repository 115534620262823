import httpResource from "../../http/httpResource";
import {ResponseApprovals} from "@/modules/tajneed/tajneed.types";

const API_URL = process.env.VUE_APP_API_URL + '/api/approval/';

class ApprovalService {

  approveTajneedDelete(request: any) {
    return httpResource.post(API_URL + 'approvetajneeddelete', request);

  }

  approveChildAddRequest(requestId: any) {
    return httpResource.post(API_URL + 'approvechildaddrequest', { childAddRequest: requestId});
  }

  declineChildAddRequest(requestId: any) {
    return httpResource.post(API_URL + 'declinechildaddrequest', { childAddRequest: requestId});

  }

  declineTajneedDelete(selectedTajneedId: any, declineReason: any) {
    return httpResource.post(API_URL + 'declinetajneeddelete', {
      selectedTajneedId: selectedTajneedId,
      declineReason: declineReason,
    });
  }

  approveNewTajneed(selectedTajneedId: any) {
    return httpResource.post(API_URL + 'newtajneed/approve', { selectedTajneedId: selectedTajneedId});
  }

  declineNewTajneed(selectedTajneedId: any) {
    return httpResource.post(API_URL + 'newtajneed/decline', {
      selectedTajneedId: selectedTajneedId,
    });
  }

  getApprovals(sex: string) {
    return httpResource.post(API_URL + 'getapprovals', {sex: sex});
  }

  getRefusalPdf(deleteRequestId: string) {
    return httpResource.get(API_URL + 'refusalpdf/' + deleteRequestId);
  }
}

export default new ApprovalService();
