
































import {Component, Prop, Vue} from "vue-property-decorator";
import BasedataService from '../basedata.service';
import LoadingOverlay from "../../../core/components/LoadingOverlay.vue"

@Component({
  components: {LoadingOverlay},
})
export default class CreateNewLajnaRegionDialog extends Vue {

  private showOverlay = false;

  private newRegionName: string = '';

  private valid: boolean = true;


  notempty = [
    (v: string) => !!v || 'Feld muss ausgefüllt sein'
  ];

  createNewRegion() {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.showOverlay = true;
      BasedataService.createNewLajnaRegion(this.newRegionName)
        .then((response: any) => {
          this.showOverlay = false;
          this.$notify({
            group: 'foo',
            title: 'Info',
            text: 'Region wurde erfolgreich angelegt',
            type: 'success'
          });
          this.$emit('success');
        })
        .catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text:  error.response!.data.message,
            type: 'error'
          });
          this.showOverlay = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.showOverlay = false;
          }, 5000);
        });
    }
  }

}
