


























































































































































import Vue from 'vue';
import {Component} from "vue-property-decorator";

import LoadingOverlay from "../../../core/components/LoadingOverlay.vue";


//const store = this.$store.direct;
@Component({
  components: {LoadingOverlay},
})
export default class DataPrivacy extends Vue {

}
