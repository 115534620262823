
































































import moment from 'moment';
import {Component, Vue} from "vue-property-decorator";
import LoadingOverlay from "../../../core/components/LoadingOverlay.vue"
import AppLoader from "../../../core/components/AppLoader.vue"
import {LocalReport, LocalReportJson, ReportTajneedSessionAttendedSet} from '../report.types';
import ReportService from '../report.service';
import TimeSeriesChart from '../components/TimeSeriesChart.vue';
import {Getter} from "vuex-class";
import {Useraccount} from "../../../models/useraccount.model";
import LocalReportIndividualData from "../components/LocalReportIndividualData.vue";
import {Tajneed} from "../../tajneed/tajneed.types";
import LocalReportSignatureInput from "../components/LocalReportSignatureInput.vue";
import {FileReadUtils} from "../../../utils/filereadutils";

@Component({
  components: {LocalReportIndividualData, AppLoader, LoadingOverlay, TimeSeriesChart, LocalReportSignatureInput}
})
export default class ViewReportToHuzoor extends Vue {

  private loadingReports: boolean = false;

  private report = null;

  private rules = {
    required: (value: any) => !!value || 'Pflichtfeld',
    digitsOnly: (v: string) => /^(\s*|\d+)$/.test(v) || 'Zur Zahlen erlaubt',
  };

  created() {
    this.loadReports();
  }

  private reloadReports() {
    this.loadReports();
  }

  loadReports() {
    this.loadingReports = true;
    //@ts-ignore
    let type = '';
    ReportService.getReportForHuzoor().then((response: any) => {
      this.report = response.data;
      this.loadingReports = false;
    }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: error,
        type: 'error'
      });
      this.loadingReports = false;
    }).finally(() => {
      setTimeout(() => {
        this.loadingReports = false;
      }, 5000);
    });
  }
}
