






















































import { Component, Prop, Vue } from 'vue-property-decorator';

import AppCard from './AppCard.vue';

@Component({
  components: { AppCard },
})
export default class AppCardButton extends Vue {
  @Prop({ default: false, required: false })
  isDisabled!: boolean;

  @Prop({ default: 'mdi-card-outline', required: false })
  icon!: string;

  @Prop({ default: null, required: false })
  text!: string;

  @Prop({ default: '', required: false })
  actionUrl!: string;

  @Prop({ default: '', required: false })
  actionPath!: string;

  @Prop({ default: false, required: false })
  externalRedirect!: boolean;

  @Prop({ default: false, required: false })
  hasInfo!: boolean;

  @Prop({ default: '', required: false })
  infoText!: string;

  @Prop({ default: null, required: false })
  tajneedId!: any;

  @Prop({ default: "", required: false })
  personId!: string;

  @Prop({ default: null, required: false })
  isChild!: any;

  @Prop({ default: null, required: false })
  isOwnTajneed!: any;

  @Prop({ default: false, required: false })
  deactivateRedirect!: boolean;

  @Prop({ default: "", required: false })
  dateOfBirth!: string;

  @Prop({ default: false, required: false })
  onlyActionPath!: any;

  private redirect() {
    if(!this.deactivateRedirect){
      if (this.actionUrl) {
        this.$router.push({
          name: this.actionUrl,
          params: {
            tajneedId: this.tajneedId,
            isChild: this.isChild,
            isOwnTajneed: this.isOwnTajneed,
            dateOfBirth: this.dateOfBirth,
            personId: this.personId,
          }
        });
      } else if (this.actionPath && !this.onlyActionPath) {
        this.$router.push({
          path: this.actionPath,
          query: {
            tajneedId: this.tajneedId,
            isChild: this.isChild,
            isOwnTajneed: this.isOwnTajneed,
            dateOfBirth: this.dateOfBirth,
            personId: this.personId,
          }
        });
      } else {
        this.$router.push({
          path: this.actionPath,
        });
      }
    }
  }
}

