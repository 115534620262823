import {JaizaRequest} from "@/modules/jaiza/jaiza.types";
import httpResource from "../../http/httpResource";
import {AxiosResponse} from "axios";

const API_URL = process.env.VUE_APP_API_URL + '/api';

class DashboardService {

  getDashobardData(tajneedId: string) {
    return httpResource.get(API_URL+ '/dashboard/' + tajneedId);
  }
}

export default new DashboardService();
