






























































































import {Component, Vue} from "vue-property-decorator";
import LoadingOverlay from "../../../core/components/LoadingOverlay.vue"
import AppLoader from "../../../core/components/AppLoader.vue"
import {Getter} from "vuex-class";
import {Useraccount} from "../../../models/useraccount.model";
import {Exam} from '../exam.types';
import ExamService from '../exam.service';
import moment from 'moment';

@Component({
  components: {AppLoader, LoadingOverlay}
})
export default class ViewTableExamsIndividual extends Vue {
  @Getter('getUseraccount', {namespace: 'useraccount'})
  private getUseraccount!: Useraccount;

  tableData: Exam[] = [];

  private personId: string = "";

  headers = [
    {text: 'Jahr', value: 'year'},
    {text: 'Erstellt', value: 'createDate'},
    {text: 'Abschlussdatum', value: 'dateFinished'},
    {text: 'Status', value: 'status'}
  ];

  private loadingExams: boolean = false;

  created() {
    if (!this.$route.params.personId) {
      this.$router.push({path: '/'});
    } else {
      this.personId = this.$route.params.personId;
      this.loadExams();
    }
    /* var iframe = document.getElementById('myIFrame');
    console.log(iframe);
    //@ts-ignore
    iframe!.contentWindow!.addEventListener('load', () => {
      console.log(iframe);
            //@ts-ignore
      const link = iframe!.contentDocument!.getElementsByClassName('uArJ5e')[0];
      console.log(link);
      link.addEventListener('click', this.test())
    }) */

    /* var iframeElem = document.getElementById('myIFrame');


    iframeElem!.onload = function(){
        //onload also catches form post sending
                      //@ts-ignore
        var iframeDoc = iframeElem!.contentDocument;
        //retrieves the height of the content's body
        var contentHeight = iframeDoc.body.scrollHeight;
        console.log(contentHeight);
        //do something ... 
    } */
  }

  loadExams() {
    this.loadingExams = true;

    //@ts-ignore
    ExamService.getExamsForIndividual(this.personId).then((response: any) => {
      //We only want the latest delete request to be seen
      this.loadingExams = false;

      this.tableData = response.data;

    }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: error,
        type: 'error'
      });
      this.loadingExams = false;
    }).finally(() => {
      setTimeout(() => {
        this.loadingExams = false;
      }, 5000);
    });
  }

  handleEdit(selectedEntry: Exam) {
    //@ts-ignore
    this.$router.push({
      name: 'ViewExam',
      params: {
        examId: selectedEntry.id,
        personId:this.$route.params.personId
      }
    });
  }

  getTextForStatus(status: string): string {
    switch (status) {
      case "CREATED" :
        return "ERSTELLT"
      case "SAVED" :
        return "ZWISCHENGESPEICHERT"
      case "FINISHED" :
        return "ABGESCHLOSSEN"
      case "EXPIRED" :
        return "ABGELAUFEN"
      default:
        return "";
    }
  }
}
