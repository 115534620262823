import {RegistrationI} from "../../models/registration";
import httpResource from "../../http/httpResource";

const API_URL = process.env.VUE_APP_API_URL + '/api/registration/';

class RegistrationService {

  register(registration: RegistrationI) {
    return httpResource.post(API_URL + 'register', {registration : registration});
  }

  getRegistrations() {
    return httpResource.get(API_URL+ 'getregistrations');
  }
}

export default new RegistrationService();
