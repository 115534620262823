






















































































































































  import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import AppCard from '../../../core/components/cards/AppCard.vue';
import AppLoader from '../../../core/components/AppLoader.vue';
import DocumentsService from "../documents.service";
import DashboardCol from '../../../core/components/DashboardCol.vue';
import DashboardRow from '../../../core/components/DashboardRow.vue';
import {AxiosResponse, AxiosError} from "axios";
import TableButton from '../../../core/components/TableButton.vue';
import {FileReadUtils} from "../../../utils/filereadutils";
import {ServerResponse} from '../../../core/core.types';
import {Getter} from 'vuex-class';
import {Useraccount} from "../../../models/useraccount.model";

@Component({
  components: {
    AppCard,
    AppLoader,
    DashboardCol,
    DashboardRow,
    TableButton
  }
})
export default class ViewDocuments extends Vue {
  @Getter('getUseraccount', {namespace: 'useraccount'})
    private getUseraccount!: Useraccount;

  @Ref()
  hiddenFileInput!: HTMLInputElement;

  private documentTableHeaders = [
    {text: 'Name', align: 'start', sortable: false, value: 'fileName'},
    {text: 'Typ', align: 'start', sortable: false, value: 'fileType'},
  ];

  private documentUploadTableHeaders = [
    {text: 'Name', value: 'name'},
    {text: 'Größe', value: 'size'},
    {text: 'Rollen', value: 'roles'},
    {text: 'Typ', value: 'type'}
  ];

  private documentTableItems: any[] = [];
  private allRoleItems: any[] = [];

  private personId: string = "";

  private loadingData: boolean = false;

  private processingFiles: boolean = false;
  
  private uploadingFiles: boolean = false;

  private acceptedFileTypes: string = 'image/png,image/jpeg,image/jpg,application/pdf';

  private documentsToUploadList: any[] = [];

  private search = '';

  private searchType = '';

  private isNationalJamaatUser: boolean = false;

  private documentTypeItems: string[] = [];

  private documentTypeSelected: string = "";

  private notempty = [
      (v: []) => v.length > 0 || 'Feld muss ausgefüllt sein.',
    ];

  private notemptyString = [
    (v: string) => v.length > 0 && v.trim().length > 0 || 'Feld muss ausgefüllt sein.',
  ];

  @Watch('searchType')
  onPropertyChange(value: any, oldValue: any) {
    if (value && value.indexOf(' ') == 0) {
      value.trim();
    }
  }

  created() {
    // If someone has entered URL of /documents directly in browser,
    // then redirect to persons overview:
    if (!this.$route.params.personId) {
      this.$router.push({name: 'ViewDashboardPersons'});
    } else {
      this.personId = this.$route.params.personId;
      this.loadDocuments();
    }

    if (this.getUseraccount.isNationalJamaatUser) {
      this.isNationalJamaatUser = true;
      this.documentTableHeaders.push({text: 'Lesezugriff', align: 'start', sortable: false, value: 'roles'})
    }
    this.documentTableHeaders.push({text: '', align: 'end', sortable: false, value: 'actions'})
  }

  private loadDocuments() {
    this.loadingData = true;
    DocumentsService.getAllDocuments(this.$route.params.personId).then((response: AxiosResponse) => {
        this.loadingData = false;
        this.documentTableItems = response.data.documents;
        this.allRoleItems = response.data.roles;
        this.documentTypeItems = response.data.types;
      });
  }

  private fileDownload(item: any) {
    DocumentsService.getDocument(this.personId, item.id).then((response: any) => {
      FileReadUtils.openSaveAs(response.data.document, item.fileName);
    });
  }

  private fileInputChanged(event: any) {
    let selectedDocuments = event.target.files;
    let containsNonPdf = false;
    if (selectedDocuments.length > 0) {
      for (var i = 0; i < selectedDocuments.length; i++){
        if (selectedDocuments[i].name.split('.').pop() != 'pdf'){
          containsNonPdf = true;
        }
      }
    }
    if(containsNonPdf) {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: "Nur PDF Dateien als Anhang erlaubt",
        type: 'error'
      });
      return;
    }
    for (var i = 0; i < selectedDocuments.length; i++) {
      var _size = '0';
      if (selectedDocuments[i].size < 1000000) {
        _size = Math.floor(selectedDocuments[i].size/1000) + ' KB';
      } else {
        _size = Math.floor(selectedDocuments[i].size/1000000).toString();
        if (+_size > 10) {
          this.$notify({group: 'foo', title: 'Fehler', text: 'Dateien größer als 10 MB sind nicht erlaubt.', type: 'error'});
          return;
        }
        _size = _size + ' MB';
      }
      
      this.documentsToUploadList.push({
        fileObject: selectedDocuments[i],
        name: selectedDocuments[i].name,
        size: _size,
        type: ''
      });
    }
   }

   async uploadFiles() {
    if ((this.$refs.formMain as Vue & { validate: () => boolean }).validate()) {
      this.uploadingFiles = true;
      let documentsToUpload = [];
        //Handle documents added in the file input field:
        if (this.documentsToUploadList != null) {
          for (var i = 0; i < this.documentsToUploadList.length; i++) {
            let binaryString = await FileReadUtils.readFileAsync(this.documentsToUploadList[i].fileObject);
            //@ts-ignore
            let encodedBinaryString = window.btoa(binaryString.toString());

            let documentUpload = {
              id: null,
              data: encodedBinaryString,
              fileName: this.documentsToUploadList[i].name,
              type: this.documentsToUploadList[i].type,
              roles: this.documentsToUploadList[i].roles
            };

            documentsToUpload.push(documentUpload);
          }
        }

        let request = {
          documents: documentsToUpload,
          personId: this.personId
        }
        
        DocumentsService.uploadDocuments(request).then((response: AxiosResponse) => {
          this.uploadingFiles = false;
          this.$notify({group: 'foo', title: 'Info', text: 'Gespeichert', type: 'success'});
          this.documentsToUploadList = [];
          this.loadDocuments();
        }).catch((error: AxiosError<ServerResponse>) => {
          this.uploadingFiles = false;
          this.$notify({group: 'foo', title: 'Fehler', text: 'Es ist ein Fehler aufgetreten', type: 'error'});
        }).finally(() => {
          setTimeout(() => {
            this.uploadingFiles = false;
          }, 5000);
        });
        
        return documentsToUpload;
    }
   }

}
