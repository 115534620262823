import Vue from 'vue';
import {
  Action,
  Module, Mutation, VuexModule,
} from 'vuex-module-decorators';

import {IndividualReport, IndividualReportsForGroupJson, ReportsForGroupJson} from './report.types';

@Module({
  namespaced: true,
})
export class ReportStore extends VuexModule {

  private fetchingIndividualReports: boolean = false;

  private individualReports: IndividualReportsForGroupJson[] = [];

  private organizationReportsGroup: ReportsForGroupJson[] = [];

  get getIndividualReports(): IndividualReportsForGroupJson[] {
    return this.individualReports;
  }

  get getOrganizationForGroup(): ReportsForGroupJson[] {
    return this.organizationReportsGroup;
  }

  @Mutation
  private SET_FETCHING_INDIVIDUAL_REPORT(value: boolean): void {
    this.fetchingIndividualReports = value;
  }

  @Mutation
  private SET_INDIVIDUAL_REPORTS(individualReports: IndividualReportsForGroupJson[]): void {
    this.individualReports = individualReports;
  }

  /* @Mutation
  private SET_FETCHING_ORGANIZATION_REPORTS_GROUP(value: boolean): void {
    this.fetchingIndividualReports = value;
  } */

  @Mutation
  private SET_ORGANIZATION_REPORTS_GROUP(organizationReportsGroup: ReportsForGroupJson[]): void {
    this.organizationReportsGroup = organizationReportsGroup;
  }
}
