











































































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AppCard extends Vue {
  @Prop({ default: true, required: false })
  closeable!: boolean;

  @Prop({ default: true, required: false })
  initialShowContent!: boolean;

  @Prop({ default: false, required: false })
  isDisabled!: boolean;

  @Prop({ default: false, required: false })
  isOutlined!: boolean;

  @Prop({ default: false, required: false })
  isHoverable!: boolean;

  @Prop({ default: false, required: false })
  isTransparent!: boolean;

  @Prop({ default: false, required: false })
  hasOverlay!: boolean;

  @Prop({ default: true, required: false })
  hasHeader!: boolean;

  @Prop({ default: 'mdi-card-outline', required: false })
  icon!: string;

  @Prop({ default: 'AppCard', required: false })
  title!: string;

  @Prop({ default: '', required: false })
  linkUrl!: string;

  @Prop({ default: null, required: false })
  linkCallback!: () => {};

  @Prop({ default: '', required: false })
  linkText!: string;

  @Prop({ default: true, required: false })
  isGrowing!: boolean;

  @Prop({ default: false, required: false })
  isHighlighted!: boolean;

  @Prop({ default: '', required: false })
  badgeText!: string;

  @Prop({ default: '', required: false })
  badgeIcon!: string;

  @Prop({ default: 'primary', required: false })
  badgeColor!: string;

  @Prop({ default: true, required: false })
  forceOutlined!: boolean;

  private showContent: boolean = true;

  private created() {
    this.showContent = this.initialShowContent;
  }
}
