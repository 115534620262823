























































































import {Component, Vue} from "vue-property-decorator";
import LoadingOverlay from "../../../core/components/LoadingOverlay.vue"
import AppLoader from "../../../core/components/AppLoader.vue"
import ReportService from '../report.service';
import TimeSeriesChart from '../components/TimeSeriesChart.vue';
import moment from 'moment';
/**
 * Data (Label with values)
 */
export interface Data {
  label: string;
  data: Value[];
}

/**
 * Value
 */
export interface Value {
  x: string;
  y: number;
}

@Component({
  components: {AppLoader, LoadingOverlay, TimeSeriesChart}
})
export default class ViewTableLocalReports extends Vue {

  tableData: any[] = [];

  headers = [
    {text: 'Jahr', value: 'year'},
    {text: 'Monat', value: 'month'},
    {text: 'Anzahl Waqifin Berichte', value: 'totalFinishedReports'},
    {text: 'Status', value: 'status'},
  ];

  private xAxesLabels: string[] = [];

  private loadingReports: boolean = false;

  private maximumReports: number = 100;

  /**
   * All data
   */
  private allDataReports: Data[] = [];

  private allDataTarbiyyat: Data[] = [];

  private allDataKhilafat: Data[] = [];

  /**
   * Data for chart
   */
  private chartDataReports: Chart.ChartData = {};

  private chartDataTarbiyyat: Chart.ChartData = {};

  private chartDataKhilafat: Chart.ChartData = {};

  /**
   * chart style
   */
  private chartStyles = {
    height: "100%",
    width: "100%",
    margin: "auto"
  };

  private start() {
    this.allDataReports = [];
    this.allDataTarbiyyat = [];
    this.allDataKhilafat = [];
    // Initialize 30 data
    // How many lines should be there?
    this.allDataReports.push({label: "Waqfeen Berichte", data: []});

    this.allDataTarbiyyat.push({label: "Regelmäßig gebetet", data: []});
    this.allDataTarbiyyat.push({label: "Regelmäßig in Gemeinschaft gebetet", data: []});
    this.allDataTarbiyyat.push({label: "Regelmäßig in NC/Moschee gebetet", data: []});
    this.allDataTarbiyyat.push({label: "Quran regelmäßig rezitiert", data: []});
    this.allDataTarbiyyat.push({label: "An allen Freitagsgebeten teilgenommen", data: []});
    this.allDataTarbiyyat.push({label: "Alle Freitagsansprachen von Huzoor gehört", data: []});

    this.allDataKhilafat.push({label: "Nawafil für Khilafat regelmäßig verrichtet", data: []});
    this.allDataKhilafat.push({label: "Khilafat Gebete regelmäßig rezitiert", data: []});
    this.allDataKhilafat.push({label: "Wöchentlich gefastet ", data: []});

    this.updateDatasets();
  }

  created() {
    for(let i = 12; i > 0; i--) {
      this.xAxesLabels.push(this.getMonthForNumber(moment().subtract(i, 'M').month()));
    }
    this.loadReports();
  }

  /**
   * Update datasets
   */
  private updateDatasets() {

    // if we dont this, then the graphs will be jumping in months
    this.tableData = this.tableData.sort(function (a:any, b:any) {
      //@ts-ignore
      if (moment("01." + b.month + "." + b.year, "dd-MM-yyyy").isAfter(moment("01." + a.month + "." + a.year, "dd-MM-yyyy"))) return -1;
      if (moment("01." + b.month + "." + b.year, "dd-MM-yyyy").isBefore(moment("01." + a.month + "." + a.year, "dd-MM-yyyy"))) return 1;
      return 0;
    });

    // for each line
    this.tableData.forEach(td => {
      // We dont want current month in graph:
      if(!(+td.month == moment().month() +1 && +td.year == moment().year())) {
        this.allDataReports[0].data.push({
          x: this.getMonthForNumber(td.month - 1),
          y: td.totalFinishedReports
        });

        this.allDataTarbiyyat[0].data.push({
          x: this.getMonthForNumber(td.month - 1),
          y: td.prayersRegularObserved
        });

        this.allDataTarbiyyat[1].data.push({
          x: this.getMonthForNumber(td.month - 1),
          y: td.prayersObservedInCongregation
        });

        this.allDataTarbiyyat[2].data.push({
          x: this.getMonthForNumber(td.month - 1),
          y: td.prayersObservedInNcMosque
        });

        this.allDataTarbiyyat[3].data.push({
          x: this.getMonthForNumber(td.month - 1),
          y: td.quranRegularRecited
        });

        this.allDataTarbiyyat[4].data.push({
          x: this.getMonthForNumber(td.month - 1),
          y: td.fridayPrayersAttended
        });

        this.allDataTarbiyyat[5].data.push({
          x: this.getMonthForNumber(td.month - 1),
          y: td.fridaySermonsListened
        });

        this.allDataKhilafat[0].data.push({
          x: this.getMonthForNumber(td.month - 1),
          y: td.khilafatNawafilObserved
        });

        this.allDataKhilafat[1].data.push({
          x: this.getMonthForNumber(td.month - 1),
          y: td.khilafatPrayersRecited
        });

        this.allDataKhilafat[2].data.push({
          x: this.getMonthForNumber(td.month - 1),
          y: td.weeklyFasted
        });
      }
    });

    const dataset = (labelData: Data) => ({
      label: labelData.label,
      data: labelData.data,
      radius: 5
    });

    this.chartDataReports = {
      datasets: this.allDataReports.map(label => dataset(label))
    };

    this.chartDataTarbiyyat = {
      datasets: this.allDataTarbiyyat.map(label => dataset(label))
    };

    this.chartDataKhilafat = {
      datasets: this.allDataKhilafat.map(label => dataset(label))
    };
  }

  private reloadReports() {
    this.loadReports();
  }

  loadReports() {
    this.loadingReports = true;
    //@ts-ignore
    ReportService.getRegionalReports().then((response: any) => {
      this.tableData = response.data.organizationReportOverviewJsonList;
      this.maximumReports = response.data.maximumReports;
      this.loadingReports = false;
      this.start();
    }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: error,
        type: 'error'
      });
      this.loadingReports = false;
    }).finally(() => {
      setTimeout(() => {
        this.loadingReports = false;
      }, 5000);
    });
  }

  getMonthForNumber(month: number): string {
    switch (month) {
      case 0:
        return "Jan";
      case 1:
        return "Feb";
      case 2:
        return "Mär";
      case 3:
        return "Apr";
      case 4:
        return "Mai";
      case 5:
        return "Jun";
      case 6:
        return "Jul";
      case 7:
        return "Aug";
      case 8:
        return "Sep";
      case 9:
        return "Okt";
      case 10:
        return "Nov";
      case 11:
        return "Dez";
      default:
        return "";
    }
  }

  getNumberForMonth(month: string): number {
    switch (month) {
      case "Januar":
        return 0;
      case "Februar":
        return 1;
      case "März":
        return 2;
      case "April":
        return 3;
      case "Mai":
        return 4;
      case "Juni":
        return 5;
      case "Juli":
        return 6;
      case "August":
        return 7;
      case "September":
        return 8;
      case "Oktober":
        return 9;
      case "November":
        return 10;
      case "Dezember":
        return 11;
      default:
        return -1;
    }
  }

  private openLocalReport(selectedEntry: any) {
    //@ts-ignore
    this.$router.push({
      name: 'ViewRegionalReport',
      params: {
        month: selectedEntry.month,
        year: selectedEntry.year
      }
    });
  }
}
