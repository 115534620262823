







































































































































import Vue from 'vue';
import {Component, Watch} from "vue-property-decorator";
import {router} from './router';
import {Getter} from 'vuex-class';
import {Useraccount} from "./models/useraccount.model";
import {OwnMetaData} from "./modules/tajneed/tajneed.types";
import moment from 'moment';
import {performLogout} from "./utils/utils";

@Component({
  components: {},
})
export default class App extends Vue {

  private drawer = null;
  private menuItems: any[] = [];

  @Getter('getUseraccount', {namespace: 'useraccount'})
  private getUseraccount!: Useraccount;

  @Getter('getOwnMetaData', {namespace: 'accountmetadata'})
  private getOwnMetaData!: OwnMetaData;

  private options = [
    {
      icon: 'mdi-cog',
      text: 'Einstellungen',
      callback: this.redirectToSettings,
    },
    {
      icon: 'mdi-logout',
      text: 'Logout',
      callback: this.logout,
    },
  ];

  @Watch('getOwnMetaData')
  onPropertyChange(value: any, oldValue: any) {
    this.loadMenu();
  }

  beforeUpdate() {
    this.loadMenu();
  }

  private loadMenu() {
    this.menuItems = [];
    if (this.getUseraccount) {
      if (this.getUseraccount.isNationalJamaatUser) {
        this.menuItems.push({icon: 'mdi-text-account', text: 'Tajneed'});
        this.menuItems[0]["children"] = [
          {icon: 'mdi-human-male-female', text: 'Alle', name: 'TajneedTableAll'},
          {icon: 'mdi-human-male', text: 'Waqifin', name: 'TajneedTableWaqifin'},
          {icon: 'mdi-human-female', text: 'Waqifat', name: 'TajneedTableWaqifat'},
          {icon: 'mdi-check-bold', text: 'Genehmigungen', name: 'ApprovalsWaqifin'},
          {icon: 'mdi-archive', text: 'Archiv', name: 'archive'}
        ];
        this.menuItems.push({icon: 'mdi-application-cog', text: 'Administration'});
        this.menuItems[1]["children"] = [
          {icon: 'mdi-account-switch', text: 'Accounts', name: 'ViewAccounts'},
          {icon: 'mdi-target-variant', text: 'Registrationen', name: 'ViewRegistrationsTable'},
          {icon: 'mdi-database-outline', text: 'Stammdaten', name: 'ViewBasedata'},
          {icon: 'mdi-file-percent-outline', text: 'Analyse', name: 'ViewAnalysisCoverage'},
           {icon: 'mdi-tools', text: 'Konfiguration', name: 'ViewConfiguration'}
        ];
        this.menuItems.push({icon: 'mdi-table-large', text: 'Berichte'});
        this.menuItems[2]["children"] = [
          {icon: 'mdi-table-large', text: 'Individual Berichte', name: 'ViewTableIndividualReportsGroup'},
          {icon: 'mdi-table-large', text: 'Lokale Berichte', name: 'ViewTableLocalReportsGroup'},
          {icon: 'mdi-table-large', text: 'Regional Berichte', name: 'ViewTableRegionalReportsGroup'},
          {icon: 'mdi-table-large', text: 'National Berichte', name: 'ViewTableNationalReports'},
          {icon: 'mdi-table-large', text: 'Bericht an Huzoor-e-Aqdas', name: 'ViewReportToHuzoor'}
        ];
        this.menuItems.push({icon: 'mdi-list-status', text: 'Jaiza'});
        this.menuItems[3]["children"] = [
          {icon: 'mdi-list-status', text: 'Jaiza', name: 'ViewJaizasGroup'},
          {icon: 'mdi-file-percent-outline', text: 'Jaiza-Analyse', name: 'ViewAnalysisJaiza'}
        ]
        this.menuItems.push({icon: 'mdi-school', text: 'Prüfung', name: 'ViewExamsGroup'});
        this.menuItems.push({icon: 'mdi-human-male-boy', text: 'Elternberatung'});
        this.menuItems[5]["children"] = [
          {icon: 'mdi-account-multiple', text: 'Murabbiyan', name: 'ViewMissionariesList'},
          {icon: 'mdi-format-list-checkbox', text: 'Beratung', name: 'ViewParentsCounsellingCases'}
        ]
        this.menuItems.push({icon: 'mdi-email-edit-outline', text: 'Neue Email verfassen', name: 'ViewWriteEmail'});
      }
      if (this.getUseraccount.isNationalLajnaUser) {
        this.menuItems.push({icon: 'mdi-human-female', text: 'Waqifat', name: 'TajneedTableWaqifat'});
        this.menuItems.push({icon: 'mdi-table-large', text: 'Berichte'});
        this.menuItems[1]["children"] = [
          {icon: 'mdi-table-large', text: 'Individual Berichte', name: 'ViewTableIndividualReportsGroup'},
          {icon: 'mdi-table-large', text: 'Lokale Berichte', name: 'ViewTableLocalReportsGroup'},
          {icon: 'mdi-table-large', text: 'Regional Berichte', name: 'ViewTableRegionalReportsGroup'},
          {icon: 'mdi-table-large', text: 'National Berichte', name: 'ViewTableNationalReports'}
        ];
        this.menuItems.push({icon: 'mdi-list-status', text: 'Jaiza', name: 'ViewJaizasGroup'});
        this.menuItems.push({icon: 'mdi-file-percent-outline', text: 'Jaiza-Analyse', name: 'ViewAnalysisJaiza'});
        this.menuItems.push({icon: 'mdi-file-percent-outline', text: 'Analyse', name: 'ViewAnalysisCoverage'});
        this.menuItems.push({icon: 'mdi-school', text: 'Prüfung', name: 'ViewExamsGroup'});
        this.menuItems.push({icon: 'mdi-application-cog', text: 'Administration'});
        this.menuItems[6]["children"] = [
          {icon: 'mdi-database-outline', text: 'Stammdaten', name: 'ViewBasedata'},
        ];
      }
      if (this.getUseraccount.isNaibAmirUser) {
        this.menuItems.push({icon: 'mdi-text-account', text: 'Tajneed'});
        this.menuItems[0]["children"] = [
          {icon: 'mdi-human-male-female', text: 'Alle', name: 'TajneedTableAll'},
          {icon: 'mdi-human-male', text: 'Waqifin', name: 'TajneedTableWaqifin'},
          {icon: 'mdi-human-female', text: 'Waqifat', name: 'TajneedTableWaqifat'},
          {icon: 'mdi-check-bold', text: 'Genehmigungen', name: 'ApprovalsWaqifin'},
          {icon: 'mdi-archive', text: 'Archiv', name: 'archive'}
        ];
      }
      if (this.getUseraccount.isLocalJamaatUser) {
        this.menuItems.push({icon: 'mdi-human-male', text: 'Waqifin-e-Nau', name: 'TajneedTableWaqifin'});
        this.menuItems.push({icon: 'mdi-chart-bar', text: 'Lokale Berichte', name: 'ViewTableLocalReports'});
        this.menuItems.push({icon: 'mdi-table-large', text: 'Individual Berichte', name: 'ViewTableIndividualReportsGroup'});
        this.menuItems.push({icon: 'mdi-list-status', text: 'Jaiza', name: 'ViewJaizasGroup'});
        this.menuItems.push({icon: 'mdi-badge-account-outline', text: 'Judges', name: 'ViewJudges'});
        this.menuItems.push({icon: 'mdi-school', text: 'Prüfung', name: 'ViewExamsGroup'});
      }
      if (this.getUseraccount.isLocalLajnaUser) {
        this.menuItems.push({icon: 'mdi-human-male', text: 'Waqifat-e-Nau', name: 'TajneedTableWaqifat'});
        this.menuItems.push({icon: 'mdi-chart-bar', text: 'Lokale Berichte', name: 'ViewTableLocalReports'});
        this.menuItems.push({icon: 'mdi-table-large', text: 'Individual Berichte', name: 'ViewTableIndividualReportsGroup'});
        this.menuItems.push({icon: 'mdi-list-status', text: 'Jaiza', name: 'ViewJaizasGroup'});
        this.menuItems.push({icon: 'mdi-badge-account-outline', text: 'Judges', name: 'ViewJudges'});
        this.menuItems.push({icon: 'mdi-school', text: 'Prüfung', name: 'ViewExamsGroup'});
      }
      if (this.getUseraccount.isRegionalJamaatUser) {
        this.menuItems.push({icon: 'mdi-human-male', text: 'Waqifin-e-Nau', name: 'TajneedTableWaqifin'});
        this.menuItems.push({icon: 'mdi-table-large', text: 'Regional Berichte', name: 'ViewTableRegionalReports'});
        this.menuItems.push({icon: 'mdi-table-large', text: 'Lokale Berichte', name: 'ViewTableLocalReportsGroup'});
        this.menuItems.push({icon: 'mdi-list-status', text: 'Jaiza', name: 'ViewJaizasGroup'});
        this.menuItems.push({icon: 'mdi-badge-account-outline', text: 'Judges', name: 'ViewJudgesRegion'});
        this.menuItems.push({icon: 'mdi-school', text: 'Prüfung', name: 'ViewExamsGroup'});
      }
      if (this.getUseraccount.isRegionalLajnaUser) {
        this.menuItems.push({icon: 'mdi-human-male', text: 'Waqifat-e-Nau', name: 'TajneedTableWaqifat'});
        this.menuItems.push({icon: 'mdi-table-large', text: 'Regional Berichte', name: 'ViewTableRegionalReports'});
        this.menuItems.push({icon: 'mdi-table-large', text: 'Lokale Berichte', name: 'ViewTableLocalReportsGroup'});
        this.menuItems.push({icon: 'mdi-list-status', text: 'Jaiza', name: 'ViewJaizasGroup'});
        this.menuItems.push({icon: 'mdi-badge-account-outline', text: 'Judges', name: 'ViewJudgesRegion'});
        this.menuItems.push({icon: 'mdi-school', text: 'Prüfung', name: 'ViewExamsGroup'});
      }
      if (this.getUseraccount.isJudgeUser) {
        this.menuItems.push({icon: 'mdi-list-status', text: 'Jaiza', name: 'ViewJaizasGroup'});
      }
      if (this.getUseraccount.isNationalAssistantJamaatUser) {
        if (this.getUseraccount.getUsername.indexOf("berichte") >= 0) {
          this.menuItems.push({icon: 'mdi-table-large', text: 'Lokale Berichte', name: 'ViewTableLocalReportsGroup'});
          this.menuItems.push({icon: 'mdi-table-large', text: 'Regional Berichte', name: 'ViewTableRegionalReportsGroup'});
        } else if (this.getUseraccount.getUsername.indexOf("jaiza") >= 0) {
          this.menuItems.push({icon: 'mdi-list-status', text: 'Jaiza', name: 'ViewJaizasGroup'});
          this.menuItems.push({icon: 'mdi-file-percent-outline', text: 'Jaiza-Analyse', name: 'ViewAnalysisJaiza'});
        } else {
          this.menuItems.push({icon: 'mdi-file-percent-outline', text: 'Analyse', name: 'ViewAnalysisCoverage'});
        }
      }
      if (this.getUseraccount.isNationalAssistantLajnaUser) {
        if (this.getUseraccount.getUsername.indexOf("berichte") >= 0) {
          this.menuItems.push({icon: 'mdi-table-large', text: 'Lokale Berichte', name: 'ViewTableLocalReportsGroup'});
          this.menuItems.push({icon: 'mdi-table-large', text: 'Regional Berichte', name: 'ViewTableRegionalReportsGroup'});
        } else if (this.getUseraccount.getUsername.indexOf("jaiza") >= 0) {
          this.menuItems.push({icon: 'mdi-list-status', text: 'Jaiza', name: 'ViewJaizasGroup'});
          this.menuItems.push({icon: 'mdi-file-percent-outline', text: 'Jaiza-Analyse', name: 'ViewAnalysisJaiza'});
        } else {
          this.menuItems.push({icon: 'mdi-human-female', text: 'Waqifat', name: 'TajneedTableWaqifat'});
          this.menuItems.push({icon: 'mdi-table-large', text: 'Individual Berichte', name: 'ViewTableIndividualReportsGroup'});
          this.menuItems.push({icon: 'mdi-table-large', text: 'Regional Berichte', name: 'ViewTableRegionalReportsGroup'});
          this.menuItems.push({icon: 'mdi-table-large', text: 'Lokale Berichte', name: 'ViewTableLocalReportsGroup'});
          this.menuItems.push({icon: 'mdi-table-large', text: 'National Berichte', name: 'ViewTableNationalReports'});
          this.menuItems.push({icon: 'mdi-list-status', text: 'Jaiza', name: 'ViewJaizasGroup'});
          this.menuItems.push({icon: 'mdi-file-percent-outline', text: 'Jaiza-Analyse', name: 'ViewAnalysisJaiza'});
          this.menuItems.push({icon: 'mdi-file-percent-outline', text: 'Analyse', name: 'ViewAnalysisCoverage'});
        }
      }
      if (this.getUseraccount.isMissionaryUser) {
        this.menuItems.push({icon: 'mdi-text-account', text: 'Tajneed'});
        this.menuItems[0]["children"] = [
          {icon: 'mdi-human-male', text: 'Waqifin', name: 'TajneedTableWaqifin'},
        ];
        this.menuItems.push({icon: 'mdi-list-status', text: 'Jaiza', name: 'ViewJaizasGroup'});
        this.menuItems.push({icon: 'mdi-school', text: 'Prüfung', name: 'ViewExamsGroup'});
        this.menuItems.push({icon: 'mdi-table-large', text: 'Individual Berichte', name: 'ViewTableIndividualReportsGroup'});
        this.menuItems.push({icon: 'mdi-table-large', text: 'Lokale Berichte', name: 'ViewTableLocalReportsGroup'});
        this.menuItems.push({icon: 'mdi-table-large', text: 'Murabbi Berichte'});
        if (this.getUseraccount.getMurabbiJamaats != null && this.getUseraccount.getMurabbiJamaats.length > 0) {
          let jamaatsMenuEntries = [];
          for (let jamaat of this.getUseraccount.getMurabbiJamaats) {
            jamaatsMenuEntries.push({
              icon: 'mdi-city',
              text: jamaat,
              path: '/bericht/tabelle/murabbi/' + jamaat
            });
          }
          this.menuItems[5]["children"] = jamaatsMenuEntries;
        }
        this.menuItems.push({icon: 'mdi-human-male-boy', text: 'Elternberatung'});
        this.menuItems[6]["children"] = [
          {icon: 'mdi-format-list-checkbox', text: 'Beratung', name: 'ViewParentsCounsellingCases'}
        ];
        this.menuItems.push({icon: 'mdi-numeric-1-circle-outline', text: '1. Zusage fehlend', name: 'FirstConfirmationMissingTableView'});
        this.menuItems.push({icon: 'mdi-numeric-2-circle-outline', text: '2. Zusage fehlend', name: 'SecondConfirmationMissingTableView'});
      }
      if (this.getUseraccount.isMissionaryInchargeUser) {
        this.menuItems.push({icon: 'mdi-human-male-boy', text: 'Elternberatung'});
        this.menuItems[0]["children"] = [
          {icon: 'mdi-account-multiple', text: 'Murabbiyan', name: 'ViewMissionariesList'},
          {icon: 'mdi-format-list-checkbox', text: 'Beratung', name: 'ViewParentsCounsellingCases'}
        ]
      }
      if (this.getUseraccount.isNormalUser) {
        this.menuItems.push({icon: 'mdi-view-dashboard', text: 'Dashboard', name: 'ViewDashboardPersons'});
        let parentHasTajneed = false;
        if (this.getOwnMetaData.ownMetaData) {
          if (Object.keys(this.getOwnMetaData.ownMetaData).length > 0) {
            parentHasTajneed = true;
            this.menuItems.push({
              icon: 'mdi-account',
              text: this.getOwnMetaData.ownMetaData.firstname + ' ' + this.getOwnMetaData.ownMetaData.lastname,
              disabled: false
            });
            this.menuItems[1]["children"] = [];
            this.menuItems[1]["children"].push(
              {
                icon: 'list',
                text: 'Tajneed',
                name: 'TajneedForm',
                params: {tajneedId: this.getOwnMetaData.ownMetaData.id, isOwnTajneed: true},
              },
            );
            if (moment().diff(this.getOwnMetaData.ownMetaData.dateOfBirth, 'years') >= 7) {
              this.menuItems[1]["children"].push(
                {
                  icon: 'mdi-table-large',
                  text: 'Bericht',
                  name: 'ViewTableIndividualReports',
                  params: {tajneedId: this.getOwnMetaData.ownMetaData.id, personId: this.getOwnMetaData.ownMetaData.personId},
                },
              );
            }
            this.menuItems[1]["children"].push(
              {
                icon: 'mdi-list-status',
                text: 'Jaiza',
                name: 'ViewTableJaizas',
                params: {personId: this.getOwnMetaData.ownMetaData.personId, dateOfBirth: this.getOwnMetaData.ownMetaData.dateOfBirth},
              },
            );
            this.menuItems[1]["children"].push(
              {
                icon: 'mdi-folder-open-outline',
                text: 'Dokumente',
                name: 'ViewDocuments',
                params: {personId: this.getOwnMetaData.ownMetaData.personId},
              },
            );
            this.menuItems[1]["children"].push(
              {
                icon: 'mdi-notebook',
                text: 'Tagebuch',
                name: 'ViewDiary',
                params: {personId: this.getOwnMetaData.ownMetaData.personId},
              },
            );
            this.menuItems[1]["children"].push(
              {
                icon: 'mdi-chart-line',
                text: 'Entwicklung',
                name: 'ViewIndividualDevelopment',
                params: {personId: this.getOwnMetaData.ownMetaData.personId},
              },
            );
            if (moment().diff(this.getOwnMetaData.ownMetaData.dateOfBirth, 'years') >= 15) {
              this.menuItems[1]["children"].push(
                {
                  icon: 'mdi-school',
                  text: 'Prüfung',
                  name: 'ViewTableExamsIndividual',
                  params: {personId: this.getOwnMetaData.ownMetaData.personId, dateOfBirth: this.getOwnMetaData.ownMetaData.dateOfBirth},
                },
              );
            }
          }
        }
        if (this.getOwnMetaData.childrenMetaData.length > 0) {
          let startIndex = parentHasTajneed ? 2 : 1;
          for (let child of this.getOwnMetaData.childrenMetaData) {
            this.menuItems.push({
              icon: 'mdi-account',
              text: child.firstname + ' ' + child.lastname,
              disabled: moment().diff(child.dateOfBirth, 'years') >= 16 ? true : false,
              children: []
            });
            this.menuItems[startIndex]["children"].push(
              {
                icon: 'list',
                text: 'Tajneed',
                name: 'TajneedForm',
                params: {tajneedId: child.id, isChild: true},
              },
            );
            if (moment().diff(child.dateOfBirth, 'years') >= 7) {
              this.menuItems[startIndex]["children"].push(
                {
                  icon: 'mdi-table-large',
                  text: 'Bericht',
                  name: 'ViewTableIndividualReports',
                  params: {tajneedId: child.id, personId: child.personId},
                },
              );
              this.menuItems[startIndex]["children"].push(
                {
                  icon: 'mdi-notebook',
                  text: 'Tagebuch',
                  name: 'ViewDiary',
                  params: {personId: child.personId},
                },
              );
              this.menuItems[startIndex]["children"].push(
                {
                  icon: 'mdi-chart-line',
                  text: 'Entwicklung',
                  name: 'ViewIndividualDevelopment',
                  params: {personId: child.personId},
                },
              );
            }
            this.menuItems[startIndex]["children"].push(
              {
                icon: 'mdi-list-status',
                text: 'Jaiza',
                name: 'ViewTableJaizas',
                params: {personId: child.personId, dateOfBirth: child.dateOfBirth},
              },
            );
            this.menuItems[startIndex]["children"].push(
              {
                icon: 'mdi-folder-open-outline',
                text: 'Dokumente',
                name: 'ViewDocuments',
                params: {personId: child.personId},
              },
            );
            if (moment().diff(child.dateOfBirth, 'years') >= 15) {
              this.menuItems[1]["children"].push(
                {
                  icon: 'mdi-school',
                  text: 'Prüfung',
                  name: 'ViewDiary',
                  params: {personId: this.getOwnMetaData.ownMetaData.personId, dateOfBirth: this.getOwnMetaData.ownMetaData.dateOfBirth},
                },
              );
            }
            startIndex++;
          }
        }
      }

      this.menuItems.push({icon: 'mdi-folder-download', text: 'Downloads', name: 'ViewDownloads'});
      this.menuItems.push({icon: 'mdi-cog', text: 'Account Einstellungen', name: 'ViewSettings'});
    }
  }

  private logout() {
    performLogout();
    router.push({name: 'login'});
  }

  private redirectToSettings() {
    router.push({name: 'ViewSettings'});

  }

}
