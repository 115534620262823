


































import { Component, Prop, Vue } from 'vue-property-decorator';

import AppCard from './../../../core/components/cards/AppCard.vue'
import DownloadsService from './../downloads.service';
import {FileReadUtils} from "../../../utils/filereadutils";

@Component({
  components: { AppCard },
})
export default class DownloadUrduCardButton extends Vue {
  @Prop({ default: 'mdi-card-outline', required: false })
  iconOne!: string;

  @Prop({ default: 'mdi-card-outline', required: false })
  iconTwo!: string;

  @Prop({ default: null, required: false })
  level!: string;

  redirect() {
    DownloadsService.getUrduForLevel(this.level).then((response: any) => {
      FileReadUtils.openSaveAs(response.data.document, "Urdu-" + this.level + ".pdf");
    })
  }
}

