























































































import {Component, Prop, Vue} from "vue-property-decorator";
import ParentsCouncellingService from '../parents-councelling.service';
import LoadingOverlay from "../../../core/components/LoadingOverlay.vue"
import parentsCouncellingService from "../parents-councelling.service";
import {Missionary} from "../parents-councelling.types";

@Component({
  components: {LoadingOverlay},
})
export default class CreateNewMissionaryEntryDialog extends Vue {

  @Prop({default: null})
  selectedMissionary: Missionary;

  private showOverlay = false;
  private newJamaatHalqaName: string = '';
  private jamaatItems: string[] = [];
  private valid: boolean = true;
  private selectedJamaats: string[] = [];

  private rules = {
    required: (value: any) => !!value || 'Pflichtfeld',
    dateFormat: (v: string) => /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)\d\d$/.test(v) || 'TT.MM.JJJJ Format',
    digitsOnly: (v: string) => /^(\s*|\d+)$/.test(v) || 'Jamaat-ID darf nur Zahlen beinhalten.',
  };

  private emailRules = [
    (v: string) => !!v || 'Feld muss ausgefüllt sein',
    (v: any) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-Mail überprüfen',
  ]

  private missionary: Missionary = {};

  created() {
    this.loadJamaats();
  }

  private loadJamaats() {
    ParentsCouncellingService.getJamaatsWithoutMissionary().then((response: any) => {
      this.jamaatItems = response.data.map((a: any) => a.name);
      if (this.selectedMissionary) {
        this.jamaatItems = [ ...this.jamaatItems, ...this.selectedMissionary.jamaats!];
        this.missionary = JSON.parse(JSON.stringify(this.selectedMissionary));
      }
    }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: error,
        type: 'error'
      });
    }).finally(() => {
      setTimeout(() => {
      }, 5000);
    });
  }

  createNewMissionaryEntry() {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.showOverlay = true;
      if (this.selectedMissionary) {
        ParentsCouncellingService.updateMissionaryEntry(this.missionary)
        .then((response: any) => {
          this.showOverlay = false;
          this.$notify({
            group: 'foo',
            title: 'Info',
            text: 'Eintrag wurde erfolgreich bearbeitet',
            type: 'success'
          });
          this.$emit('success');
        })
        .catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text:  error.response!.data.message,
            type: 'error'
          });
          this.showOverlay = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.showOverlay = false;
          }, 5000);
        });
      } else {
        ParentsCouncellingService.createNewMissionaryEntry(this.missionary)
        .then((response: any) => {
          this.showOverlay = false;
          this.$notify({
            group: 'foo',
            title: 'Info',
            text: 'Eintrag wurde erfolgreich angelegt',
            type: 'success'
          });
          this.$emit('success');
        })
        .catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text:  error.response!.data.message,
            type: 'error'
          });
          this.showOverlay = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.showOverlay = false;
          }, 5000);
        });
      }
    }
  }

}
