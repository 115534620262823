import httpResource from "../../http/httpResource";
import {DiaryEntry} from "@/modules/diary/diary.types";

const API_URL = process.env.VUE_APP_API_URL + '/api/diary';

class DiaryService {

  getDiaryEntry(date: string, personId: string) {
    return httpResource.get(API_URL+ '/entry/' + date + '/' + personId);
  }

  saveDiaryEntry(personId: string, diaryEntry: DiaryEntry) {
    return httpResource.post(API_URL+ '/entry/' + personId, diaryEntry );
  }
}

export default new DiaryService();
