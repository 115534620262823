import httpResource from "../../http/httpResource";
import {Missionary, ParentsCounsellingCase} from "@/modules/parents-councelling/parents-councelling.types";

const API_URL = process.env.VUE_APP_API_URL + '/api/parents-counselling-case-attachment/';

class ParentsCounsellingAttachmentService {

  getMetaForAttachments(requestId: any) {
    return httpResource.get(API_URL + 'getmetaforattachments/' + requestId);
  }

  downloadAttachment(id: any) {
    return httpResource.get(API_URL + 'getattachment/' + id);
  }
  
}

export default new ParentsCounsellingAttachmentService();
