















































































































































































































































import {Component, Vue} from "vue-property-decorator";
import LoadingOverlay from "../../../core/components/LoadingOverlay.vue"
import AppLoader from "../../../core/components/AppLoader.vue"
import {Getter} from "vuex-class";
import {Useraccount} from "../../../models/useraccount.model";
import JaizaService from '../jaiza.service';
import moment from 'moment';
import TableButton from '../../../core/components/TableButton.vue';
import BasedataService from "../../../modules/basedata/basedata.service"
import {FileReadUtils} from "../../../utils/filereadutils";

@Component({
  components: {AppLoader, LoadingOverlay, TableButton}
})
export default class ViewJaizasGroup extends Vue {
  @Getter('getUseraccount', {namespace: 'useraccount'})
  private getUseraccount!: Useraccount;

  jaizas: any[] = [];

  private nameFilterValue = "";

  headers = [
    {text: 'Name', value: 'name'},
    {text: 'Judge', value: 'lastJaizaJudge'},
  ];

  private loadingReports: boolean = false;

  private loadingSearchData: boolean = false;

  private jamaatFilterItems: string[] = [];

  private majlisFilterItems: string[] = [];

  private statusFilterItems: string[] = ['CREATED', 'SAVED', 'SENT_BY_WAQIFIN', 'SAVED_BY_JUDGE', 'SENT_BY_JUDGE', 'SENT_BY_LOCAL_SECRETARY', 'SENT_BY_LOCAL_MUAWINA'];

  private statusItemsForResultForLessThenSixYears: string[] = ['SAVED', 'SAVED_BY_JUDGE', 'SENT_BY_JUDGE', 'SENT_BY_LOCAL_SECRETARY', 'SENT_BY_LOCAL_MUAWINA', 'SENT_BY_REGIONAL_SECRETARY', 'SENT_BY_REGIONAL_MUAWINA', 'SAVED_BY_NATIONAL_JAMAAT', 'SENT_BY_NATIONAL_JAMAAT', 'SAVED_BY_NATIONAL_LAJNA', 'SENT_BY_NATIONAL_LAJNA'];

  private statusItemsForResultForOlderThenSixYears: string[] = ['SAVED_BY_JUDGE', 'SENT_BY_JUDGE', 'SENT_BY_LOCAL_SECRETARY', 'SENT_BY_LOCAL_MUAWINA', 'SENT_BY_REGIONAL_SECRETARY', 'SENT_BY_REGIONAL_MUAWINA', 'SAVED_BY_NATIONAL_JAMAAT', 'SENT_BY_NATIONAL_JAMAAT', 'SAVED_BY_NATIONAL_LAJNA', 'SENT_BY_NATIONAL_LAJNA'];

  private jamaatFilterValue: string = '';

  private majlisFilterValue: string = '';

  private statusFilterValue: string = '';

  private waqfenauIdFilterValue: string = '';

  private aimsIdFilterValue: string = '';

  private lastJaizaFinishDate: string = '';

  private years: any[] = [];

  private page: number = 1;
  private totalPages: number = 0;
  private pageSize: number = 30;

  private pageSizes = [10, 30, 90];

  private legendLabels = [
    { text: "Die Eltern der Waqifin geben das Jaiza im persönlichen Portal ein.", color: "grey" },
    { text: "Judge hat Jaiza gesendet", color: "yellow" },
    { text: "Jaiza wurde an die Zentrale gesendet", color: "limegreen" }
  ];

  private isResultAvailable(item: any, year: any): boolean {
    if (!this.loadingReports) {
      // For all older jayzas jsut show result:
      if (year < Math.max(...this.years.map(y => y.year).map(Number))) {
        return true;
      }
      let ageAsOfJayzaNisabStartDate = moment(Math.max(...item.years.map((o: any) => o.year)) + "-09-01").diff(moment(item.dateOfBirth), 'years');
      if (moment().isSameOrAfter(this.lastJaizaFinishDate)
        && item.years.filter((yearInItem: any) => yearInItem.year == year && (this.statusItemsForResultForOlderThenSixYears.includes(yearInItem.status) 
          || (ageAsOfJayzaNisabStartDate < 6 && this.statusItemsForResultForLessThenSixYears.includes(yearInItem.status)))).length == 1) {
        return true;
      }
    }
    return false;
  }

  private getRequestParams(jamaatFilterValue: string, majlisFilterValue: string, statusFilterValue: string, waqfenauIdFilterValue: string, aimsIdFilterValue: string, page: number, pageSize: number) {
    let params = {};

    if (jamaatFilterValue) {
      //@ts-ignore
      params["jamaatString"] = jamaatFilterValue;
    }

    if (majlisFilterValue) {
      //@ts-ignore
      params["majlisString"] = majlisFilterValue;
    }

    if (statusFilterValue) {
      //@ts-ignore
      params["status"] = statusFilterValue;
    }

    if (waqfenauIdFilterValue) {
      //@ts-ignore
      params["waqfenauId"] = waqfenauIdFilterValue;
    }

    if (aimsIdFilterValue) {
      //@ts-ignore
      params["aimsId"] = aimsIdFilterValue;
    }

    if (page) {
      //@ts-ignore
      params["page"] = page - 1;
    }

    if (pageSize) {
      //@ts-ignore
      params["size"] = pageSize;
    }

    return params;
  }


  created() {
    this.loadingSearchData = true;
    BasedataService.getJamaatAndMajalisForUser()
      .then((response: any) => {
        this.jamaatFilterItems = response.data.jamaats;
        this.majlisFilterItems = response.data.majalis;
        this.loadingSearchData = false;
        this.loadJaizas();
      })
      .catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: 'Netzwerkfehler',
          type: 'error'
        });
      })
      .finally(() => {
        setTimeout(() => {
        }, 5000);
      });

    //static !?
    for (let i = 2021; i <= 2024; i++) {
      this.headers.push({
        text: i.toString(),
        value: '',
      });
      this.years.push({
        year: i.toString(),
      })
    }
  }

  private getJaizaIsPresent(item: any, year: string): boolean {
    if (item.years.filter((yearInItem: any) => yearInItem.year == year).length == 1) {
      return true;
    }
    return false;
  }

  private reloadJaizas() {
    this.loadJaizas();
  }

  private handlePageChange(value: any) {
    this.page = value;
    this.loadJaizas();
  }

  handlePageSizeChange(size: any) {
    this.pageSize = size;
    this.page = 1;
    this.loadJaizas();
  }

  private loadJaizas() {
    const params = this.getRequestParams(
      this.jamaatFilterValue,
      this.majlisFilterValue,
      this.statusFilterValue,
      this.waqfenauIdFilterValue,
      this.aimsIdFilterValue,
      this.page,
      this.pageSize
    );
    this.loadingReports = true;
    //@ts-ignore
    JaizaService.getJaizasForGroup(params).then((response: any) => {
      const {jaizas, totalPages} = response.data;
      this.jaizas = jaizas;
      this.totalPages = totalPages;

      this.jaizas = this.jaizas.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.personId === value.personId
        ))
      )
      this.loadingReports = false;
      this.lastJaizaFinishDate = response.data.lastJaizaFinishDate;
    }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: error,
        type: 'error'
      });
      this.loadingReports = false;
    }).finally(() => {
      setTimeout(() => {
        this.loadingReports = false;
      }, 5000);
    });
  }

  private search() {
    this.loadJaizas()
  }

  private nameFilter(valueInRow: any) {
    if (!this.nameFilterValue) {
      return true;
    }
    else if (!valueInRow) {
      return false;
    }
    else {
      return valueInRow.toString().toUpperCase().indexOf(this.nameFilterValue.toUpperCase()) >= 0;
    }
  }

  openJaiza(selectedEntry: any, year: string) {
    //@ts-ignore
    this.$router.push({
      name: 'ViewJaiza',
      params: {
        personId: selectedEntry.personId.toString(),
        year: year,
        dateOfBirth: selectedEntry.dateOfBirth,
      }
    });
  }


  openResult(selectedEntry: any, year: string) {
    JaizaService.getResultPdf(selectedEntry.personId.toString(), year).then((response: any) => {
      FileReadUtils.openSaveAs(response.data.document, 'Jaiza.pdf');
    });
  }

  restoreJaiza(selectedEntry: any, year: string) {
    JaizaService.restoreJaiza(selectedEntry.personId.toString(), year)
    .then((response: any) => {
      this.loadJaizas();
      this.$notify({
        group: 'foo',
        title: 'Info',
        text: response.data,
        type: 'success'
      });
    })
    .catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: error.response!.data.message,
        type: 'error'
      });
    })
    .finally(() => {
      setTimeout(() => {
      }, 5000);
    });
  }

  printAllResults() {
    JaizaService.getAllResultPdf().then((response: any) => {
      FileReadUtils.openSaveAsZip(response.data.document, 'test.zip');
    });
  }

  customRowClass(item: any) {
    let ageAsOfFirstJayzaNisabDate = moment(Math.max(...item.years.map((o: any) => o.year)) + "-09-01").diff(moment(item.dateOfBirth), 'years');
    if(ageAsOfFirstJayzaNisabDate < 6) {
      return 'grey-bg';
    }

    else if (item.lastJaizaStatus == 'SENT_BY_JUDGE') {
      return 'yellow-bg';
    }
    else if (item.lastJaizaStatus == 'SENT_BY_JUDGE') {
      return 'yellow-bg';
    }
    else if (item.lastJaizaStatus === 'SENT_BY_LOCAL_SECRETARY') {
      return 'green-bg';
    }
    else if (item.lastJaizaStatus === 'SENT_BY_LOCAL_MUAWINA') {
      return 'green-bg';
    }
    else if (item.lastJaizaStatus === 'SENT_BY_REGIONAL_SECRETARY') {
      return 'green-bg';
    }
    else if (item.lastJaizaStatus === 'SENT_BY_REGIONAL_MUAWINA') {
      return 'green-bg';
    }
    else if (item.lastJaizaStatus === 'SENT_BY_NATIONAL_JAMAAT') {
      return 'green-bg';
    }
    else if (item.lastJaizaStatus === 'SENT_BY_NATIONAL_LAJNA') {
      return 'green-bg';
    }
    else {
      return '';
    }
  }

  getMonthForNumber(month: number): string {
    switch (month) {
      case 0:
        return "Jan";
      case 1:
        return "Feb";
      case 2:
        return "Mär";
      case 3:
        return "Apr";
      case 4:
        return "Mai";
      case 5:
        return "Jun";
      case 6:
        return "Jul";
      case 7:
        return "Aug";
      case 8:
        return "Sep";
      case 9:
        return "Okt";
      case 10:
        return "Nov";
      case 11:
        return "Dez";
      default:
        return "";
    }
  }

  getNumberForMonth(month: string): number {
    switch (month) {
      case "Januar":
        return 0;
      case "Februar":
        return 1;
      case "März":
        return 2;
      case "April":
        return 3;
      case "Mai":
        return 4;
      case "Juni":
        return 5;
      case "Juli":
        return 6;
      case "August":
        return 7;
      case "September":
        return 8;
      case "Oktober":
        return 9;
      case "November":
        return 10;
      case "Dezember":
        return 11;
      default:
        return -1;
    }
  }
}
