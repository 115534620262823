class StaticData {

  getAllCountries() {
    return [
      "Afghanistan",
      "Ägypten",
      "Albanien",
      "Algerien",
      "Andorra",
      "Angola",
      "Antigua und Barbuda",
      "Äquatorialguinea",
      "Argentinien",
      "Armenien",
      "Aserbaidschan",
      "Äthiopien",
      "Australien",
      "Bahamas",
      "Bahrain",
      "Bangladesch",
      "Barbados",
      "Belarus",
      "Belgien",
      "Belize",
      "Benin",
      "Bhutan",
      "Bolivien, Plurinationaler Staat",
      "Bosnien und Herzegowina",
      "Botsuana",
      "Brasilien",
      "Brunei Darussalam",
      "Bulgarien",
      "Burkina Faso",
      "Burundi",
      "Cabo Verde",
      "Chile",
      "China",
      "Cookinseln",
      "Costa Rica",
      "Côte d'Ivoire",
      "Dänemark",
      "Deutschland",
      "Dominica",
      "Dominikanische Republik",
      "Dschibuti",
      "Ecuador",
      "El Salvador",
      "Eritrea",
      "Estland",
      "Eswatini",
      "Fidschi",
      "Finnland",
      "Frankreich",
      "Gabun",
      "Gambia",
      "Georgien",
      "Ghana",
      "Grenada",
      "Griechenland",
      "Guatemala",
      "Guinea",
      "Guinea-Bissau",
      "Guyana",
      "Haiti",
      "Heiliger Stuhl",
      "Honduras",
      "Indien",
      "Indonesien",
      "Irak",
      "Iran, Islamische Republik",
      "Irland",
      "Island",
      "Israel",
      "Italien",
      "Jamaika",
      "Japan",
      "Jemen",
      "Jordanien",
      "Kambodscha",
      "Kamerun",
      "Kanada",
      "Kasachstan",
      "Katar",
      "Kenia",
      "Kirgisistan",
      "Kiribati",
      "Kolumbien",
      "Komoren",
      "Kongo",
      "Kongo, Demokratische Republik",
      "Korea, Demokratische Volksrepublik",
      "Korea, Republik",
      "Kosovo",
      "Kroatien",
      "Kuba",
      "Kuwait",
      "Laos, Demokratische Volksrepublik ",
      "Lesotho",
      "Lettland",
      "Libanon",
      "Liberia",
      "Libyen",
      "Liechtenstein",
      "Litauen",
      "Luxemburg",
      "Madagaskar",
      "Malawi",
      "Malaysia",
      "Malediven",
      "Mali",
      "Malta",
      "Marokko",
      "Marshallinseln",
      "Mauretanien",
      "Mauritius",
      "Mexiko",
      "Mikronesien, Föderierte Staaten von",
      "Moldau, Republik",
      "Monaco",
      "Mongolei",
      "Montenegro",
      "Mosambik",
      "Myanmar",
      "Namibia",
      "Nauru",
      "Nepal",
      "Neuseeland",
      "Nicaragua",
      "Niederlande",
      "Niger",
      "Nigeria",
      "Niue",
      "Nordmazedonien",
      "Norwegen",
      "Oman",
      "Österreich",
      "Pakistan",
      "Palau",
      "Panama",
      "Papua-Neuguinea",
      "Paraguay",
      "Peru",
      "Philippinen",
      "Polen",
      "Portugal",
      "Ruanda",
      "Rumänien",
      "Russische Föderation",
      "Salomonen",
      "Sambia",
      "Samoa",
      "San Marino",
      "São Tomé und Príncipe",
      "Saudi-Arabien",
      "Schweden",
      "Schweiz",
      "Senegal",
      "Serbien",
      "Seychellen",
      "Sierra Leone",
      "Simbabwe",
      "Singapur",
      "Slowakei",
      "Slowenien",
      "Somalia",
      "Spanien",
      "Sri Lanka",
      "St. Kitts und Nevis",
      "St. Lucia",
      "St. Vincent und die Grenadinen",
      "Südafrika",
      "Sudan",
      "Südsudan",
      "Suriname",
      "Syrien, Arabische Republik",
      "Tadschikistan",
      "Tansania, Vereinigte Republik",
      "Thailand",
      "Timor-Leste",
      "Togo",
      "Tonga",
      "Trinidad und Tobago",
      "Tschad",
      "Tschechien",
      "Tunesien",
      "Türkei",
      "Turkmenistan",
      "Tuvalu",
      "Uganda",
      "Ukraine",
      "Ungarn",
      "Uruguay",
      "Usbekistan",
      "Vanuatu",
      "Vatikanstadt",
      "Venezuela, Bolivarische Republik",
      "Vereinigte Arabische Emirate",
      "Vereinigte Staaten (USA)",
      "Vereinigtes Königreich (UK)",
      "Vietnam",
      "Weißrussland",
      "Zentralafrikanische Republik",
      "Zypern",
      "Unbekannt"
    ];
  }

  getAllNationalities() {
    return [
      "afghanisch",
      "ägyptisch",
      "albanisch",
      "algerisch",
      "andorranisch",
      "angolanisch",
      "antiguanisch",
      "äquatorialguineisch",
      "argentinisch",
      "armenisch",
      "aserbaidschanisch",
      "äthiopisch",
      "australisch",
      "bahamaisch",
      "bahrainisch",
      "bangladeschisch",
      "barbadisch",
      "belgisch",
      "belizisch",
      "beninisch",
      "bhutanisch",
      "bolivianisch",
      "bosnisch",
      "herzegowinisch",
      "botsuanisch",
      "brasilianisch",
      "bruneiisch",
      "bulgarisch",
      "burkinisch",
      "burundisch",
      "chilenisch",
      "chinesisch",
      "costa-ricanisch",
      "dänisch",
      "kongolesisch",
      "deutsch",
      "dominicanisch",
      "dominikanisch",
      "dschibutisch",
      "ecuadorianisch",
      "salvadorianisch",
      "ivorisch",
      "englisch",
      "eritreisch",
      "estnisch",
      "fidschianisch",
      "finnisch",
      "französisch",
      "gabunisch",
      "gambisch",
      "georgisch",
      "ghanaisch",
      "grenadisch",
      "griechisch",
      "grönländisch",
      "britisch",
      "guatemaltekisch",
      "guineisch",
      "guinea-bissauisch",
      "guyanisch",
      "haitianisch",
      "honduranisch",
      "indisch",
      "indonesisch",
      "irakisch",
      "iranisch",
      "irisch",
      "isländisch",
      "israelisch",
      "italienisch",
      "jamaikanisch",
      "japanisch",
      "jemenitisch",
      "jordanisch",
      "kambodschanisch",
      "kamerunisch",
      "kanadisch",
      "kapverdisch",
      "kasachisch",
      "katarisch",
      "kenianisch",
      "kirgisisch",
      "kiribatisch",
      "kolumbianisch",
      "komorisch",
      "kongolesisch",
      "kosovarisch",
      "kroatisch",
      "kubanisch",
      "kuwaitisch",
      "laotisch",
      "lesothisch",
      "lettisch",
      "libanesisch",
      "liberianisch",
      "libysch",
      "liechtensteinisch",
      "litauisch",
      "luxemburgisch",
      "madagassisch",
      "malawisch",
      "malaysisch",
      "maledivisch",
      "malisch",
      "maltesisch",
      "marokkanisch",
      "marshallisch",
      "mauretanisch",
      "mauritisch",
      "mazedonisch",
      "mexikanisch",
      "mikronesisch",
      "moldauisch",
      "monegassisch",
      "mongolisch",
      "montenegrinisch",
      "mosambikanisch",
      "myanmarisch",
      "namibisch",
      "nauruisch",
      "nepalesisch",
      "neuseeländisch",
      "nicaraguanisch",
      "niederländisch",
      "nigrisch",
      "nigerianisch",
      "nordkoreanisch",
      "norwegisch",
      "omanisch",
      "österreichisch",
      "pakistanisch",
      "palästinensisch",
      "palauisch",
      "panamaisch",
      "papua-neuguineisch",
      "paraguayisch",
      "peruanisch",
      "philippinisch",
      "polnisch",
      "portugiesisch",
      "puerto-ricanisch",
      "kongolesisch",
      "ruandisch",
      "rumänisch",
      "russisch",
      "salomonisch",
      "sambisch",
      "samoanisch",
      "san-marinesisch",
      "saudi-arabisch",
      "schottisch",
      "schwedisch",
      "schweizerisch",
      "senegalesisch",
      "serbisch",
      "seychellisch",
      "sierra-leonisch",
      "simbabwisch",
      "singapurisch",
      "slowakisch",
      "slowenisch",
      "somalisch",
      "spanisch",
      "sri-lankisch",
      "lucianisch",
      "vincentisch",
      "südafrikanisch",
      "sudanesisch",
      "südkoreanisch",
      "surinamisch",
      "swasiländisch",
      "syrisch",
      "são-toméisch",
      "tadschikisch",
      "taiwanisch",
      "tansanisch",
      "thailändisch",
      "tibetisch",
      "timoresisch",
      "togoisch",
      "tongaisch",
      "tschadisch",
      "tschechisch",
      "tunesisch",
      "türkisch",
      "turkmenisch",
      "tuvaluisch",
      "ugandisch",
      "ukrainisch",
      "ungarisch",
      "uruguayisch",
      "amerikanisch",
      "usbekisch",
      "vanuatuisch",
      "vatikanisch",
      "venezolanisch",
      "vietnamesisch",
      "walisisch",
      "weißrussisch",
      "zentralafrikanisch",
      "zyprisch",
    ]
  }

  getAllUnis() {
    return [
      "Hochschulname",
      "Fachhochschule Aachen",
      "Rheinisch-Westfälische Technische Hochschule Aachen",
      "Hochschule Aalen - Technik und Wirtschaft",
      "Hochschule Albstadt-Sigmaringen",
      "Alanus Hochschule",
      "Ostbayerische Technische Hochschule Amberg-Weiden",
      "Hochschule Anhalt - Anhalt University of Applied Sciences",
      "Hochschule für angewandte Wissenschaften Ansbach",
      "Technische Hochschule Aschaffenburg",
      "Hochschule für angewandte Wissenschaften Augsburg - University of Applied Sciences",
      "Universität Augsburg",
      "accadis Hochschule Bad Homburg",
      "Internationale Hochschule Liebenzell (IHL)",
      "Otto-Friedrich-Universität Bamberg",
      "Hochschule für evangelische Kirchenmusik der Evangelisch-Lutherischen Kirche in Bayern",
      "Universität Bayreuth",
      "Akkon-Hochschule",
      "Alice Salomon Hochschule Berlin",
      "bbw Hochschule",
      "Bard College Berlin, A Liberal Arts University",
      "Beuth Hochschule für Technik Berlin",
      "Berlin International University of Applied Sciences",
      "Barenboim-Said Akademie",
      "BSP Business School Berlin - Hochschule für Management GmbH",
      "CODE University of Applied Sciences",
      "Digital Business University of Applied Sciences",
      "DEKRA Hochschule für Medien",
      "DHGS Deutsche Hochschule für Gesundheit und Sport",
      "ESCP Europe Wirtschaftshochschule Berlin e.V.",
      "ESMT European School of Management and Technology",
      "Evangelische Hochschule Berlin",
      "Touro College Berlin",
      "Freie Universität Berlin",
      "Hochschule für angewandte Pädagogik",
      "Hochschule für Musik Hanns Eisler Berlin",
      "Hochschule für Schauspielkunst Ernst Busch",
      "HMKW Hochschule für Medien, Kommunikation und Wirtschaft",
      "Hertie School of Governance",
      "Hochschule für Technik und Wirtschaft Berlin",
      "Humboldt-Universität zu Berlin",
      "Hochschule für Wirtschaft und Recht Berlin",
      "Hochschule für Wirtschaft, Technik und Kultur (HWTK)",
      "IB Hochschule für Gesundheit und Soziales",
      "International Psychoanalytic University Berlin",
      "Katholische Hochschule für Sozialwesen Berlin (KHSB) - Staatlich anerkannte Fachhochschule für Sozialwesen",
      "Mediadesign Hochschule für Design und Informatik",
      "Medical School Berlin - Hochschule für Gesundheit und Medizin (MSB)",
      "Psychologische Hochschule Berlin (PHB)",
      "Quadriga Hochschule Berlin",
      "Steinbeis-Hochschule Berlin",
      "SRH Berlin University of Applied Sciences",
      "Technische Universität Berlin",
      "Universität der Künste Berlin",
      "weißensee kunsthochschule berlin",
      "Cusanus Hochschule",
      "Hochschule Biberach - Hochschule für Architektur und Bauwesen, Betriebswirtschaft und Biotechnologie",
      "Fachhochschule Bielefeld",
      "Fachhochschule der Diakonie - Diaconia - University of Applied Sciences",
      "Fachhochschule des Mittelstands (FHM)",
      "Universität Bielefeld",
      "Technische Hochschule Bingen",
      "EBZ Business School - University of Applied Sciences",
      "Hochschule Bochum - University of Applied Sciences",
      "Hochschule für Gesundheit - University of Applied Sciences",
      "Evangelische Hochschule Rheinland-Westfalen-Lippe",
      "Technische Hochschule Georg Agricola",
      "Ruhr-Universität Bochum",
      "Hochschule der Sparkassen-Finanzgruppe, University of Applied Sciences, Bonn GmbH",
      "Hochschule Bonn-Rhein-Sieg, University of Applied Sciences",
      "Rheinische Friedrich-Wilhelms-Universität Bonn",
      "Technische Hochschule Brandenburg",
      "Hochschule für Bildende Künste Braunschweig",
      "Technische Universität Carolo-Wilhelmina zu Braunschweig",
      "APOLLON Hochschule der Gesundheitswirtschaft",
      "Hochschule Bremen",
      "Hochschule für Künste Bremen",
      "Jacobs University Bremen",
      "Universität Bremen",
      "Hochschule für Öffentliche Verwaltung Bremen",
      "Hochschule Bremerhaven",
      "Europäische Fachhochschule Rhein/Erft, european university of applied sciences",
      "hochschule 21",
      "Technische Universität Chemnitz",
      "Technische Universität Clausthal",
      "Hochschule für angewandte Wissenschaften Coburg",
      "Brandenburgische Technische Universität Cottbus-Senftenberg",
      "Evangelische Hochschule Darmstadt (staatlich anerkannt) – Kirchliche Körperschaft des öffentlichen Rechts",
      "Wilhelm Büchner Hochschule - Private Fernhochschule Darmstadt",
      "Hochschule Darmstadt",
      "Technische Universität Darmstadt",
      "Technische Hochschule Deggendorf",
      "Hochschule für Musik Detmold",
      "Fachhochschule Dortmund",
      "International School of Management",
      "Technische Universität Dortmund",
      "DIU - Dresden International University GmbH",
      "Evangelische Hochschule Dresden",
      "Hochschule für Bildende Künste Dresden",
      "Hochschule für Kirchenmusik der Evangelisch-Lutherischen Landeskirche Sachsens",
      "Hochschule für Musik Carl Maria von Weber Dresden",
      "Palucca Hochschule für Tanz Dresden",
      "Hochschule für Technik und Wirtschaft Dresden - University of Applied Sciences",
      "Fachhochschule Dresden",
      "Technische Universität Dresden",
      "Universität Duisburg-Essen",
      "Fliedner Fachhochschule Düsseldorf",
      "Hochschule Düsseldorf",
      "Robert-Schumann-Hochschule Düsseldorf",
      "IST-Hochschule für Management",
      "Kunstakademie Düsseldorf",
      "Heinrich-Heine-Universität Düsseldorf",
      "Hochschule für nachhaltige Entwicklung Eberswalde",
      "Katholische Universität Eichstätt - Ingolstadt",
      "Nordakademie - Staatlich anerkannte private Fachhochschule mit dualen Studiengängen",
      "Theologische Hochschule Elstal",
      "Hochschule Emden/Leer",
      "Fachhochschule Erfurt",
      "IUBH Internationale Hochschule",
      "Universität Erfurt",
      "Friedrich-Alexander-Universität Erlangen-Nürnberg",
      "FOM Hochschule für Oekonomie & Management - University of Applied Sciences",
      "Hochschule der bildenden Künste (HBK) Essen",
      "Folkwang Universität der Künste",
      "Hochschule Esslingen",
      "Theologisches Seminar Ewersbach",
      "Hochschule Flensburg",
      "Europa-Universität Flensburg",
      "Frankfurt University of Applied Sciences",
      "Provadis School of International Management and Technology",
      "Frankfurt School of Finance & Management",
      "Hochschule für Bildende Künste (Städelschule)",
      "Hochschule für Musik und Darstellende Kunst Frankfurt am Main",
      "Philosophisch-Theologische Hochschule Sankt Georgen Frankfurt am Main",
      "Johann Wolfgang Goethe-Universität, Frankfurt am Main",
      "Europa-Universität Viadrina Frankfurt (Oder)",
      "Technische Universität Bergakademie Freiberg",
      "Evangelische Hochschule Freiburg, staatlich anerkannte Hochschule der Evangelischen Landeskirche in Baden",
      "Hochschule für Musik Freiburg im Breisgau",
      "Katholische Hochschule Freiburg, staatlich anerkannte Hochschule - Catholic University of Applied Sciences",
      "Pädagogische Hochschule Freiburg",
      "Albert-Ludwigs-Universität Freiburg im Breisgau",
      "Theologische Hochschule Friedensau",
      "Zeppelin Universität - Hochschule zwischen Wirtschaft, Kultur und Politik",
      "Hochschule Fulda - University of Applied Sciences",
      "Theologische Fakultät Fulda",
      "Wilhelm Löhe Hochschule für angewandte Wissenschaften",
      "Hochschule Furtwangen - Informatik, Technik, Wirtschaft, Medien, Gesundheit",
      "Hochschule Geisenheim",
      "Duale Hochschule Gera-Eisenach",
      "SRH Hochschule für Gesundheit Gera GmbH",
      "Freie Theologische Hochschule Gießen",
      "Justus-Liebig-Universität Gießen",
      "PFH - Private Hochschule Göttingen",
      "Georg-August-Universität Göttingen",
      "Universität Greifswald",
      "Hochschule der Deutschen Bundesbank",
      "FernUniversität in Hagen",
      "Evangelische Hochschule für Kirchenmusik",
      "Burg Giebichenstein Kunsthochschule Halle",
      "Martin-Luther-Universität Halle-Wittenberg",
      "Bucerius Law School, Hochschule für Rechtswissenschaft",
      "EBC Hochschule - University of Applied Sciences",
      "Europäische Fernhochschule Hamburg",
      "Evangelische Hochschule für Soziale Arbeit & Diakonie",
      "Hamburger Fern-Hochschule, gemeinnützige GmbH",
      "Brand University of Applied Sciences",
      "Hochschule für Angewandte Wissenschaften Hamburg",
      "HafenCity Universität Hamburg",
      "Hochschule für Bildende Künste Hamburg",
      "Hochschule für Musik und Theater Hamburg",
      "HSBA Hamburg School of Business Administration",
      "Kühne Logistics University - Wissenschaftliche Hochschule für Logistik und Unternehmensführung",
      "MSH Medical School Hamburg - University of Applied Sciences and Medical University",
      "NBS Northern Business School – University of Applied Sciences",
      "Technische Universität Hamburg",
      "Universität Hamburg",
      "Helmut-Schmidt-Universität/Universität der Bundeswehr Hamburg",
      "SRH Hochschule Hamm",
      "Hochschule Hamm-Lippstadt",
      "Fachhochschule für die Wirtschaft Hannover",
      "Hochschule Hannover",
      "Hochschule für Musik, Theater und Medien Hannover",
      "Leibniz-Fachhochschule",
      "Medizinische Hochschule Hannover (MHH)",
      "Stiftung Tierärztliche Hochschule Hannover",
      "Gottfried Wilhelm Leibniz Universität Hannover",
      "Hochschule Harz, Hochschule für angewandte Wissenschaften (FH)",
      "SRH Hochschule Heidelberg - Staatlich anerkannte Fachhochschule",
      "Hochschule für Jüdische Studien Heidelberg",
      "Hochschule für Kirchenmusik der Evangelischen Landeskirche in Baden",
      "Hochschule Fresenius Heidelberg – staatlich anerkannte Hochschule der Hochschule Fresenius für Internationales Management GmbH",
      "Pädagogische Hochschule Heidelberg",
      "Ruprecht-Karls-Universität Heidelberg",
      "Hochschule Heilbronn, Technik, Wirtschaft, Informatik",
      "German Graduate School of Management and Law gGmbH Heilbronn, staatlich anerkannte Hochschule",
      "Hochschule für Kirchenmusik der Evangelischen Kirche von Westfalen",
      "Fachhochschule für Interkulturelle Theologie Hermannsburg",
      "Hessische Hochschule für Polizei und Verwaltung",
      "Universität Hildesheim",
      "Hochschule für angewandte Wissenschaft und Kunst, Fachhochschule Hildesheim/Holzminden/Göttingen",
      "Hochschule für Angewandte Wissenschaften Hof",
      "Universität Hohenheim",
      "Hochschule Fresenius",
      "Technische Universität Ilmenau",
      "Technische Hochschule Ingolstadt",
      "Hochschule für Angewandte Wissenschaften Europa - Iserlohn, Berlin, Hamburg",
      "Hochschule für angewandtes Management",
      "Ernst-Abbe-Hochschule Jena – University of Applied Sciences",
      "Friedrich-Schiller-Universität Jena",
      "Hochschule Kaiserslautern (University of Applied Sciences)",
      "Technische Universität Kaiserslautern",
      "Staatliche Akademie der Bildenden Künste Karlsruhe",
      "Hochschule Karlsruhe - Technik und Wirtschaft",
      "Staatliche Hochschule für Gestaltung Karlsruhe",
      "Hochschule für Musik Karlsruhe",
      "Internationale Karlshochschule - staatlich anerkannte Fachhochschule der MAI Privathochschulen gGmbH, Karlsruhe",
      "Pädagogische Hochschule Karlsruhe",
      "Karlsruher Institut für Technologie",
      "CVJM-Hochschule Kassel",
      "Universität Kassel",
      "Hochschule für angewandte Wissenschaften Kempten",
      "Fachhochschule Kiel",
      "Muthesius Kunsthochschule",
      "Christian-Albrechts-Universität zu Kiel",
      "Hochschule Koblenz",
      "Universität Koblenz-Landau",
      "CBS International Business School - University of Applied Sciences",
      "Deutsche Sporthochschule Köln",
      "Hochschule für Musik und Tanz Köln",
      "HSD Hochschule Döpfer",
      "Kunsthochschule für Medien Köln",
      "Rheinische Fachhochschule Köln",
      "Technische Hochschule Köln",
      "Universität zu Köln",
      "Allensbach Hochschule Konstanz, staatlich anerkannte Hochschule der Allensbach Hochschule GmbH",
      "Hochschule Konstanz Technik, Wirtschaft und Gestaltung",
      "Universität Konstanz",
      "Hochschule Landshut - Hochschule für angewandte Wissenschaften",
      "Hochschule für Grafik und Buchkunst Leipzig",
      "HHL Leipzig Graduate School of Management",
      "Hochschule für Musik und Theater Felix Mendelssohn Bartholdy Leipzig",
      "Hochschule für Technik, Wirtschaft und Kultur Leipzig",
      "Universität Leipzig",
      "Musikhochschule Lübeck",
      "Technische Hochschule Lübeck",
      "Universität zu Lübeck",
      "Evangelische Hochschule Ludwigsburg - staatlich anerkannte Hochschule für Angewandte Wissenschaften der Evangelischen Landeskirche in Württemberg",
      "Pädagogische Hochschule Ludwigsburg",
      "Hochschule für Wirtschaft und Gesellschaft Ludwigshafen ",
      "Leuphana Universität Lüneburg",
      "Hochschule Magdeburg-Stendal",
      "Otto-von-Guericke-Universität Magdeburg",
      "Hochschule Mainz",
      "Katholische Hochschule Mainz Catholic University of Applied Sciences",
      "Johannes Gutenberg-Universität Mainz",
      "Hochschule Mannheim",
      "Hochschule der Bundesagentur für Arbeit - Staatlich anerkannte Hochschule für angewandte Wissenschaft in Mannheim und Schwerin (University of Applied Labour Studies of the Federal Employment Agency)",
      "Hochschule der Wirtschaft für Management",
      "Staatliche Hochschule für Musik und Darstellende Kunst Mannheim",
      "Universität Mannheim",
      "Evangelische Hochschule Tabor",
      "Philipps-Universität Marburg",
      "Hochschule Merseburg",
      "Technische Hochschule Mittelhessen - THM",
      "Hochschule Mittweida, University of Applied Sciences",
      "Evangelische Hochschule Moritzburg",
      "Akademie der Bildenden Künste München",
      "Hochschule für angewandte Wissenschaften München",
      "Hochschule der Bayerischen Wirtschaft für angewandte Wissenschaften - HDBW",
      "Hochschule für Fernsehen und Film München",
      "Hochschule für Musik und Theater München",
      "Hochschule für Philosophie",
      "Internationale Hochschule SDI München - Hochschule für angewandte Wissenschaften",
      "Katholische    Stiftungshochschule für   angewandte Wissenschaften München - Hochschule der Kirchlichen Stiftung des öffentlichen Rechts Katholische Bildungsstätten für Sozialberufe in Bayern",
      "Munich Business School - Staatlich anerkannte private Fachhochschule",
      "Technische Universität München",
      "Ludwig-Maximilians-Universität München",
      "Universität der Bundeswehr München",
      "Fachhochschule Münster",
      "Kunstakademie Münster, Hochschule für Bildende Künste",
      "Philosophisch-Theologische Hochschule Münster - Kirchlich und staatlich anerkannte Hochschule in Trägerschaft der PTH Philosophisch-Theologische Hochschule Münster gemeinnützige GmbH",
      "Westfälische Wilhelms-Universität Münster",
      "Hochschule Neubrandenburg - University of Applied Sciences",
      "Augustana-Hochschule Neuendettelsau",
      "Medizinische Hochschule Brandenburg Theodor Fontane",
      "Hochschule für angewandte Wissenschaften Neu-Ulm",
      "Hochschule Niederrhein",
      "Kommunale Hochschule für Verwaltung in Niedersachsen",
      "Hochschule Nordhausen",
      "DIPLOMA Hochschule - Private Fachhochschule Nordhessen",
      "Katholische Hochschule Nordrhein-Westfalen - Catholic University of Applied Sciences",
      "Akademie der Bildenden Künste Nürnberg",
      "Evangelische Hochschule für angewandte Wissenschaften - Evangelische Fachhochschule Nürnberg",
      "Hochschule für Musik Nürnberg",
      "Technische Hochschule Nürnberg Georg Simon Ohm",
      "Hochschule für Wirtschaft und Umwelt Nürtingen-Geislingen",
      "Lutherische Theologische Hochschule Oberursel",
      "Hochschule für Gestaltung Offenbach",
      "Hochschule für Technik, Wirtschaft und Medien Offenburg",
      "Carl von Ossietzky Universität Oldenburg",
      "Hochschule Osnabrück",
      "Universität Osnabrück",
      "Hochschule Braunschweig/Wolfenbüttel, Ostfalia Hochschule für angewandte Wissenschaften",
      "Technische Hochschule Ostwestfalen-Lippe ",
      "Hochschule für Künste im Sozialen, Ottersberg",
      "Fachhochschule der Wirtschaft",
      "Theologische Fakultät Paderborn",
      "Universität Paderborn",
      "Universität Passau",
      "Hochschule Pforzheim - Gestaltung, Technik, Wirtschaft und Recht",
      "Filmuniversität Babelsberg Konrad Wolf",
      "Fachhochschule Clara Hoffbauer Potsdam - University of Applied Sciences",
      "Fachhochschule Potsdam",
      "Fachhochschule für Sport und Management Potsdam",
      "Health and Medical University Potsdam",
      "Universität Potsdam",
      "XU Exponential University of Applied Sciences",
      "Hochschule Ravensburg-Weingarten",
      "Hochschule für Katholische Kirchenmusik und Musikpädagogik",
      "Ostbayerische Technische Hochschule Regensburg",
      "Universität Regensburg",
      "Hochschule Reutlingen, Hochschule für Technik-  Wirtschaft-Informatik-Design",
      "Theologische Hochschule Reutlingen - staatlich anerkannte Fachhochschule der Evangelisch-methodistischen Kirche",
      "Hochschule RheinMain",
      "Hochschule Rhein-Waal - University of Applied Sciences",
      "SRH Fernhochschule - The Mobile University",
      "Technische Hochschule Rosenheim",
      "Hochschule für Musik und Theater Rostock",
      "Universität Rostock",
      "Hochschule für Forstwirtschaft Rottenburg",
      "Hochschule für Kirchenmusik der Diözese Rottenburg-Stuttgart",
      "Hochschule Ruhr West- University of Applied Sciences",
      "Deutsche Hochschule für Prävention und Gesundheitsmanagement GmbH",
      "Hochschule für Musik Saar",
      "Hochschule für Technik und Wirtschaft des Saarlandes",
      "Universität des Saarlandes",
      "Hochschule der Bildenden Künste Saar",
      "Philosophisch-Theologische Hochschule SVD Sankt Augustin, Theologische Fakultät",
      "Duale Hochschule Schleswig-Holstein - staatlich anerkannte Hochschule für angewandte Wissenschaften in Trägerschaft der Wirtschaftsakademie Schleswig-Holstein",
      "Hochschule Schmalkalden",
      "Hochschule für Gestaltung Schwäbisch Gmünd",
      "Pädagogische Hochschule Schwäbisch Gmünd",
      "Universität Siegen",
      "Deutsche Universität für Verwaltungswissenschaften Speyer",
      "Hochschule Stralsund",
      "AKAD Hochschule Stuttgart - staatlich anerkannt",
      "Staatliche Akademie der Bildenden Künste Stuttgart",
      "Duale Hochschule Baden-Württemberg",
      "Hochschule für Technik Stuttgart",
      "Hochschule der Medien Stuttgart",
      "Hochschule für Kommunikation und Gestaltung",
      "Staatliche Hochschule für Musik und Darstellende Kunst Stuttgart",
      "Hochschule Macromedia - staatlich anerkannte Hochschule für angewandte Wissenschaften der Macromedia GmbH mit Sitz in Stuttgart",
      "media Akademie - Hochschule Stuttgart",
      "Merz Akademie Hochschule für Gestaltung, Kunst und Medien, Stuttgart - Staatlich anerkannt",
      "Universität Stuttgart",
      "Freie Hochschule Stuttgart - Seminar für Waldorfpädagogik. Staatlich anerkannte wissenschaftliche Hochschule.",
      "VWA-Hochschule für berufsbegleitendes Studium",
      "Fachhochschule Südwestfalen",
      "Hochschule Trier - Trier University of Applied Sciences",
      "Theologische Fakultät Trier",
      "Universität Trier",
      "Staatliche Hochschule für Musik Trossingen",
      "Hochschule für Kirchenmusik der Evangelischen Landeskirche in Württemberg",
      "Eberhard Karls Universität Tübingen",
      "Technische Hochschule Ulm",
      "Universität Ulm",
      "Philosophisch-Theologische Hochschule Vallendar - Kirchlich und staatlich anerkannte wissenschaftliche Hochschule in freier Trägerschaft",
      "WHU - Otto Beisheim School of Management",
      "Private Hochschule für Wirtschaft und Technik Vechta/Diepholz",
      "Universität Vechta",
      "Fachhochschule Wedel",
      "Hochschule  für  angewandte  Wissenschaften  Weihenstephan-Triesdorf",
      "Hochschule für Musik Franz Liszt Weimar",
      "Bauhaus-Universität Weimar",
      "Pädagogische Hochschule Weingarten",
      "Hochschule Weserbergland",
      "Westfälische Hochschule Gelsenkirchen, Bocholt, Recklinghausen",
      "Fachhochschule Westküste, Hochschule für Wirtschaft und Technik",
      "EBS Universität für Wirtschaft und Recht",
      "Technische Hochschule Wildau",
      "Jade Hochschule -  Wilhelmshaven/Oldenburg/Elsfleth",
      "Hochschule Wismar - University of Applied Sciences: Technology, Business and Design",
      "Private Universität Witten/Herdecke gGmbH",
      "Hochschule Worms, University of Applied Sciences",
      "Kirchliche Hochschule Wuppertal/Bethel (Hochschule für Kirche und Diakonie)",
      "Bergische Universität Wuppertal",
      "Hochschule für Musik Würzburg",
      "Hochschule für angewandte Wissenschaften Würzburg-Schweinfurt",
      "Julius-Maximilians-Universität Würzburg",
      "Hochschule Zittau/Görlitz",
      "Westsächsische Hochschule Zwickau"
    ];

  }

  getAllSchulformen() {
    return [
      "Abendgymnasium",
      "Abendhauptschule",
      "Abendrealschule",
      "Ausbildungsvorbereitung dual und Vorqualifizierungsjahr Arbeit/Beruf",
      "Berufliche Bildung",
      "Berufliche Schule",
      "Berufliches Gymnasium",
      "Berufliches Gymnasium Oberstufe",
      "Berufsakademie",
      "Berufseinstiegsjahr",
      "Berufseinstiegsschule",
      "Berufsfachschule",
      "Berufsfachschule zum Übergang in Ausbildung (BÜA)",
      "Berufskolleg",
      "Berufsoberschule",
      "Berufsschule",
      "Berufsvorbereitende Schulart",
      "Bildungsabteilung ReBZZ",
      "Bildungszentrum",
      "Bilinguale Schule",
      "Duale Ausbildung",
      "Duale Berufsausbildung",
      "Einjährige Fachschule",
      "Ergänzungsschule",
      "Ersatzschule",
      "Erzieherberuf",
      "Evangelische Bekenntnisschule",
      "Evangelisches Gymnasium",
      "Fachakademie",
      "Fachoberschule",
      "Fachoberschule einjährig",
      "Fachoberschule zwei- oder dreijährig",
      "Fachschule",
      "Fachschule für Sozialwesen",
      "Förderschule",
      "Förderzentrum",
      "Freies Gymnasium",
      "Gemeinschaftsschule",
      "Gesamtschule",
      "Gesamtschule mit gymnasialer Oberstufe",
      "Grundschule",
      "Gymnasium",
      "Gymnasium für Hochbegabte",
      "Haupt- und Förderschule",
      "Hauptschule",
      "Höhere Handelsschule",
      "Höhere Technikschule für Informations-, Metall- und Elektrotechnik",
      "Integrierte Berufausbildungsvorbereitung",
      "Integrierte Sekundarstufe",
      "Internationale Schule",
      "Katholische Haupt- und Realschule",
      "Katholisches Gymnasium",
      "Kindergarten",
      "Klinik- und Krankenhausschule",
      "Kolleg",
      "Mittelschule",
      "Mittelstufenschule",
      "Montessori-Schule",
      "Musikgymnasium",
      "Oberschule",
      "Privatgymnasium für Begabte",
      "Privatschule",
      "Realschule",
      "Regelschule",
      "Regionale Schule",
      "Schulartunabhängige Orientierungsstufe",
      "Schule des Gesundheitswesen",
      "Sekundarschule",
      "Sonderpädagogische Förderung",
      "Spezielle Sonderschule",
      "Sportgymnasium",
      "Sportschule",
      "Staatliche vollqualifizierende Berufsfachschule",
      "Stadtteilschule",
      "Übergang weiterführende Schule",
      "Volksschule",
      "Vorqualifizierungsjahr Arbeit/Beruf ohne Deutschkenntnisse",
      "Vorschule",
      "Waldorfschule",
      "Weiterbildung",
      "Werkrealschule-Hauptschule",
      "Wirtschaftsschule",
      "Zweijährige Fachschule",
      "Zweiter Bildungsweg"
    ];
  }

  getAllUniAbschluesse() {
    return [
      "B.A. (Bachelor of Arts)",
      "B.Sc. (Bachelor of Science)",
      "B.Eng. (Bachelor of Engineering)",
      "LL.B. (Bachelor of Laws)",
      "B.F.A. (Bachelor of Fine Arts)",
      "B.Mus. (Bachelor of Music)",
      "B.Ed. (Bachelor of Education)",
      "B. A. (Baccalaureus Artium)",
      "bac. jur. (Baccalaureus Juris)",
      "MB BS (Bachelor of Medicine, Bachelor of Surgery)",
      "Diplom (FH)",
      "Dipl.-Ing. (Diplom-Ingenieur/in)",
      "Dipl.-Hdl. (Diplom-Handelslehrer/in)",
      "Dipl.-Kfm./-Kffr. (Diplom-Kaufmann/-Kauffrau)",
      "Mag., M.A. (Magister Artium, Magister der Künste)",
      "M.A. (Master of Arts)",
      "M.Sc. (Master of Science)",
      "M.Eng. (Master of Engineering)",
      "LL.M. (Master of Laws)",
      "M.F.A. (Master of Fine Arts)",
      "M.Mus. (Master of Music)",
      "M.Ed. (Master of Education)",
      "M.Arch. (Master of Architecture)",
      "PhD",
      "Staatsexamen",
      "1. Staatsexamen",
      "2. Staatsexamen",
      "3. Staatsexamen"
    ]
  }

  getAllUniqueFachbereiche() {
    return [
      "Ingenieurwissenschaften",
      "Kunst, Gestaltung und Musik",
      "Gesellschafts- und Sozialwissenschaften",
      "Naturwissenschaften und Mathemathik",
      "Rechts- und Wirtschaftswissenschaften",
      "Sprach- und Kulturwissenschaften",
      "Medizin und Gesundheitswesen",
      "Agrar- und Forstwissenschaften",
      "Lehramtstudiengänge",
      "Beamtenstudiengänge",
      "Dienstleistung",
      "Produktion, Fertigung",
      "Verkehr, Logistik",
      "Kein Fachbereich"
    ]
  }

  getAllLanguages() {
    return ["Abchasisch",
      "Aceh-Sprache",
      "Acholi-Sprache",
      "Adangme",
      "Adygeisch",
      "Afar",
      "Afrihili",
      "Afrikaans",
      "Aghem",
      "Ägyptisch",
      "Ägyptisches Arabisch",
      "Ainu-Sprache",
      "Akan",
      "Akkadisch",
      "Akoose",
      "Alabama",
      "Albanisch",
      "Aleutisch",
      "Algerisches Arabisch",
      "Alt-Newari",
      "Altenglisch",
      "Altfranzösisch",
      "Altgriechisch",
      "Althochdeutsch",
      "Altirisch",
      "Altnordisch",
      "Altpersisch",
      "Altpreussisch",
      "Altprovenzalisch",
      "Altsyrisch",
      "Amerikanische Gebärdensprache",
      "Amerikanisches Englisch",
      "Amharisch",
      "Angika",
      "Ao-Naga",
      "Arabisch",
      "Aragonesisch",
      "Aramäisch",
      "Araona",
      "Arapaho-Sprache",
      "Araukanisch",
      "Arawak-Sprache",
      "Armenisch",
      "Aromunisch",
      "Aserbaidschanisch",
      "Assamesisch",
      "Asturianisch",
      "Atsam",
      "Australisches Englisch",
      "Avestisch",
      "Awadhi",
      "Awarisch",
      "Aymara",
      "Bachtiarisch",
      "Badaga",
      "Bafia",
      "Bafut",
      "Bairisch",
      "Balinesisch",
      "Bambara-Sprache",
      "Bamun",
      "Banjaresisch",
      "Basaa-Sprache",
      "Baschkirisch",
      "Baskisch",
      "Batak Toba",
      "Bedauye",
      "Belutschisch",
      "Bemba-Sprache",
      "Bena",
      "Bengalisch",
      "Bergmari",
      "Betawi",
      "Bhodschpuri",
      "Bhutanisch",
      "Bikol-Sprache",
      "Bini-Sprache",
      "Birmanisch",
      "Bishnupriya",
      "Bislama",
      "Blackfoot-Sprache",
      "Blin",
      "Bliss-Symbole",
      "Bodo",
      "Bosnisch",
      "Brahui",
      "Braj-Bhakha",
      "Brasilianisches Portugiesisch",
      "Bretonisch",
      "Britisches Englisch",
      "Buginesisch",
      "Bulgarisch",
      "Bulu",
      "Burjatisch",
      "Caddo",
      "Cajun",
      "Capiznon",
      "Cayuga",
      "Cebuano",
      "Chamorro-Sprache",
      "Cherokee",
      "Cheyenne",
      "Chibcha-Sprache",
      "Chimborazo Hochland-Quechua",
      "Chinesisch",
      "Chinesisch (traditionell)",
      "Chinesisch (vereinfacht)",
      "Chinook",
      "Chipewyan",
      "Choctaw",
      "Cree",
      "Dakota-Sprache",
      "Dänisch",
      "Darginisch",
      "Dazaga",
      "Delaware-Sprache",
      "Deutsch",
      "Dinka-Sprache",
      "Diola",
      "Dogri",
      "Dogrib",
      "Duala",
      "Dyula-Sprache",
      "Efik",
      "Ekajuk",
      "Elamisch",
      "Elliceanisch",
      "Embu",
      "Emilianisch",
      "Englisch",
      "Ersja-Mordwinisch",
      "Esperanto",
      "Estnisch",
      "Europäisches Portugiesisch",
      "Europäisches Spanisch",
      "Ewe",
      "Ewondo",
      "Extremadurisch",
      "Fanti-Sprache",
      "Farefare",
      "Färöisch",
      "Fidschi",
      "Fidschi-Hindi",
      "Filipino",
      "Finnisch",
      "Flämisch",
      "Fon-Sprache",
      "Frankoprovenzalisch",
      "Französisch",
      "Friulisch",
      "Ful",
      "Ga-Sprache",
      "Gabri",
      "Gagausisch",
      "Galizisch",
      "Gan",
      "Ganda",
      "Gayo",
      "Gbaya-Sprache",
      "Geez",
      "Gegisch",
      "Georgisch",
      "Ghomala",
      "Gilaki",
      "Gilbertesisch",
      "Goa-Konkani",
      "Gondi-Sprache",
      "Gotisch",
      "Grebo-Sprache",
      "Griechisch",
      "Grönländisch",
      "Guarani",
      "Gujarati",
      "Gusii",
      "Haida-Sprache",
      "Haitianisch",
      "Hakka",
      "Hausa",
      "Hawaiisch",
      "Hebräisch",
      "Herero-Sprache",
      "Hethitisch",
      "Hiligaynon-Sprache",
      "Hindi",
      "Hiri-Motu",
      "Hupa",
      "Iban",
      "Ibibio",
      "Ido-Sprache",
      "Igbo",
      "Ilokano-Sprache",
      "Inari-Lappisch",
      "Indonesisch",
      "Inguschisch",
      "Interlingua",
      "Interlingue",
      "Inuktitut",
      "Inupiak",
      "Irisch",
      "Ischorisch",
      "Isländisch",
      "Italienisch",
      "Jakutisch",
      "Jamaikanisch-kreolische Sprache",
      "Japanisch",
      "Javanisch",
      "Jiddisch",
      "Jju",
      "Jüdisch-Arabisch",
      "Jüdisch-Persisch",
      "Jütisch",
      "K’iche’",
      "Kabardinisch",
      "Kabuverdianu",
      "Kabylisch",
      "Kachin-Sprache",
      "Kaingang",
      "Kako",
      "Kalenjin",
      "Kalmückisch",
      "Kamba",
      "Kambodschanisch",
      "Kanadisches Englisch",
      "Kanadisches Französisch",
      "Kanembu",
      "Kannada",
      "Kantonesisch",
      "Kanuri-Sprache",
      "Karakalpakisch",
      "Karatschaiisch-Balkarisch",
      "Karelisch",
      "Karibisch",
      "Kasachisch",
      "Kaschmirisch",
      "Kaschubisch",
      "Katalanisch",
      "Kawi",
      "Keine Sprachinhalte",
      "Kenyang",
      "Khasi-Sprache",
      "Khotta",
      "Khowar",
      "Kikuyu",
      "Kimbundu-Sprache",
      "Kinaray-a",
      "Kirchenslawisch",
      "Kirgisisch",
      "Kirmanjki",
      "Klassisches Chinesisch",
      "Klingonisch",
      "Kölsch",
      "Kom",
      "Komi-Permjakisch",
      "Komi-Sprache",
      "Komorisch",
      "Kongo-Suaheli",
      "Kongolesisch",
      "Konkani",
      "Koptisch",
      "Koreanisch",
      "Kornisch",
      "Koro",
      "Korsisch",
      "Kosraeanisch",
      "Kotava",
      "Koyra Chiini",
      "Koyra Senni",
      "Kpelle-Sprache",
      "Krimtatarisch",
      "Krio",
      "Kroatisch",
      "Kumükisch",
      "Kurdisch",
      "Kutchin-Sprache",
      "Kutenai-Sprache",
      "Kwanyama",
      "Kwasio",
      "Ladino",
      "Lahnda",
      "Lakota",
      "Lamba-Sprache",
      "Langi",
      "Laotisch",
      "Lasisch",
      "Latein",
      "Lateinamerikanisches Spanisch",
      "Lesgisch",
      "Lettgallisch",
      "Lettisch",
      "Ligurisch",
      "Limburgisch",
      "Lingala",
      "Lingua Franca Nova",
      "Litauisch",
      "Livisch",
      "Lojban",
      "Lombardisch",
      "Luba-Katanga",
      "Luba-Lulua",
      "Luhya",
      "Luiseno-Sprache",
      "Lule-Lappisch",
      "Lunda-Sprache",
      "Luo-Sprache",
      "Lushai-Sprache",
      "Luxemburgisch",
      "Maba",
      "Machame",
      "Madagassisch",
      "Maduresisch",
      "Mafa",
      "Mainfränkisch",
      "Maithili",
      "Makassarisch",
      "Makhuwa-Meetto",
      "Makonde",
      "Malaiisch",
      "Malayalam",
      "Maledivisch",
      "Maltesisch",
      "Mandaresisch",
      "Manding-Sprache",
      "Mandschurisch",
      "Manx",
      "Maori",
      "Marathi",
      "Marokkanisches Arabisch",
      "Marschallesisch",
      "Marwari",
      "Masanderanisch",
      "Massai-Sprache",
      "Mazedonisch",
      "Mbundu-Sprache",
      "Meänkieli",
      "Medumba",
      "Mehrsprachig",
      "Meithei-Sprache",
      "Mende-Sprache",
      "Mentawai",
      "Meru",
      "Meta’",
      "Mexikanisches Spanisch",
      "Miao-Sprache",
      "Micmac-Sprache",
      "Min Nan",
      "Minangkabau-Sprache",
      "Mingrelisch",
      "Mirandesisch",
      "Mittelenglisch",
      "Mittelfranzösisch",
      "Mittelhochdeutsch",
      "Mittelirisch",
      "Mittelniederländisch",
      "Mittelpersisch",
      "Modernes Hocharabisch",
      "Mohawk",
      "Moksha",
      "Moldauisch",
      "Mongo",
      "Mongolisch",
      "Mongondou",
      "Morisyen",
      "Mossi-Sprache",
      "Mundang",
      "Muskogee-Sprache",
      "Myene",
      "N’Ko",
      "Nama",
      "Nauruisch",
      "Navajo",
      "Ndonga",
      "Neapolitanisch",
      "Nepalesisch",
      "Neumelanesisch",
      "Newari",
      "Ngambay",
      "Ngiemboon",
      "Ngomba",
      "Nheengatu",
      "Nias-Sprache",
      "Niederdeutsch",
      "Niederländisch",
      "Niedersorbisch",
      "Niue-Sprache",
      "Nogai",
      "Nord-Ndebele-Sprache",
      "Nord-Samisch",
      "Nord-Sotho-Sprache",
      "Nordfriesisch",
      "Norwegisch",
      "Norwegisch Bokmål",
      "Norwegisch Nynorsk",
      "Novial",
      "Nuer",
      "Nyamwezi-Sprache",
      "Nyanja-Sprache",
      "Nyankole",
      "Nyoro",
      "Nzima",
      "Obersorbisch",
      "Ojibwa-Sprache",
      "Okzitanisch",
      "Oraon-Sprache",
      "Oriya",
      "Oromo",
      "Osage-Sprache",
      "Osmanisch",
      "Ossetisch",
      "Osterinsel-Sprache",
      "Österreichisches Deutsch",
      "Ostfriesisch",
      "Palau",
      "Pali",
      "Pampanggan-Sprache",
      "Pangasinan-Sprache",
      "Pangwe-Sprache",
      "Panjabi",
      "Papiamento",
      "Pare",
      "Paschtu",
      "Pennsylvaniadeutsch",
      "Persisch",
      "Pfälzisch",
      "Phönikisch",
      "Picardisch",
      "Piemontesisch",
      "Plautdietsch",
      "Polnisch",
      "Ponapeanisch",
      "Pontisch",
      "Portugiesisch",
      "Quechua",
      "Rajasthani",
      "Rarotonganisch",
      "Rätoromanisch",
      "Romagnol",
      "Romani",
      "Rombo",
      "Root",
      "Rotse-Sprache",
      "Rotumanisch",
      "Roviana",
      "Ruandisch",
      "Rukiga",
      "Rumänisch",
      "Rundi-Sprache",
      "Russinisch",
      "Russisch",
      "Rwa",
      "Saho",
      "Sakisch",
      "Samaritanisch",
      "Samburu",
      "Samoanisch",
      "Samogitisch",
      "Sandawe-Sprache",
      "Sango",
      "Sangu",
      "Sanskrit",
      "Santali",
      "Sardisch",
      "Sasak",
      "Sassarisch",
      "Saterfriesisch",
      "Saurashtra",
      "Schan-Sprache",
      "Schlesisch",
      "Schlesisch (Polen)",
      "Schottisch",
      "Schottisches Gälisch",
      "Schwedisch",
      "Schweizer Französisch",
      "Schweizer Hochdeutsch",
      "Schweizerdeutsch",
      "Seeländisch",
      "Selayar",
      "Selkupisch",
      "Sena",
      "Seneca",
      "Serbisch",
      "Serbo-Kroatisch",
      "Serer-Sprache",
      "Seri",
      "Shambala",
      "Shona",
      "Sidamo",
      "Sindhi",
      "Singhalesisch",
      "Sizilianisch",
      "Skolt-Lappisch",
      "Slave",
      "Slowakisch",
      "Slowenisch",
      "Soga",
      "Sogdisch",
      "Somali",
      "Soninke-Sprache",
      "Sorani",
      "Spanisch",
      "Srananisch",
      "Suaheli",
      "Süd-Altaisch",
      "Süd-Ndebele-Sprache",
      "Süd-Samisch",
      "Süd-Sotho-Sprache",
      "Südaserbaidschanisch",
      "Sukuma-Sprache",
      "Sumerisch",
      "Sundanesisch",
      "Susu",
      "Swazi",
      "Syrisch",
      "Tadschikisch",
      "Tagalog",
      "Tahitisch",
      "Taita",
      "Talisch",
      "Tamaseq",
      "Tamazight",
      "Tamilisch",
      "Tarifit",
      "Taroko",
      "Tasawaq",
      "Taschelhit",
      "Tatarisch",
      "Tatisch",
      "Telugu",
      "Temne",
      "Tereno-Sprache",
      "Teso",
      "Tetum-Sprache",
      "Thailändisch",
      "Tibetisch",
      "Tigre",
      "Tigrinja",
      "Tiv-Sprache",
      "Tlingit-Sprache",
      "Tokelauanisch",
      "Tongaisch",
      "Trukesisch",
      "Tsachurisch",
      "Tsakonisch",
      "Tschadisch-Arabisch",
      "Tschagataisch",
      "Tschechisch",
      "Tscheremissisch",
      "Tschetschenisch",
      "Tschuwaschisch",
      "Tsimshian-Sprache",
      "Tsonga",
      "Tsonga-Sprache",
      "Tswana-Sprache",
      "Tulu",
      "Tumbuka-Sprache",
      "Tunesisches Arabisch",
      "Türkisch",
      "Turkmenisch",
      "Turoyo",
      "Tuwinisch",
      "Twi",
      "Tyap",
      "Udmurtisch",
      "Ugaritisch",
      "Uigurisch",
      "Ukrainisch",
      "Unbestimmte Sprache",
      "Ungarisch",
      "Urdu",
      "Usbekisch",
      "Vai",
      "Venda-Sprache",
      "Venetisch",
      "Vietnamesisch",
      "Volapük",
      "Võro",
      "Vunjo",
      "Walamo-Sprache",
      "Walisisch",
      "Wallonisch",
      "Walser-Dialekte",
      "Waray",
      "Warlpiri",
      "Washo-Sprache",
      "Wayúu",
      "Weissrussisch",
      "Wepsisch",
      "Westflämisch",
      "Westfriesisch",
      "Wolof",
      "Wotisch",
      "Wu",
      "Xhosa",
      "Xiang",
      "Yangben",
      "Yao-Sprache",
      "Yapesisch",
      "Yemba",
      "Yi",
      "Yoruba",
      "Zapotekisch",
      "Zarma",
      "Zaza",
      "Zenaga",
      "Zentral-Alaska-Yupik",
      "Zentral-Dusun",
      "Zentralatlas-Tamazight",
      "Zhuang",
      "Zulu",
      "Zuni-Sprache"
    ]

  }

  getAllSports(){
    return [
      "Aikido",
      "Air Hockey",
      "Airsoft",
      "Akontion",
      "Allgemeines Turnen",
      "Alpinklettern",
      "Amateurfunkpeilen",
      "American Football",
      "American Handball",
      "Angelsport",
      "Apnoetauchen",
      "Aquaball",
      "Aquathlon",
      "Arena Football",
      "Armdrücken",
      "Badminton",
      "Bahnengolf",
      "Bahnradsport",
      "Ballbadminton",
      "Bankdrücken",
      "Baseball",
      "Base-Jumping",
      "Basketball",
      "Beachbasketball",
      "Beachhandball",
      "Beachminton",
      "Beachrugby",
      "Beachsoccer",
      "Beachvolleyball",
      "Berglauf",
      "Bergrennen",
      "Bergsteigen",
      "Biathle",
      "Biathlon",
      "Bikebiathlon",
      "Billard",
      "BMX",
      "Bob",
      "Boccia",
      "Bogenlaufen",
      "Bogenschießen",
      "Bootschen",
      "Boßeln",
      "Bosseln (Reha-Sport)",
      "Bouldern",
      "Boule Lyonnaise",
      "Bowling",
      "Bowls",
      "Boxen",
      "Brazilian Jiu-Jitsu",
      "Brennball",
      "Broomball",
      "Bügelspiel",
      "Buggykiting",
      "Buildering",
      "Bumerangwerfen",
      "Bungee-Jumping",
      "Cageball",
      "Calisthenics",
      "Callanetics",
      "Camogie",
      "Canadian Football",
      "Canyoning",
      "Capoeira",
      "Casting",
      "Combatschießen",
      "Cricket",
      "Croquet",
      "CrossFit",
      "Crossgolf",
      "Cubbies",
      "Curling",
      "Cyclocross",
      "Darts",
      "DDC-Frisbee",
      "Discgolf",
      "Diskuswurf",
      "Distanzreiten",
      "Dodgeball",
      "Downhill",
      "Drachenfliegen/Hängegleiter",
      "Drachensteigen",
      "Dreisprung",
      "Dressurreiten",
      "Duathlon",
      "Eierlaufen",
      "Einradbasketball",
      "Einradhockey",
      "Eishockey",
      "Eisklettern",
      "Eisschnelllauf",
      "Eisspeedway",
      "Eisstockschießen",
      "Extrembügeln",
      "Fahrsport",
      "Falknerei",
      "Faustball",
      "Faustkampf (Pygme)",
      "Fechten",
      "Federball",
      "Federfußball",
      "Feldhandball",
      "Feldkorbball",
      "Fives",
      "Flag Football",
      "Floorball",
      "Flossenschwimmen",
      "Footvolley",
      "Freestyle Frisbee",
      "Freestyle-Skiing",
      "Freiklettern",
      "Friskee",
      "Fußball",
      "Fußballtennis",
      "Futsal",
      "Gaelic Football",
      "Gateball",
      "Gehen",
      "Geocaching",
      "Gerätturnen",
      "Gewichtheben",
      "Gleitschirmfliegen",
      "Goalball",
      "Golf",
      "Gorodki",
      "Grasski",
      "Guts",
      "Gymnastik",
      "Hallenfußball",
      "Hallenhockey",
      "Hallenkorbball",
      "Hammerwurf",
      "Handball",
      "Hindernislauf",
      "Hochsprung",
      "Hockey (Feldhockey)",
      "Höhlenwandern",
      "Hornussen",
      "Horseball",
      "House-Running",
      "Hürdenlauf",
      "Hurling",
      "Indiaca",
      "Indoorcycling",
      "Inlinehockey",
      "Inline-Skaterhockey",
      "Inlineskating",
      "Inline-Speedskating",
      "Intercrosse",
      "International Rules Football",
      "Irish Handball",
      "Jahn-Wettkämpfe",
      "Jai Alai",
      "Jeu de palets",
      "Jeu de Paume (Real Tennis)",
      "Jeu Provençal",
      "Jiu-Jitsu",
      "Joggen",
      "Jogo do pau",
      "Jokeiba",
      "Judo",
      "Jugger",
      "Ju-Jutsu",
      "Jukskei",
      "Kaatsen",
      "Kabaddi",
      "Kanupolo",
      "Kanurennsport",
      "Kanuslalom",
      "Karambolage",
      "Karate",
      "Kartsport",
      "Kegelbillard",
      "Kegeln",
      "Kendo",
      "Kickball",
      "Kickboxen",
      "Kiiking",
      "Kin-Ball",
      "Kitelandboarding",
      "Kitesurfen",
      "Klettern",
      "Klippenspringen",
      "Klootschießen",
      "Klotstockspringen",
      "Kniebeugen",
      "Korbball",
      "Korfball",
      "Kraftdreikampf",
      "Kreuzheben",
      "Kubb",
      "Kugelstoßen",
      "Kung Fu",
      "Kunstradfahren",
      "Kutterpullen",
      "Kyūdō",
      "Lacrosse",
      "Langbold",
      "Langstreckenlauf",
      "Lapta (Spiel)",
      "Laufen",
      "Leichtathletik",
      "Loheland-Gymnastik",
      "Longaméta",
      "Luftkissen-Schlitteln",
      "Luta Livre",
      "Maculelê",
      "Merkball",
      "Mesoamerikanisches Ballspiel",
      "Minigolf",
      "Mittelstreckenlauf",
      "Mixed-Klettern",
      "Moderne Schwertkunst",
      "Moderner Fünfkampf",
      "Mölkky",
      "Moroken",
      "Motoball",
      "Motorbootsport",
      "Motorradsport",
      "Mountainbike",
      "Mountainbike-Orienteering",
      "Muay Thai",
      "Naginata",
      "Naturbahnrodeln",
      "Netball",
      "Nippers",
      "Nordic Walking",
      "Nordische Kombination",
      "Novuss",
      "Oină",
      "Öl-Ringkampf",
      "Orientierungslauf",
      "Orientierungstauchen",
      "Pall Mall",
      "Palla eh!",
      "Pankration",
      "Parkour",
      "Pato",
      "Pelota",
      "Penspinning",
      "Pentathlon",
      "Pesäpallo",
      "Pétanque",
      "Pferderennen",
      "Pilates",
      "Platzgen",
      "Plinking",
      "Polo",
      "Polocrosse",
      "Pontoniersport",
      "Poolbillard",
      "Prellball",
      "Pultstockspringen/Klotstockspringen",
      "Quidditch",
      "Racketlon",
      "Rackets",
      "Racquetball",
      "Radball",
      "Radpolo",
      "Radsport",
      "Rafting",
      "Rallye",
      "Rasenkraftsport",
      "Red Bull Air Race Weltmeisterschaft",
      "Reifenrodeln",
      "Reiten",
      "Rennrodeln",
      "Rettungssport",
      "Rhönradturnen",
      "Ricochet",
      "Ringen",
      "Ringette",
      "Ringreiten",
      "Ringtennis",
      "Rinkball",
      "Rodeo",
      "Rollhockey",
      "Rollstuhlbasketball",
      "Rollstuhlrugby",
      "Roque",
      "Rounders",
      "Rudern",
      "Rugby League",
      "Rugby Union",
      "Rundbold",
      "Russisches Kegeln",
      "Sandbahnrennen",
      "Sandboarding",
      "Savate",
      "Schachboxen",
      "Schießen",
      "Schlagball",
      "Schleuderball",
      "Schnorcheln",
      "Schwierigkeitsklettern",
      "Schwimmen",
      "Schwingen",
      "Scoutball",
      "Seesport",
      "Segelfliegen",
      "Segeln",
      "Segwaypolo",
      "Seilklettern",
      "Seilspringen",
      "Sepak Takraw",
      "Shaolin Kempo",
      "Shinty",
      "Shorttrack",
      "Shuffleboard",
      "Sitzball",
      "Sitzfußball",
      "Sitzvolleyball",
      "Skateboarding",
      "Skeleton",
      "Ski Alpin (Abfahrtslauf, Super-G, Slalom & Riesenslalom)",
      "Skibergsteigen",
      "Skijöring",
      "Skilanglauf",
      "Skimboarden",
      "Skirodeln",
      "Skispringen",
      "Skwal",
      "Skydiving (Fallschirmspringen)",
      "Slackline",
      "Slamball",
      "Sledge-Eishockey",
      "Smashball",
      "Smolball",
      "Snooker",
      "Snowboard",
      "Snowkiting",
      "Soccergolf",
      "Softball",
      "Speckbrett",
      "Speed Badminton",
      "Speed Flying",
      "Speedklettern",
      "Speerwurf",
      "Sport Stacking",
      "Sportholzfällen",
      "Sportklettern",
      "Sportwagenrennen",
      "Springreiten",
      "Sprint",
      "Squash",
      "Stabhochsprung",
      "Stadionlauf",
      "Staffellauf",
      "Stehpaddeln",
      "Steinstoßen",
      "Steinwerfen",
      "Stockball",
      "Stockschießen",
      "Stoolball",
      "Strandsegeln",
      "Straßenfußball",
      "Straßenrennen",
      "Streetball",
      "Streethockey",
      "Strongman",
      "Sumō",
      "Surf Ski",
      "Surfen",
      "Swingolf",
      "Tae Bo",
      "Taekwondo",
      "Tag Rugby",
      "Taiji Bailong Ball",
      "Tamburello",
      "Taubenschießen",
      "Tauchen",
      "Tauziehen",
      "Tchoukball",
      "TeamGym",
      "Tennis",
      "Tipp-Kick",
      "Tischeishockey",
      "Tischfußball",
      "Tischtennis",
      "Toboggan",
      "Torball",
      "Touch (Sport)",
      "Trampolinturnen",
      "Trekking",
      "Tretrollersport",
      "Tretze",
      "Trials",
      "Triathlon",
      "Tumbling",
      "Ulama (Ballspiel)",
      "Ultimate",
      "Unihockey",
      "Unterwasserhockey",
      "Unterwasserrugby",
      "Urban Exploration",
      "Urban Freeride",
      "Vielseitigkeitsprüfung (Angeln)",
      "Vielseitigkeitsreiten (Military)",
      "Vierkampf",
      "Vigoro",
      "Völkerball",
      "Volleyball",
      "Volleyclub",
      "Wagenrennen",
      "Wakeboarding",
      "Wandern",
      "Wanderreiten",
      "Wasserball",
      "Wasserfahren",
      "Wasserski",
      "Weitsprung",
      "Wellenreiten",
      "Westernreiten",
      "Windsurfen",
      "Wing Tsun",
      "Wokfahren",
      "Wurfscheibenschießen",
      "Wushu",
      "Yoga",
      "Yukigassen",
      "Zehnkampf, Siebenkampf",
    ]
  }

}

export default new StaticData();
