import jwt_decode from "jwt-decode";

export class Useraccount {
  private id: number;

  private username: string;

  private jamaat: string;

  private majlis: string;

  private name: string;

  private roles: string[];

  private officeHolderDataReupdated: boolean;

  private murabbiJamaats: string[];

  constructor(
    id: number,
    username: string,
    jamaat: string,
    majlis: string,
    name: string,
    roles: string[],
    officeHolderDataReupdated: boolean,
    murabbiJamaats: string[]
    ) {
    this.id = id;
    this.username = username;
    this.jamaat = jamaat;
    this.majlis = majlis;
    this.name = name;
    this.roles = roles;
    this.officeHolderDataReupdated = officeHolderDataReupdated;
    this.murabbiJamaats = murabbiJamaats;
  }

  get getId(): number {
    return this.id;
  }

  get getUsername(): string {
    return this.username;
  }

  get getJamaat(): string {
    return this.jamaat;
  }

  get getMajlis(): string {
    return this.majlis;
  }

  get getName(): string {
    return this.name;
  }

  get getOfficeHolderDataReupdated(): boolean {
    return this.officeHolderDataReupdated;
  }

  get getRoles(): string[] {
    return this.roles;
  }

  get getMurabbiJamaats(): string[] {
    return this.murabbiJamaats;
  }

  get isNormalUser(): boolean {
    if (this.roles.indexOf("ROLE_NORMAL_MEMBER") >= 0) {
      return true;
    }
    return false;
  }

  get isMissionaryUser(): boolean {
    if (this.roles.indexOf("ROLE_MISSIONARY") >= 0) {
      return true;
    }
    return false;
  }

  get isMissionaryInchargeUser(): boolean {
    if (this.roles.indexOf("ROLE_MISSIONARY_INCHARGE") >= 0) {
      return true;
    }
    return false;
  }

  get isNationalAssistantJamaatUser(): boolean {
    if (this.roles.indexOf("ROLE_ASS_NATIONAL_SHOBA_JAMAAT") >= 0) {
      return true;
    }

    return false;
  }

  get isNationalAssistantLajnaUser(): boolean {
    if (this.roles.indexOf("ROLE_ASS_NATIONAL_SHOBA_LAJNA") >= 0) {
      return true;
    }

    return false;
  }

  get isNationalJamaatUser(): boolean {
    if (this.roles.indexOf("ROLE_NATIONAL_SHOBA_JAMAAT") >= 0) {
      return true;
    }
    return false;
  }

  get isNationalLajnaUser(): boolean {
    if (this.roles.indexOf("ROLE_NATIONAL_SHOBA_LAJNA") >= 0) {
      return true;
    }

    return false;
  }

  get isNaibAmirUser(): boolean {
    if (this.roles.indexOf("ROLE_NAIB_AMIR") >= 0) {
      return true;
    }
    return false;
  }

  get isNationalUserOrNationalAssistantUser(): boolean {
    if (this.roles.indexOf("ROLE_ASS_NATIONAL_SHOBA_JAMAAT") >= 0
      || this.roles.indexOf("ROLE_NATIONAL_SHOBA_JAMAAT") >= 0
      || this.roles.indexOf("ROLE_ASS_NATIONAL_SHOBA_LAJNA") >= 0
      || this.roles.indexOf("ROLE_NATIONAL_SHOBA_LAJNA") >= 0) {
      return true;
    }
    return false;
  }

  get isAdministrativeUser(): boolean {
    if (this.roles.indexOf("ROLE_LOCAL_SECRETARY_JAMAAT") >= 0
      || this.roles.indexOf("ROLE_LOCAL_SECRETARY_LAJNA") >= 0
      || this.roles.indexOf("ROLE_REGIONAL_SECRETARY_LAJNA") >= 0
      || this.roles.indexOf("ROLE_REGIONAL_SECRETARY_JAMAAT") >= 0
      || this.roles.indexOf("ROLE_ASS_NATIONAL_SHOBA_JAMAAT") >= 0
      || this.roles.indexOf("ROLE_NATIONAL_SHOBA_JAMAAT") >= 0
      || this.roles.indexOf("ROLE_ASS_NATIONAL_SHOBA_LAJNA") >= 0
      || this.roles.indexOf("ROLE_NATIONAL_SHOBA_LAJNA") >= 0) {
      return true;
    }
    return false;
  }

  get isJudgeUser(): boolean {
    if (this.roles.indexOf("ROLE_JUDGE_JAMAAT") >= 0
      || this.roles.indexOf("ROLE_JUDGE_LAJNA") >= 0) {
      return true;
    }
    return false;
  }

  get isLocalOrRegionalJamaatUser(): boolean {
    if (this.roles.indexOf("ROLE_LOCAL_SECRETARY_JAMAAT") >= 0
      || this.roles.indexOf("ROLE_REGIONAL_SECRETARY_JAMAAT") >= 0
    ) {
      return true;
    }
    return false;
  }


  get isRegionalUser(): boolean {
    if (this.roles.indexOf("ROLE_REGIONAL_SECRETARY_JAMAAT") >= 0
      || this.roles.indexOf("ROLE_REGIONAL_SECRETARY_LAJNA") >= 0
    ) {
      return true;
    }
    return false;
  }

  get isRegionalJamaatUser(): boolean {
    if (this.roles.indexOf("ROLE_REGIONAL_SECRETARY_JAMAAT") >= 0) {
      return true;
    }
    return false;
  }

  get isRegionalLajnaUser(): boolean {
    if (this.roles.indexOf("ROLE_REGIONAL_SECRETARY_LAJNA") >= 0) {
      return true;
    }
    return false;
  }

  get isLocalOrRegionalLajnaUser(): boolean {
    if (this.roles.indexOf("ROLE_LOCAL_SECRETARY_LAJNA") >= 0
      || this.roles.indexOf("ROLE_REGIONAL_SECRETARY_LAJNA") >= 0) {
      return true;
    }
    return false;
  }

  get isLocalOrRegionalOrNationalLajnaUser(): boolean {
    if (this.roles.indexOf("ROLE_LOCAL_SECRETARY_LAJNA") >= 0
      || this.roles.indexOf("ROLE_REGIONAL_SECRETARY_LAJNA") >= 0
      || this.roles.indexOf("ROLE_ASS_NATIONAL_SHOBA_LAJNA") >= 0
    ) {
      return true;
    }
    return false;
  }

  get isLocalUser(): boolean {
    if (this.roles.indexOf("ROLE_LOCAL_SECRETARY_JAMAAT") >= 0
      || this.roles.indexOf("ROLE_LOCAL_SECRETARY_LAJNA") >= 0) {
      return true;
    }

    return false;
  }

  get isLocalJamaatUser(): boolean {
    if (this.roles.indexOf("ROLE_LOCAL_SECRETARY_JAMAAT") >= 0) {
      return true;
    }

    return false;
  }

  get isLocalLajnaUser(): boolean {
    if (this.roles.indexOf("ROLE_LOCAL_SECRETARY_LAJNA") >= 0) {
      return true;
    }
    return false;
  }
}
