import {IndividualReportRequest, LocalReport, LocalReportJson, MissionaryReportJson, RegionalReportJson} from "@/modules/report/report.types";
import httpResource from "../../http/httpResource";
import { Exam } from "./exam.types";

const API_URL = process.env.VUE_APP_API_URL + '/api/exam';

class ExamService {

  getExamsForIndividual(personId: string) {
    return httpResource.get(API_URL+ '/individual/table/' + personId);
  }

  getIndividualExam(examId: string, personId: string) {
    return httpResource.get(API_URL+ '/individual/' + examId + '/' + personId);
  }

  saveExam(exam: Exam, personId: string) {
    return httpResource.post(API_URL + '/individual/save/' + personId, exam);
  }

  finishExam(exam: Exam, personId: string) {
    return httpResource.post(API_URL + '/individual/finish/' + personId, exam);
  }

  getExamsForGroup(params: any) {
    return httpResource.post(API_URL + '/group', params);
  }
}

export default new ExamService();
