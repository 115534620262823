import axios from 'axios';
import {Useraccount} from "../models/useraccount.model";
import {UseraccountData} from "@/store/useraccount.store";
import jwt_decode from "jwt-decode";
import {store} from "@/store";
import {UseraccountService} from "../modules/useraccount/useraccount.service";
import httpResource from "../http/httpResource";

const API_URL = process.env.VUE_APP_API_URL;

class BasedataService {

  getJamaatAndMajalis() {
    return httpResource.get('/api/basedata/jamaatsandmajalis');
  }

}

export default new BasedataService();
