var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{on:{"change":_vm.onTabWaqifinClicked}},[_vm._v(" Waqifin ")]),_c('v-tab',{on:{"change":_vm.onTabWaqifatClicked}},[_vm._v(" Waqifat ")])],1),_c('v-tabs-items',{staticClass:"elevation-3",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[(_vm.loadingWaqifin)?_c('AppLoader',{attrs:{"text":'Waqifin',"icon":'mdi-check'}}):_c('div',[_c('div',{staticClass:"px-5"},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.newTajneedTableHeaders,"items":_vm.newTajneedWaqifinTabledata,"items-per-page":10,"search":_vm.search2,"custom-sort":_vm.customSort},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"tableheader"},[_c('h3',[_vm._v("Neue Tajneed - Anfragen")])])]},proxy:true},{key:"item.newTajneedRequest.createDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.newTajneedRequest.createDate.toString().substring(6))+" ")]}},{key:"item.approve",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleApproveNewTajneedRequest(item)}}},[_vm._v(" mdi-check ")])]}}])})],1),_c('br'),_c('v-divider'),_c('div',{staticClass:"px-5"},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.childAddRequestsWaqifinTableHeaders,"items":_vm.childAddRequestsWaqifinTabledata,"items-per-page":10,"custom-sort":_vm.customSort,"search":_vm.search2},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"tableheader"},[_c('h3',[_vm._v("Kind hinzufügen - Anfragen")])])]},proxy:true},{key:"item.createDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.createDate.toString().substring(6))+" ")]}},{key:"item.approve",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleApproveAddChildRequest(item)}}},[_vm._v(" mdi-check ")])]}}])})],1),_c('br'),_c('v-divider'),_c('div',{staticClass:"px-5"},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.shiftInlandTableHeaders,"items":_vm.shiftInlandWaqifinTableData,"items-per-page":10,"custom-sort":_vm.customSort,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"tableheader"},[_c('h3',[_vm._v("Umzug Inland - Anfragen")])])]},proxy:true},{key:"item.deleteTajneedRequestSet[0].createDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.deleteTajneedRequestSet[0].createDate.toString().substring(6))+" ")]}},{key:"item.approve",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleApproveDeleteTajneedRequest(item)}}},[_vm._v(" mdi-check ")])]}}])})],1),_c('br'),_c('v-divider'),_c('div',{staticClass:"px-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.shiftAuslandTableHeaders,"items":_vm.shiftAuslandWaqifinTableData,"items-per-page":10,"custom-sort":_vm.customSort,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"tableheader"},[_c('h3',[_vm._v(" Umzug Ausland - Anfragen")])])]},proxy:true},{key:"item.deleteTajneedRequestSet[0].createDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.deleteTajneedRequestSet[0].createDate.toString().substring(6))+" ")]}},{key:"item.approve",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleApproveDeleteTajneedRequest(item)}}},[_vm._v(" mdi-check ")])]}}])})],1)],1)],1),_c('br'),_c('v-divider'),_c('div',{staticClass:"px-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.amooreAmaCaseTableHeader,"items":_vm.amooreAmaCaseWaqifinTableData,"items-per-page":10,"custom-sort":_vm.customSort,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"tableheader"},[_c('h3',[_vm._v("Amore Ama Case - Anfragen")])])]},proxy:true},{key:"item.deleteTajneedRequestSet[0].createDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.deleteTajneedRequestSet[0].createDate.toString().substring(6))+" ")]}},{key:"item.approve",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleApproveDeleteTajneedRequest(item)}}},[_vm._v(" mdi-check ")])]}}])})],1)],1)],1),_c('br'),_c('v-divider'),_c('div',{staticClass:"px-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.verstorbenTableHeader,"items":_vm.verstorbenWaqifinTableData,"items-per-page":10,"custom-sort":_vm.customSort,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"tableheader"},[_c('h3',[_vm._v("Verstorben - Anfragen")])])]},proxy:true},{key:"item.deleteTajneedRequestSet[0].createDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.deleteTajneedRequestSet[0].createDate.toString().substring(6))+" ")]}},{key:"item.approve",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleApproveDeleteTajneedRequest(item)}}},[_vm._v(" mdi-check ")])]}}])})],1)],1)],1),_c('br'),_c('v-divider'),_c('div',{staticClass:"px-5"},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.ruecktrittTableHeader,"items":_vm.ruecktrittWaqifinTableData,"items-per-page":10,"custom-sort":_vm.customSort,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"tableheader"},[_c('h3',[_vm._v("Waqf-e-Nau Rücktritt Anfragen")])])]},proxy:true},{key:"item.approve",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleApproveDeleteTajneedRequest(item)}}},[_vm._v(" mdi-check ")])]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [(item.deleteTajneedRequestSet[0].refusalPdf)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.downloadRefusalPdf(item)}}},[_vm._v(" mdi-file-download ")]):_vm._e()]}}])})],1),_c('br'),_c('v-divider'),_c('div',{staticClass:"px-5"},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.jamiaTableHeaders,"items":_vm.jamiaTableData,"items-per-page":10,"custom-sort":_vm.customSort,"search":_vm.search2},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"tableheader"},[_c('h3',[_vm._v("Jamia Anfragen")])])]},proxy:true},{key:"item.deleteTajneedRequestSet[0].createDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.deleteTajneedRequestSet[0].createDate.toString().substring(6))+" ")]}},{key:"item.approve",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleApproveDeleteTajneedRequest(item)}}},[_vm._v(" mdi-check ")])]}}])})],1)],1)],1),_c('v-tab-item',[(_vm.loadingWaqifat)?_c('AppLoader',{attrs:{"text":'Waqifat',"icon":'mdi-check'}}):_c('div',[_c('div',{staticClass:"px-5"},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.newTajneedTableHeaders,"items":_vm.newTajneedWaqifatTabledata,"items-per-page":10,"search":_vm.search2,"custom-sort":_vm.customSort},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"tableheader"},[_c('h3',[_vm._v("Neue Tajneed - Anfragen")])])]},proxy:true},{key:"item.newTajneedRequest.createDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.newTajneedRequest.createDate.toString().substring(6))+" ")]}},{key:"item.approve",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleApproveNewTajneedRequest(item)}}},[_vm._v(" mdi-check ")])]}}])}),_c('br'),_c('v-data-table',{attrs:{"dense":"","headers":_vm.childAddRequestsWaqifatTableHeaders,"items":_vm.childAddRequestsWaqifatTabledata,"items-per-page":10,"custom-sort":_vm.customSort,"search":_vm.search2},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"tableheader"},[_c('h3',[_vm._v("Kind hinzufügen - Anfragen")])])]},proxy:true},{key:"item.createDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.createDate.toString().substring(6))+" ")]}},{key:"item.approve",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleApproveAddChildRequest(item)}}},[_vm._v(" mdi-check ")])]}}])}),_c('v-data-table',{attrs:{"dense":"","headers":_vm.shiftInlandTableHeaders,"items":_vm.shiftInlandWaqifatTableData,"items-per-page":10,"custom-sort":_vm.customSort,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"tableheader"},[_c('h3',[_vm._v("Umzug Inland - Anfragen")])])]},proxy:true},{key:"item.deleteTajneedRequestSet[0].createDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.deleteTajneedRequestSet[0].createDate.toString().substring(6))+" ")]}},{key:"item.approve",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleApproveDeleteTajneedRequest(item)}}},[_vm._v(" mdi-check ")])]}}])}),_c('br'),_c('div',{staticClass:"tableheader"},[_c('h3',[_vm._v("Umzug Ausland")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-5",attrs:{"dense":"","headers":_vm.shiftAuslandTableHeaders,"items":_vm.shiftAuslandWaqifatTableData,"items-per-page":10,"custom-sort":_vm.customSort,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"tableheader"},[_c('h3',[_vm._v("Anfragen")])])]},proxy:true},{key:"item.deleteTajneedRequestSet[0].createDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.deleteTajneedRequestSet[0].createDate.toString().substring(6))+" ")]}},{key:"item.approve",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleApproveDeleteTajneedRequest(item)}}},[_vm._v(" mdi-check ")])]}}])})],1)],1),_c('br'),_c('div',{staticClass:"tableheader"},[_c('h3',[_vm._v("Amoore Aama Case")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-5",attrs:{"dense":"","headers":_vm.amooreAmaCaseTableHeader,"items":_vm.amooreAmaCaseWaqifatTableData,"items-per-page":10,"custom-sort":_vm.customSort,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"tableheader"},[_c('h3',[_vm._v("Anfragen")])])]},proxy:true},{key:"item.deleteTajneedRequestSet[0].createDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.deleteTajneedRequestSet[0].createDate.toString().substring(6))+" ")]}},{key:"item.approve",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleApproveDeleteTajneedRequest(item)}}},[_vm._v(" mdi-check ")])]}}])})],1)],1),_c('br'),_c('div',{staticClass:"tableheader"},[_c('h3',[_vm._v("Verstorben")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-5",attrs:{"dense":"","headers":_vm.verstorbenTableHeader,"items":_vm.verstorbenWaqifatTableData,"items-per-page":10,"custom-sort":_vm.customSort,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"tableheader"},[_c('h3',[_vm._v("Anfragen")])])]},proxy:true},{key:"item.deleteTajneedRequestSet[0].createDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.deleteTajneedRequestSet[0].createDate.toString().substring(6))+" ")]}},{key:"item.approve",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleApproveDeleteTajneedRequest(item)}}},[_vm._v(" mdi-check ")])]}}])})],1)],1),_c('br'),_c('v-data-table',{attrs:{"dense":"","headers":_vm.ruecktrittTableHeader,"items":_vm.ruecktrittWaqifatTableData,"items-per-page":10,"custom-sort":_vm.customSort,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"tableheader"},[_c('h3',[_vm._v("Waqf-e-Nau Rücktritt Anfragen")])])]},proxy:true},{key:"item.deleteTajneedRequestSet[0].createDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.deleteTajneedRequestSet[0].createDate.toString().substring(6))+" ")]}},{key:"item.approve",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleApproveDeleteTajneedRequest(item)}}},[_vm._v(" mdi-check ")])]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [(item.deleteTajneedRequestSet[0].refusalPdf)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.downloadRefusalPdf(item)}}},[_vm._v(" mdi-file-download ")]):_vm._e()]}}])}),_c('br')],1)])],1)],1),_c('br'),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.approveDeleteDialogVisible),callback:function ($$v) {_vm.approveDeleteDialogVisible=$$v},expression:"approveDeleteDialogVisible"}},[(_vm.approveDeleteDialogVisible)?_c('ApproveDeleteDialog',{attrs:{"selectedTajneed":_vm.selectedTajneedForApprove},on:{"event":_vm.handleCloseApproveDeleteDialog}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.approveChildAddRequestDialogVisible),callback:function ($$v) {_vm.approveChildAddRequestDialogVisible=$$v},expression:"approveChildAddRequestDialogVisible"}},[(_vm.approveChildAddRequestDialogVisible)?_c('ApproveChildAddRequest',{attrs:{"selectedRequest":_vm.selectedRequestForApprove},on:{"event":_vm.handleCloseApproveChildAddRequestDialog}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.newTajneedDeclineDialogVisible),callback:function ($$v) {_vm.newTajneedDeclineDialogVisible=$$v},expression:"newTajneedDeclineDialogVisible"}},[(_vm.newTajneedDeclineDialogVisible)?_c('ApproveNewTajneedDialog',{attrs:{"newTajneed":_vm.selectedTajneedForApprove},on:{"on-success":_vm.handlerCloseNewTajneedApproveDialog}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }