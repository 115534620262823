

























































import {Component, Vue, Watch} from 'vue-property-decorator';

import AppCard from '../../../core/components/cards/AppCard.vue';
import {UseraccountService} from '../useraccount.service';
import {Getter} from "vuex-class";
import {Useraccount} from "../../../models/useraccount.model";
import {ServerResponse} from '../../../core/core.types';
import {AxiosError, AxiosResponse} from "axios";

interface Username {
  username: string;
  usernameConfirmed: string;
}

@Component({
  components: {
    AppCard,
  },
})
export default class FormChangeUsername extends Vue {
  @Getter('getUseraccount', {namespace: 'useraccount'})
  private getUseraccount!: Useraccount;

  private username: Username = {
    username: '',
    usernameConfirmed: '',
  }

  private showOverlay: boolean = false;

  private rules = {
    required: (value: any) => !!value || 'Pflichtfeld',
    usernameConfirmed: (v: string) => this.usernameMatch(v) || 'Email-Adressen stimmen nicht überein',
    correctEmail: (v: any) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-Mail überprüfen',
  };

  private usernameMatch(p: string) {
    if (this.username.usernameConfirmed.length > 0) {
      return (this.username.username === this.username.usernameConfirmed);
    }
    return true;
  }

  @Watch('username', {immediate: true, deep: true})
  async onErrorCountQuickChanged(val: Username, oldVal: Username) {
    await this.$nextTick();

    if (this.username.usernameConfirmed.length > 0) {
      // @ts-ignore
      this.$refs['form-changeusername'].validate();
    }
  }

  private changeUsername() {
    // @ts-ignore
    if (!this.$refs['form-changeusername'].validate()) return;

    this.showOverlay = true;

    UseraccountService.changeUsername(
      this.username.username,
    ).then((response: AxiosResponse<ServerResponse>) => {
      this.username.username = '';
      this.username.usernameConfirmed = '';
      // @ts-ignore
      this.$refs['form-changeusername'].resetValidation();
      //@ts-ignore
      this.$notify({group: 'foo', title: 'Info', text: response.data.message, type: 'success'});
    }).catch((error: AxiosError<ServerResponse>) => {
      //@ts-ignore
      this.$notify({group: 'foo', title: 'Fehler', text: error.response!.data.message, type: 'error'});
    }).finally(() => {
      setTimeout(() => {
        this.showOverlay = false;
      }, 5000);
    });
  }
}
