














































import {Component, Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import JaizaService from '../jaiza.service';
import AppCard from '../../../core/components/cards/AppCard.vue';
import {Useraccount} from "../../../models/useraccount.model";

@Component({
  components: {
    AppCard,
  },
})
export default class ViewJudges extends Vue {
  @Getter('getUseraccount', {namespace: 'useraccount'})
  private getUseraccount!: Useraccount;

  private result: string = '';

  created() {
    this.createJudge();
  }

  private createJudge(){
    JaizaService.createJudge()
      .then((response: any) => {
        this.result = response.data;
      }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: '',
        type: 'error'
      });
    }).finally(() => {
      setTimeout(() => {
      }, 5000);
    });
  }

  private resetJudgePassword(){
    JaizaService.resetJudgePassword()
      .then((response: any) => {
        this.result = response.data;
      }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: '',
        type: 'error'
      });
    }).finally(() => {
      setTimeout(() => {
      }, 5000);
    });
  }

}
