








































































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import ApprovalService from "../../../approval.service";
import TajneedService from "../../../tajneed.service";
import LoadingOverlay from "../../../../../core/components/LoadingOverlay.vue"
import {ChildAddRequest} from '../../../../useraccount/useraccount.types';
import BasedataService from "../../../../../services/basedata.service"
import plzandorte from '../../../../../staticdata/plzandorte.json';
import streets from '../../../../../staticdata/streets.json';

@Component({
  components: {LoadingOverlay},
})
export default class ApproveChildAddRequest extends Vue {

  @Prop({default: null})
  selectedRequest: ChildAddRequest;

  private jamaatItems: string[] = [];

  private majlisItems: string[] = [];

  private cityItems: any[] = [];

  private cityPostalCode = "";

  private streetItems: string[] = [];

  private locSelectedRequest: ChildAddRequest = {};

  private rules = {
    required: (value: any) => !!value || 'Pflichtfeld',
    dateFormat: (v: string) => /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)\d\d$/.test(v) || 'TT.MM.JJJJ Format',
    alreadySixteen: (v: string) => v && this.checkIsOlderThenSixteen(v) || 'Die Person ist bereits 16 Jahre alt und sollte seinen eigenen Account haben.',
    digitsOnly: (v: string) => /^(\s*|\d+)$/.test(v) || 'Jamaat-ID darf nur Zahlen beinhalten.',
    validWaqfenauId: (v: string) => /^[0-9][0-9]?[0-9]?[0-9]?[0-9]?[A-Z]$/.test(v) || 'Keine gültige Waqf-e-Nau Nummer',
  };

  private showOverlay = false;

  created() {
    BasedataService.getJamaatAndMajalis()
      .then((response: any) => {
        this.jamaatItems = response.data.jamaats;
        this.majlisItems = response.data.majalis;
      })
      .catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: 'Netzwerkfehler',
          type: 'error'
        });
      })
      .finally(() => {
        setTimeout(() => {
        }, 5000);
      });

    this.locSelectedRequest = this.selectedRequest;

    if(this.locSelectedRequest.tajneedMatchId != 0) {
      this.searchTajneedMatch(true);
    }

    this.loadOrtAndPlzSelectItems();
    this.loadStreets();
  }

  approveChildAddRequest() {
    // @ts-ignore
    this.locSelectedRequest.waqfenauId = this.locSelectedRequest.waqfenauId.replace(" ", "");
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.showOverlay = true;
      ApprovalService.approveChildAddRequest(this.locSelectedRequest)
        .then(
          (response: any) => {
            this.showOverlay = false;
            this.$notify({
              group: 'foo',
              title: 'Info',
              text: response.data,
              type: 'success'
            });
            this.$emit('event', "");
          })
        .catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text: error.response.data.message,
            type: 'error'
          });
          this.showOverlay = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.showOverlay = false;
          }, 5000);
        });
    }

  }

  declineChildAddRequest() {
    if(!this.locSelectedRequest.declineReason || this.locSelectedRequest.declineReason.length < 5) {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: 'Bitte geben Sie einen Ablehnungsgrund ein!',
        type: 'error'
      });
      return;
    }
    // @ts-ignore
    this.locSelectedRequest.waqfenauId = this.locSelectedRequest.waqfenauId.replace(" ", "");
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.showOverlay = true;
      ApprovalService.declineChildAddRequest(this.locSelectedRequest)
        .then(
          (response: any) => {
            this.showOverlay = false;
            this.$notify({
              group: 'foo',
              title: 'Info',
              text: response.data,
              type: 'success'
            });
            this.$emit('event', "");
          })
        .catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text: error.response.data.message,
            type: 'error'
          });
          this.showOverlay = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.showOverlay = false;
          }, 5000);
        });
    }
  }

  handleOrtChange(selection: any) {
    this.locSelectedRequest.postalCode = selection.text.substring(0, 5);
    this.locSelectedRequest.city = selection.text.substring(6);
  }

  private checkIsOlderThenSixteen(value: string) {

    let dateString = value;
    let dateParts = dateString.split(".");
    // month is 0-based, that's why we need dataParts[1] - 1
    let dateObject = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);

    let age = this.calculateAge(dateObject);

    if (age >= 16) {
      return false;
    } else {
      return true;
    }
  }

  private calculateAge(birthday: Date) { // birthday is a date
    var ageDifMs = Date.now() - +birthday;
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  private searchTajneedMatch(initial: boolean){
    TajneedService.findTajneedMatch(this.locSelectedRequest.aimsId + "", this.locSelectedRequest.waqfenauId + "", this.locSelectedRequest.dateOfBirth)
      .then(
        (response: any) => {
          this.showOverlay = false;
          if(!initial) {
            this.$notify({
              group: 'foo',
              title: 'Info',
              text: 'Tajneed-Match gefunden',
              type: 'success'
            });
          }
          console.log('-----');
          console.log(response.data);
          console.log(JSON.parse(JSON.stringify(response.data)));
          this.locSelectedRequest.tajneedMatchId = response.data.id;
          this.locSelectedRequest.fathersName = response.data.fathersName;
          this.locSelectedRequest.mothersName = response.data.mothersName;
          this.locSelectedRequest.mothersName = response.data.mothersName;
          this.locSelectedRequest.telephoneNumber = response.data.telephoneNumber;
          this.locSelectedRequest.street = response.data.street;
          this.locSelectedRequest.houseNr = response.data.houseNr;
          console.log(this.locSelectedRequest);
          for (var i = 0; i < this.cityItems.length; i++) {
            if (this.cityItems[i].text.indexOf(response.data.postalCode) >= 0) {
              this.cityPostalCode = this.cityItems[i];
            }
          }
        })
      .catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: error.response.data.message,
          type: 'error',
          duration: 6000
        });
        this.showOverlay = false;
      })
      .finally(() => {
        setTimeout(() => {
          this.showOverlay = false;
        }, 5000);
      });
  }

  private loadOrtAndPlzSelectItems() {
    //Load orte in autocomplete field:
    for (var i = 0; i < plzandorte.length; i++) {
      this.cityItems.push({
        text: plzandorte[i].plz + " " + plzandorte[i].ort,
        value: plzandorte[i].id
      });
    }
  }

  private loadStreets() {
    for (var i = 0; i < streets.length; i++) {
      this.streetItems.push(streets[i]);
    }
  }

}
