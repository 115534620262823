







































































import {Component, Vue} from 'vue-property-decorator';
import {Getter} from "vuex-class";
import RemoveAccountTajneedDialog from "../../tajneed/components/tajneed/dialogs/RemoveAccountTajneedDialog.vue";
import {OwnMetaData, PersonMetaData} from "../../tajneed/tajneed.types";

@Component({
  components: {RemoveAccountTajneedDialog},
})
export default class ExpansionPanelDeleteTajneed extends Vue {

  @Getter('getOwnMetaData', {namespace: 'accountmetadata'})
  private getOwnMetaData!: OwnMetaData;

  private selectedTajneed: PersonMetaData = {};

  private tajneedItems: any[] = [];

  private removeChildDialogVisible: boolean = false;

  created() {
    if (this.getOwnMetaData.ownMetaData != null) {
      this.tajneedItems.push({
        text: this.getOwnMetaData.ownMetaData.firstname + ' ' + this.getOwnMetaData.ownMetaData.lastname,
        id: this.getOwnMetaData.ownMetaData.id,
        isOwnTajneed: true,
        isChild: false
      });
    }

    if (this.getOwnMetaData.childrenMetaData != null) {
      for (let child of this.getOwnMetaData.childrenMetaData) {
        this.tajneedItems.push({
          text: child.firstname + ' ' + child.lastname,
          id: child.id,
          isOwnTajneed: false,
          isChild: true
        });
      }
    }
  }

  private showDeleteConfirmDialog() {
    this.removeChildDialogVisible = true;

  }

  private closeDeleteConfirmDialog() {
    this.removeChildDialogVisible = false;
  }

}

